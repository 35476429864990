import { IconButton, Tooltip, Typography } from "@material-ui/core"
import { IconChevronRight } from "@tabler/icons-react"
import { Suspense, useState } from "react"
import { useDeepCompareCallback } from "../../../../../utils/use-deep-compare"
import HStack from "../../../../ReusableComponents/HStack"
import VStack from "../../../../ReusableComponents/VStack"
import DialogLoader from "../../../../ReusableComponents/skeletons/DialogLoader"
import { REPORTS_DIMENSION } from "../constants"
import { useActions } from "../store"
import Dimension from "./Dimension"
import DimensionAddition from "./DimensionAddition"

export default function DimensionSelector({
  instantDimensions,
  setInstantDimensions,
}) {
  const { setDimension } = useActions()
  const [anchorEl, setAnchorEl] = useState(null)

  const [openDimensionAddition, setOpenDimensionAddition] = useState(false)

  const handleRemoveDimension = useDeepCompareCallback(
    (index) => {
      const updated = [
        ...instantDimensions.slice(0, index),
        ...instantDimensions.slice(index + 1),
      ]
      setInstantDimensions(updated)
    },
    [instantDimensions, setInstantDimensions],
  )

  return (
    <>
      <VStack
        spacing={8}
        style={{
          width: "100%",
        }}
      >
        <HStack justify={"flex-start"}>
          <Typography
            color="textPrimary"
            style={{
              color: "#020617",
              textWrap: "nowrap",
              fontWeight: 400,
              fontSize: 16,
            }}
          >
            Add Dimension
          </Typography>
        </HStack>

        <VStack
          style={{
            border: "1px solid #E2E8F0",
            padding: "8px",
            borderRadius: "4px",
            maxHeight: "600px",
            overflowY: "auto",
          }}
          spacing={8}
        >
          {instantDimensions.map((dim, index) => (
            <Dimension
              id={dim.id}
              dimension={dim}
              index={index}
              handleRemove={() => handleRemoveDimension(index)}
              instantDimensions={instantDimensions}
              setInstantDimensions={setInstantDimensions}
            />
          ))}
          <>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "12px",
                background: "#F8FAFC",
                cursor: "pointer",

                borderRadius: "4px",
                border: "1px dashed #94A3B8",
              }}
              onClick={(e) => {
                setDimension(REPORTS_DIMENSION.dimension)
                setAnchorEl(e.currentTarget)
                setOpenDimensionAddition(true)
              }}
            >
              <HStack
                spacing={6}
                align={"center"}
              >
                <Typography
                  color="textPrimary"
                  style={{
                    color: "#64748B",
                    textWrap: "nowrap",
                    fontWeight: 400,
                    fontSize: 10,
                  }}
                >
                  Add dimension
                </Typography>
              </HStack>
              <Tooltip
                title="Edit"
                placement="top"
              >
                <IconButton style={{ color: "#64748B", padding: "0px" }}>
                  <IconChevronRight style={{ height: "16px", width: "16px" }} />
                </IconButton>
              </Tooltip>
            </div>
          </>
        </VStack>
      </VStack>
      {openDimensionAddition && (
        <Suspense fallback={<DialogLoader />}>
          <DimensionAddition
            open={openDimensionAddition}
            anchorEl={anchorEl}
            onClose={() => {
              setOpenDimensionAddition(false)
              setDimension(REPORTS_DIMENSION.dimension)
            }}
            instantDimensions={instantDimensions}
            setInstantDimensions={setInstantDimensions}
            inAdditionMode={true}
          />
        </Suspense>
      )}
    </>
  )
}
