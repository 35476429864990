import { arrayMove } from "@dnd-kit/sortable"
import { createTrackedSelector } from "react-tracked"
import { defineModule } from "zoov"
import { APP_PLATFORMS } from "../../../../../../constants"
import { COLOR_STYLE_ENUM } from "../../../../../ReusableComponents/CustomColorPicker"
import {
  BUTTON,
  CHOICES,
  CHOICE_PROMPT,
  CLOSE_BUTTON,
  DEFAULT_SUCCESS_MESSAGE_IMAGE,
  DEFAULT_SURVEY,
  DEFAULT_SURVEY_REQUEST_IMAGE,
  HEADER,
  LABELS,
  LIST_ITEM,
  NEW_MODULE_TYPES,
  QUESTION_AND_SUGGESTION,
  Q_AND_A,
  RATING_BUTTONS,
  REACTION,
  SUCCESS_MESSAGE,
  SURVEY_REQUEST,
} from "../../constants"
import { getGradientConfigs } from "./components/simulator/utils"
import { ANSWER_MODES, getQAId } from "./constants"
import { generateNpsArray, getEnableColors } from "./utils"

const SurveysModule = defineModule({
  ui: DEFAULT_SURVEY,
  moduleType: NEW_MODULE_TYPES.NOTHING,
  currentStep: -1,
  onquestion: false,
  onrequestforsurvey: false,
  onthankyouforsurvey: false,
  choiceIdx: -1,
  npsSuggestionIdx: -1,
  selectedRatingBtn: -1,
  npsSelectedSuggestion: -1,
  selectedChoices: [],
  previewMode: false,
})
  .actions({
    setPreviewMode: (draft, previewMode) => {
      draft.previewMode = previewMode
    },
    setModuleType: (draft, moduleType) => {
      draft.moduleType = moduleType
    },
    setCurrentStep: (draft, currentStep) => {
      draft.currentStep = currentStep
      draft.choiceIdx = -1
      draft.selectedRatingBtn = -1
      draft.selectedChoices = []
      draft.npsSuggestionIdx = -1
      draft.npsSelectedSuggestion = -1
    },
    setOnPresentStepByDeletingSurveyRequest: (draft) => {
      draft.onrequestforsurvey = false
      draft.onquestion =
        !draft.onrequestforsurvey && draft.ui.survey.q_and_a.length > 0
      draft.onthankyouforsurvey =
        !draft.onrequestforsurvey &&
        !draft.onquestion &&
        draft.ui.enable_success_message_latest
    },
    setOnPresentStepByDeletingQuestion: (draft) => {
      draft.onquestion = draft.ui.survey.q_and_a.length > 0
      draft.onrequestforsurvey =
        !draft.onquestion && draft.ui.enable_survey_request_latest
      draft.onthankyouforsurvey =
        !draft.onrequestforsurvey &&
        !draft.onquestion &&
        draft.ui.enable_success_message_latest
    },
    setOnPresentStepByDeletingSuccessMessage: (draft) => {
      draft.onthankyouforsurvey = false
      draft.onquestion =
        !draft.onthankyouforsurvey && draft.ui.survey.q_and_a.length > 0
      draft.onrequestforsurvey =
        !draft.onthankyouforsurvey &&
        !draft.onquestion &&
        draft.ui.enable_survey_request_latest
    },
    setOnPresentStep: (draft) => {
      draft.onrequestforsurvey = draft.ui.enable_survey_request_latest
      draft.onquestion =
        !draft.onrequestforsurvey && draft.ui.survey.q_and_a.length > 0
      draft.onthankyouforsurvey =
        !draft.onrequestforsurvey &&
        !draft.onquestion &&
        draft.ui.enable_success_message_latest
    },
    setOnQuestion: (draft, enable) => {
      draft.onquestion = enable
    },
    setOnRequestForSurvey: (draft, enable) => {
      draft.onrequestforsurvey = enable
    },
    setOnThankYouForSurvey: (draft, enable) => {
      draft.onthankyouforsurvey = enable
    },
    setChoiceIdx: (draft, choiceIdx) => {
      draft.choiceIdx = choiceIdx
    },
    setNpsSuggestionIdx: (draft, npsSuggestionIdx) => {
      draft.npsSuggestionIdx = npsSuggestionIdx
    },
    setSelectedRatingBtn: (draft, selectedRatingBtn) => {
      draft.selectedRatingBtn = selectedRatingBtn
    },
    setNpsSelectedSuggestion: (draft, npsSelectedSuggestion) => {
      draft.npsSelectedSuggestion = npsSelectedSuggestion
    },
    setSelectedChoices: (draft, selectedChoices) => {
      draft.selectedChoices = selectedChoices
    },
    setMinVersion: (draft, minVersion) => {
      draft.ui.min_version = minVersion
    },
    setUi: (draft, ui) => {
      draft.ui = ui
    },

    // UI Actions
    setDelay: (draft, delay) => {
      draft.ui.delay = delay
    },
    setWidth: (draft, width) => {
      draft.ui.width = width
    },
    setHeight: (draft, height) => {
      draft.ui.height = height
    },
    setIsFullscreen: (draft, isFullscreen) => {
      draft.ui.is_fullscreen = isFullscreen
    },
    setBackgroundColor: (draft, backgroundColor) => {
      draft.ui.background_color = backgroundColor
    },

    // Dim Background Actions
    enableDimBackground: (draft, enable) => {
      draft.ui.enable_dimbackground = enable
    },
    setDimBackgroundColor: (draft, color) => {
      draft.ui.dimbackground.color = color
    },
    setDimBackgroundOpacity: (draft, opacity) => {
      draft.ui.dimbackground.opacity = opacity
    },

    // Position Actions
    setPosition: (draft, position) => {
      draft.ui.position.position = position
    },

    // Border Actions
    enableBorder: (draft, enable) => {
      draft.ui.enable_border = enable
    },
    setBorderColor: (draft, color) => {
      draft.ui.border.color = color
    },
    setBorderWidth: (draft, width) => {
      draft.ui.border.width = width
    },
    setBorderRadius: (draft, radius) => {
      draft.ui.border.radius = radius
    },
    setBorderStyle: (draft, style) => {
      draft.ui.border.style = style
    },

    // Gradient Actions
    enableGradient: (draft, enable) => {
      draft.ui.enable_gradient = enable
    },
    setGradientColor: (draft, color, idx) => {
      draft.ui.gradient.colors[idx] = color
    },
    setGradientDirection: (draft, direction) => {
      draft.ui.gradient.direction = direction
    },

    // Progress Bar Actions
    enableProgressBar: (draft, enable) => {
      draft.ui.enable_progressbar = enable
      if (draft.ui.progressbar.width === 0) {
        draft.ui.progressbar.width = 5
      }
    },
    setProgressBarWidth: (draft, width) => {
      if (width === 0) {
        draft.ui.enable_progressbar = false
      }
      draft.ui.progressbar.width = width
    },
    setProgressBarProgressColor: (draft, color) => {
      draft.ui.progressbar.progress_color = color
    },
    setProgressBarRemainingColor: (draft, color) => {
      draft.ui.progressbar.remaining_color = color
    },
    setProgressBarPosition: (draft, position) => {
      draft.ui.progressbar.position = position
    },

    // Header Actions
    enableHeader: (draft, enable) => {
      draft.ui.enable_header = enable
    },
    setHeaderBackgroundColor: (draft, color, colorStyle) => {
      if (colorStyle === COLOR_STYLE_ENUM.GRADIENT) {
        draft.ui.header.enable_gradient = true
        draft.ui.header.gradient = getGradientConfigs(color)
      } else {
        draft.ui.header.enable_gradient = false
        draft.ui.header.background_color = color
      }
    },

    // Header Border Actions
    enableHeaderBorder: (draft, enable) => {
      draft.ui.header.enable_border = enable
    },
    setHeaderBorderColor: (draft, color) => {
      draft.ui.header.border.color = color
    },
    setHeaderBorderWidth: (draft, width) => {
      draft.ui.header.border.width = width
    },
    setHeaderBorderRadius: (draft, radius) => {
      draft.ui.header.border.radius = radius
    },
    setHeaderBorderStyle: (draft, style) => {
      draft.ui.header.border.style = style
    },

    // Header Gradient Actions
    enableHeaderGradient: (draft, enable) => {
      draft.ui.header.enable_gradient = enable
    },
    setHeaderGradientColor: (draft, color, idx) => {
      draft.ui.header.gradient.colors[idx] = color
    },
    setHeaderGradientDirection: (draft, direction) => {
      draft.ui.header.gradient.direction = direction
    },

    // Header Image Actions
    enableHeaderImage: (draft, enable) => {
      draft.ui.header.enable_image = enable
    },
    setHeaderImagePath: (draft, path) => {
      draft.ui.header.image.path = path
    },
    setHeaderImageHeight: (draft, height) => {
      draft.ui.header.image.height = height
    },
    setHeaderImageWidth: (draft, width) => {
      draft.ui.header.image.width = width
    },

    // Header Image Border Actions
    enableHeaderImageBorder: (draft, enable) => {
      draft.ui.header.image.enable_border = enable
    },
    setHeaderImageBorderColor: (draft, color) => {
      draft.ui.header.image.border.color = color
    },
    setHeaderImageBorderWidth: (draft, width) => {
      draft.ui.header.image.border.width = width
    },
    setHeaderImageBorderRadius: (draft, radius) => {
      draft.ui.header.image.border.radius = radius
    },
    setHeaderImageBorderStyle: (draft, style) => {
      draft.ui.header.image.border.style = style
    },

    // Header Image As Background Actions
    enableHeaderImageAsBackground: (draft, enable) => {
      draft.ui.header.image.enable_background_image = enable
    },
    setHeaderImageBackgroundMaskColor: (draft, color) => {
      draft.ui.header.image.background_image.mask_color = color
    },
    setHeaderImageBackgroundMaskOpacity: (draft, opacity) => {
      draft.ui.header.image.background_image.mask_opacity = opacity
    },

    // Header Title Actions
    enableHeaderTitle: (draft, enable) => {
      draft.ui.header.enable_title = enable
    },
    setHeaderTitleText: (draft, text) => {
      draft.ui.header.title.text = text
    },
    setHeaderTitleColor: (draft, color) => {
      draft.ui.header.title.color = color
    },
    setHeaderTitleBackgroundColor: (draft, color) => {
      draft.ui.header.title.background_color = color
    },
    setHeaderTitleMaxCharacters: (draft, max) => {
      draft.ui.header.title.max_characters = max
    },
    setHeaderTitleAlignment: (draft, alignment) => {
      draft.ui.header.title.alignment = alignment
    },
    setHeaderTitleDynamicScript: (draft, action) => {
      const conf = draft.ui.header.title
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setHeaderTitleFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.header.title.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.header.title.font.source = config.source
        draft.ui.header.title.font.url = config.url
      }

      draft.ui.header.title.font.family = config.family
    },
    setHeaderTitleFontSize: (draft, size) => {
      draft.ui.header.title.font.size = size
    },
    setHeaderTitleFontWeight: (draft, weight) => {
      draft.ui.header.title.font.weight = weight
    },
    setHeaderTitleFontStyle: (draft, style) => {
      draft.ui.header.title.font.style = style
    },
    setHeaderTitlePadding: (draft, dir, value) => {
      draft.ui.header.title.padding[dir] = value
    },
    setHeaderTitleMargin: (draft, dir, value) => {
      draft.ui.header.title.margin[dir] = value
    },

    // Header Description Actions
    enableHeaderDescription: (draft, enable) => {
      draft.ui.header.enable_description = enable
    },
    setHeaderDescriptionText: (draft, text) => {
      draft.ui.header.description.text = text
    },
    setHeaderDescriptionColor: (draft, color) => {
      draft.ui.header.description.color = color
    },
    setHeaderDescriptionBackgroundColor: (draft, color) => {
      draft.ui.header.description.background_color = color
    },
    setHeaderDescriptionMaxCharacters: (draft, max) => {
      draft.ui.header.description.max_characters = max
    },
    setHeaderDescriptionAlignment: (draft, alignment) => {
      draft.ui.header.description.alignment = alignment
    },
    setHeaderDescriptionDynamicScript: (draft, action) => {
      const conf = draft.ui.header.description
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },
    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setHeaderDescriptionFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.header.description.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.header.description.font.source = config.source
        draft.ui.header.description.font.url = config.url
      }

      draft.ui.header.description.font.family = config.family
    },
    setHeaderDescriptionFontSize: (draft, size) => {
      draft.ui.header.description.font.size = size
    },
    setHeaderDescriptionFontWeight: (draft, weight) => {
      draft.ui.header.description.font.weight = weight
    },
    setHeaderDescriptionFontStyle: (draft, style) => {
      draft.ui.header.description.font.style = style
    },
    setHeaderDescriptionPadding: (draft, dir, value) => {
      draft.ui.header.description.padding[dir] = value
    },
    setHeaderDescriptionMargin: (draft, dir, value) => {
      draft.ui.header.description.margin[dir] = value
    },

    // Stepper Actions
    enableStepper: (draft, enable) => {
      draft.ui.header.enable_stepper = enable
    },
    setStepperType: (draft, type) => {
      draft.ui.header.stepper.type = type
    },
    makeStepperNumbered: (draft, enable) => {
      draft.ui.header.stepper.enable_numbered = enable
    },
    setStepperText: (draft, text) => {
      draft.ui.header.stepper.pagination_text.text = text
    },
    setStepperColor: (draft, color) => {
      draft.ui.header.stepper.pagination_text.color = color
    },
    setStepperBackgroundColor: (draft, color) => {
      draft.ui.header.stepper.pagination_text.background_color = color
    },
    setStepperMaxCharacters: (draft, max) => {
      draft.ui.header.stepper.pagination_text.max_characters = max
    },
    setStepperAlignment: (draft, alignment) => {
      draft.ui.header.stepper.pagination_text.alignment = alignment
    },
    setStepperTextDynamicScript: (draft, action) => {
      const conf = draft.ui.header.stepper.pagination_text
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setStepperFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.header.stepper.pagination_text.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.header.stepper.pagination_text.font.source = config.source
        draft.ui.header.stepper.pagination_text.font.url = config.url
      }

      draft.ui.header.stepper.pagination_text.font.family = config.family
    },
    setStepperFontSize: (draft, size) => {
      draft.ui.header.stepper.pagination_text.font.size = size
    },
    setStepperFontWeight: (draft, weight) => {
      draft.ui.header.stepper.pagination_text.font.weight = weight
    },
    setStepperFontStyle: (draft, style) => {
      draft.ui.header.stepper.pagination_text.font.style = style
    },
    setStepperPadding: (draft, dir, value) => {
      draft.ui.header.stepper.padding[dir] = value
    },
    setStepperMargin: (draft, dir, value) => {
      draft.ui.header.stepper.margin[dir] = value
    },

    // Back Button Actions
    enableBackButton: (draft, enable) => {
      draft.ui.header.enable_back_button = enable
    },
    setBackButtonPath: (draft, path) => {
      draft.ui.header.back_button.path = path
    },
    setBackButtonColor: (draft, color) => {
      draft.ui.header.back_button.color = color
    },
    setBackButtonFillColor: (draft, color) => {
      draft.ui.header.back_button.fill_color = color
    },
    setBackButtonType: (draft, type) => {
      draft.ui.header.back_button.type = type
    },
    setBackButtonShape: (draft, shape) => {
      draft.ui.header.back_button.shape = shape
    },
    setBackButtonHeight: (draft, height) => {
      draft.ui.header.back_button.height = height
    },
    setBackButtonWidth: (draft, width) => {
      draft.ui.header.back_button.width = width
    },
    setBackButtonPosition: (draft, position) => {
      draft.ui.header.back_button.position.position = position
    },
    setBackButtonMargin: (draft, dir, value) => {
      draft.ui.header.back_button.margin[dir] = value
    },

    // Close Button Actions
    enableCloseButton: (draft, enable) => {
      draft.ui.header.enable_close_button = enable
    },
    setCloseButtonPath: (draft, path) => {
      draft.ui.header.close_button.path = path
    },
    setCloseButtonColor: (draft, color) => {
      draft.ui.header.close_button.color = color
    },
    setCloseButtonFillColor: (draft, color) => {
      draft.ui.header.close_button.fill_color = color
    },
    setCloseButtonType: (draft, type) => {
      draft.ui.header.close_button.type = type
    },
    setCloseButtonShape: (draft, shape) => {
      draft.ui.header.close_button.shape = shape
    },
    setCloseButtonHeight: (draft, height) => {
      draft.ui.header.close_button.height = height
    },
    setCloseButtonWidth: (draft, width) => {
      draft.ui.header.close_button.width = width
    },
    setCloseButtonPosition: (draft, position) => {
      draft.ui.header.close_button.position.position = position
    },
    setCloseButtonMargin: (draft, dir, value) => {
      draft.ui.header.close_button.margin[dir] = value
    },

    // Body Actions
    setBodyBackgroundColor: (draft, color) => {
      draft.ui.survey.background_color = color
    },
    setIsResponseBasedSurvey: (draft, isResponseBasedSurvey) => {
      draft.ui.survey.is_response_based_survey = isResponseBasedSurvey
    },

    // Body Border Actions
    enableBodyBorder: (draft, enable) => {
      draft.ui.survey.enable_border = enable
    },
    setBodyBorderColor: (draft, color) => {
      draft.ui.survey.border.color = color
    },
    setBodyBorderWidth: (draft, width) => {
      draft.ui.survey.border.width = width
    },
    setBodyBorderRadius: (draft, radius) => {
      draft.ui.survey.border.radius = radius
    },
    setBodyBorderStyle: (draft, style) => {
      draft.ui.survey.border.style = style
    },

    // Close Button Actions in Survey Question level
    enableSurveyCloseButton: (draft, enable) => {
      if (!draft.ui.survey.hasOwnProperty("enable_close_button")) {
        draft.ui.survey.enable_close_button = false
        const closeButtonConfig = {
          ...CLOSE_BUTTON.close_button,
          color: "#5965EC",
        }
        draft.ui.survey.close_button = closeButtonConfig
      }
      draft.ui.survey.enable_close_button = enable
    },
    setSurveyCloseButtonPath: (draft, path) => {
      draft.ui.survey.close_button.path = path
    },
    setSurveyCloseButtonColor: (draft, color) => {
      draft.ui.survey.close_button.color = color
    },
    setSurveyCloseButtonFillColor: (draft, color) => {
      draft.ui.survey.close_button.fill_color = color
    },
    setSurveyCloseButtonType: (draft, type) => {
      draft.ui.survey.close_button.type = type
    },
    setSurveyCloseButtonShape: (draft, shape) => {
      draft.ui.survey.close_button.shape = shape
    },
    setSurveyCloseButtonHeight: (draft, height) => {
      draft.ui.survey.close_button.height = height
    },
    setSurveyCloseButtonWidth: (draft, width) => {
      draft.ui.survey.close_button.width = width
    },
    setSurveyCloseButtonPosition: (draft, position) => {
      draft.ui.survey.close_button.position.position = position
    },
    setSurveyCloseButtonMargin: (draft, dir, value) => {
      draft.ui.survey.close_button.margin[dir] = value
    },

    // Footer Actions
    enableFooter: (draft, enable) => {
      draft.ui.survey.enable_footer = enable
    },
    setFooterBackgroundColor: (draft, color, colorStyle) => {
      if (colorStyle === COLOR_STYLE_ENUM.GRADIENT) {
        draft.ui.survey.q_and_a[draft.currentStep].footer.enable_gradient = true
        draft.ui.survey.q_and_a[draft.currentStep].footer.gradient =
          getGradientConfigs(color)
      } else {
        draft.ui.survey.q_and_a[draft.currentStep].footer.enable_gradient =
          false
        draft.ui.survey.q_and_a[draft.currentStep].footer.background_color =
          color
      }
    },
    setFooterPadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a.forEach((q) => {
        q.footer.padding[dir] = value
      })
    },
    setFooterMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a.forEach((q) => {
        q.footer.margin[dir] = value
      })
    },

    // Footer Border Actions
    enableFooterBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.enable_border = enable
    },
    setFooterBorderColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.border.color = color
    },
    setFooterBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.border.width = width
    },
    setFooterBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.border.radius = radius
    },
    setFooterBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.border.style = style
    },

    // Footer Gradient Actions
    enableFooterGradient: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.enable_gradient = enable
    },
    setFooterGradientColor: (draft, color, idx) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.gradient.colors[idx] =
        color
    },
    setFooterGradientDirection: (draft, direction) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.gradient.direction =
        direction
    },

    // Button Actions
    addButton: (draft) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons.push(BUTTON)
    },
    deleteButton: (draft) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons = []
    },
    setButtonActiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].active_color = color
    },
    setButtonInactiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].inactive_color = color
    },
    setButtonType: (draft, type) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].type = type
    },
    // Button padding and margin actions
    setButtonPadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a.forEach((q) => {
        q.footer.buttons[0].padding[dir] = value
      })
    },
    setButtonMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a.forEach((q) => {
        q.footer.buttons[0].margin[dir] = value
      })
    },

    // Button Border Actions
    enableButtonBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].enable_border = enable
    },
    setButtonBorderColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].border.color = color
    },
    setButtonBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].border.width = width
    },
    setButtonBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].border.radius = radius
    },
    setButtonBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].border.style = style
    },

    // Button Position Actions
    setButtonPosition: (draft, position) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].position.position = position
    },

    // Button Text Actions
    setButtonText: (draft, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].text.text =
        text
    },
    setButtonTextColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].text.color =
        color
    },
    setButtonTextBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.background_color = color
    },
    setButtonTextMaxCharacters: (draft, max) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.max_characters = max
    },
    setButtonTextAlignment: (draft, alignment) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.alignment = alignment
    },
    setButtonTextDynamicScript: (draft, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].text
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },
    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setButtonTextFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].footer.buttons[0].text.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].footer.buttons[0].text.font.source = config.source
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].footer.buttons[0].text.font.url = config.url
      }

      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.font.family = config.family
    },
    setButtonTextFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.font.size = size
    },
    setButtonTextFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.font.weight = weight
    },
    setButtonTextFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].text.font.style = style
    },
    setButtonTextPadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].text.padding[
        dir
      ] = value
    },
    setButtonTextMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].text.margin[
        dir
      ] = value
    },

    // Button Action Actions
    setButtonActionType: (draft, type) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].action.type =
        type
    },
    setButtonActionUrl: (draft, url) => {
      draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].action.url =
        url
    },
    setButtonActionAsExternal: (draft, isExternal) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].action.is_external = isExternal
    },
    setButtonActionAsWithin: (draft, isWithin) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].action.is_within = isWithin
    },
    setButtonActionAsIr: (draft, isIr) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].footer.buttons[0].action.is_ir = isIr
    },

    // Question Actions
    addQuestion: (draft, answer_mode, themeUi) => {
      const list = [
        {
          ...LIST_ITEM,
          id: getQAId(),
          value: "Choice 1",
        },
        {
          ...LIST_ITEM,
          id: getQAId(),
          value: "Choice 2",
        },
        {
          ...LIST_ITEM,
          id: getQAId(),
          value: "Choice 3",
        },
      ]

      const start = RATING_BUTTONS.buttons.start
      const end = RATING_BUTTONS.buttons.end

      const ratings = Array.from({ length: end - start + 1 }, (_, i) => ({
        value: Number(i + 1),
        color: getEnableColors(i + 1, start, end) || "#ccc",
      }))

      if (Object.entries(themeUi).length > 0) {
        draft.ui.survey.q_and_a.push({
          ...Q_AND_A,
          question: {
            ...Q_AND_A.question,
            id: getQAId(),
            title: {
              ...Q_AND_A.question.title,
              text: "How likely are you to recommend this product to a friend?",
              color:
                themeUi.surveyBodyConfigs.text_configs.question
                  .question_font_color ?? "#002845",
              alignment:
                themeUi.surveyBodyConfigs.text_configs.question
                  .question_font_alignment,
              font: {
                ...Q_AND_A.question.title.font,
                size: themeUi.surveyBodyConfigs.text_configs.question
                  .question_font_size,
                family:
                  themeUi.surveyBodyConfigs.text_configs.question
                    .question_font_family,
                style:
                  themeUi.surveyBodyConfigs.text_configs.question
                    .question_font_style,
                weight:
                  themeUi.surveyBodyConfigs.text_configs.question
                    .question_font_weight,
              },
              margin: {
                ...Q_AND_A.question.title.margin,
                bottom: 12,
                top: 12,
                left: 16,
                right: 16,
              },
            },
            description: {
              ...Q_AND_A.question.description,
              enable_description:
                themeUi.surveyBodyConfigs.text_configs.description
                  .enable_description,
              text: "Tell us what your friends feel about this !",
              color:
                themeUi.surveyBodyConfigs.text_configs.description
                  .survey_body_font_color,
              alignment:
                themeUi.surveyBodyConfigs.text_configs.description
                  .survey_body_font_alignment,
              font: {
                ...Q_AND_A.question.description.font,
                size: themeUi.surveyBodyConfigs.text_configs.description
                  .survey_body_font_size,
                family:
                  themeUi.surveyBodyConfigs.text_configs.description
                    .survey_body_font_family,
                style:
                  themeUi.surveyBodyConfigs.text_configs.description
                    .survey_body_font_style,
                weight:
                  themeUi.surveyBodyConfigs.text_configs.description
                    .survey_body_font_weight,
              },
              margin: {
                ...Q_AND_A.question.description.margin,
                bottom: 12,
                top: 0,
                left: 16,
                right: 16,
              },
            },
          },
          answer: {
            ...Q_AND_A.answer,
            answer_mode,
            choices: {
              ...Q_AND_A.answer.choices,
              border: {
                ...Q_AND_A.answer.choices.border,
                color:
                  themeUi.surveyBodyConfigs.choice_config.active_border_color,
              },
              color_active:
                themeUi.surveyBodyConfigs.choice_config.active_text_color ??
                "#E5E5E5",
              color_inactive:
                themeUi.surveyBodyConfigs.choice_config.inactive_text_color ??
                "#002845",
              radio_color_active:
                themeUi.surveyBodyConfigs.choice_config.active_text_color ??
                "#E5E5E5",
              radio_color_inactive:
                themeUi.surveyBodyConfigs.choice_config.inactive_text_color ??
                "#002845",
              bg_color_active:
                themeUi.surveyBodyConfigs.choice_config.active_bg_color ??
                "#5965EC",
              bg_color_inactive:
                themeUi.surveyBodyConfigs.choice_config.inactive_bg_color ??
                "#E5E5E5",

              bg_type:
                themeUi.surveyBodyConfigs.choice_config.choice_layout_type,

              ...([
                ANSWER_MODES.single_choice,
                ANSWER_MODES.multi_choice,
              ].includes(answer_mode) && { list }),
            },
            input_text: {
              ...Q_AND_A.answer.input_text,
              ...([ANSWER_MODES.text].includes(answer_mode) && {
                border: {
                  ...Q_AND_A.answer.input_text.border,
                  color:
                    themeUi.surveyBodyConfigs.feedback_box_config
                      .text_box_border_color,
                  radius: 10,
                  width: 1,
                },
                text: "",
                background_color:
                  themeUi.surveyBodyConfigs.feedback_box_config
                    .text_box_color ?? "#FFFFFF",
                text_color:
                  themeUi.surveyBodyConfigs.feedback_box_config
                    .text_box_input_color ?? "#000000",
                placeholder: {
                  ...Q_AND_A.answer.input_text.placeholder,
                  text: "Give your reason...",
                  color:
                    themeUi.surveyBodyConfigs.feedback_box_config
                      .text_box_input_color ?? "#999999",
                },
              }),
            },
            rating: {
              ...Q_AND_A.answer.rating,
              buttons: {
                ...Q_AND_A.answer.rating.buttons,
                ...([ANSWER_MODES.rating].includes(answer_mode) && {
                  buttons: ratings,
                }),
              },
            },
            ...([ANSWER_MODES.text].includes(answer_mode) && {
              margin: {
                ...Q_AND_A.answer.margin,
                bottom: 12,
                top: 12,
                left: 16,
                right: 16,
              },
            }),
          },
          footer: {
            ...Q_AND_A.footer,
            background_color:
              themeUi.footer_configs.background_color ?? "#FFFFFF",
            border: {
              ...Q_AND_A.footer.border,
              color: themeUi.footer_configs.border.stroke_color,
              style: themeUi.footer_configs.border.stroke_style,
              radius: themeUi.footer_configs.border.stroke_radius,
              width: themeUi.footer_configs.border.stroke_width,
            },
            padding: {
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
            buttons: [
              {
                ...BUTTON,
                type: themeUi.buttonConfigs[0].button_type,
                active_color:
                  themeUi.buttonConfigs[0].active_filled_button_color,
                inactive_color:
                  themeUi.buttonConfigs[0].inactive_filled_button_color,
                border: {
                  ...BUTTON.border,
                  color: themeUi.buttonConfigs[0].border_color,
                  radius: themeUi.buttonConfigs[0].corner_radius,
                  style: themeUi.buttonConfigs[0].border_style,
                  width: themeUi.buttonConfigs[0].border_width,
                },
                text: {
                  ...BUTTON.text,
                  color: themeUi.buttonConfigs[0].button_text.active_font_color,
                  font: {
                    ...BUTTON.text.font,
                    family: themeUi.buttonConfigs[0].button_text.font_family,
                    style: themeUi.buttonConfigs[0].button_text.font_style,
                    size: themeUi.buttonConfigs[0].button_text.font_size,
                    weight: themeUi.buttonConfigs[0].button_text.font_weight,
                  },
                },
                ...(draft.ui.survey.q_and_a.length && {
                  margin: {
                    ...draft.ui.survey.q_and_a[0].footer.buttons[0].margin,
                  },
                  padding: {
                    ...draft.ui.survey.q_and_a[0].footer.buttons[0].padding,
                  },
                }),
              },
            ],
          },
        })
      } else {
        draft.ui.survey.q_and_a.push({
          ...Q_AND_A,
          question: {
            ...Q_AND_A.question,
            id: getQAId(),
            title: {
              ...Q_AND_A.question.title,
              text: "How likely are you to recommend this product to a friend?",
              color: "#002845",
              font: {
                ...Q_AND_A.question.title.font,
                size: 14,
                weight: "bold",
              },
              margin: {
                ...Q_AND_A.question.title.margin,
                bottom: 12,
                top: 12,
                left: 16,
                right: 16,
              },
            },
            description: {
              ...Q_AND_A.question.description,
              text: "Tell us what your friends feel about this !",
              font: {
                ...Q_AND_A.question.description.font,
                size: 12,
              },
              margin: {
                ...Q_AND_A.question.description.margin,
                bottom: 12,
                top: 0,
                left: 16,
                right: 16,
              },
            },
          },
          answer: {
            ...Q_AND_A.answer,
            answer_mode,
            choices: {
              ...Q_AND_A.answer.choices,
              ...([
                ANSWER_MODES.single_choice,
                ANSWER_MODES.multi_choice,
              ].includes(answer_mode) && { list }),
            },
            input_text: {
              ...Q_AND_A.answer.input_text,
              ...([ANSWER_MODES.text].includes(answer_mode) && {
                border: {
                  ...Q_AND_A.answer.input_text.border,
                  radius: 10,
                  width: 1,
                },
                text: "",
                placeholder: {
                  ...Q_AND_A.answer.input_text.placeholder,
                  text: "Give your reason...",
                  color: "#999999",
                },
              }),
            },
            rating: {
              ...Q_AND_A.answer.rating,
              buttons: {
                ...Q_AND_A.answer.rating.buttons,
                ...([ANSWER_MODES.rating].includes(answer_mode) && {
                  buttons: ratings,
                }),
              },
            },
          },
          footer: {
            ...Q_AND_A.footer,
            padding: {
              top: 32,
              bottom: 36,
              left: 16,
              right: 16,
            },
            buttons: [{ ...BUTTON, border: { ...BUTTON.border, radius: 10 } }],
          },
        })
      }

      if (draft.ui.survey.q_and_a.length > 1) {
        draft.ui.enable_progressbar = true
      }
    },
    moveQuestion: (draft, from, to) => {
      draft.ui.survey.q_and_a = arrayMove(draft.ui.survey.q_and_a, from, to)
    },
    copyQuestion: (draft, idx) => {
      let temp_new = {
        ...draft.ui.survey.q_and_a[idx],
        question: {
          ...draft.ui.survey.q_and_a[idx].question,
          id: getQAId(),
        },
      }
      draft.ui.survey.q_and_a.push(temp_new)
    },
    removeQuestion: (draft, idx) => {
      const deletedStepQuestionId = draft.ui.survey.q_and_a[idx].question.id
      draft.ui.survey.q_and_a.splice(idx, 1)
      draft.ui.survey.q_and_a.forEach((eachStep) => {
        if (
          eachStep.question.paths.hasOwnProperty(
            deletedStepQuestionId.toString(),
          )
        ) {
          delete eachStep.question.paths[deletedStepQuestionId.toString()]
        }
      })
      if (draft.ui.survey.q_and_a.length <= 0) {
        draft.ui.header = HEADER.header
      }

      if (draft.currentStep > draft.ui.survey.q_and_a.length - 1) {
        draft.currentStep = draft.ui.survey.q_and_a.length - 1
      }
    },
    makeQuestionRequired: (draft, isRequired) => {
      draft.ui.survey.q_and_a[draft.currentStep].is_required = isRequired
      if (
        draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].action
          .type === "dismiss" &&
        isRequired
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].footer.buttons[0].action.type = "next"
      }
    },
    makeTextRequired: (draft, isRequired) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.is_text_mandatory =
        isRequired
    },
    enableChoiceLimits: (draft, enable) => {
      if (
        draft.ui.survey.q_and_a[draft.currentStep].answer.answer_mode ===
        "multi_choice"
      ) {
        let answer = draft.ui.survey.q_and_a[draft.currentStep].answer
        answer.choices.min_choices_enabled = enable
        answer.choices.max_choices_enabled = enable
        let question = draft.ui.survey.q_and_a[draft.currentStep].question
        if (!question.choice_prompt) {
          question.choice_prompt = {
            ...CHOICE_PROMPT.choice_prompt,
          }
        }
        if (!answer.choices.min_choices || answer.choices.min_choices < 0) {
          answer.choices.min_choices = 0
        }
        draft.ui.survey.q_and_a[draft.currentStep].is_required =
          answer.choices.min_choices > 0
        if (
          !answer.choices.max_choices ||
          answer.choices.max_choices < 0 ||
          !(answer.choices.max_choices <= answer.choices.list.length)
        ) {
          answer.choices.max_choices = answer.choices.list?.length ?? 1
        }
        if (!answer.choices.color_disable) {
          answer.choices.color_disable = CHOICES.color_disable
        }
        if (!answer.choices.bg_color_disable) {
          answer.choices.bg_color_disable = CHOICES.bg_color_disable
        }
        question.enable_choice_prompt = enable
        if (enable) {
          let limits = [answer.choices.min_choices, answer.choices.max_choices]
          question.choice_prompt.text = `Minimum: ${limits[0]} Maximum: ${limits[1]}`
        }
      }
    },

    setMaxChoicesLimit: (draft, limit = 0) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.max_choices =
        limit
      draft.ui.survey.q_and_a[draft.currentStep].question.choice_prompt.text =
        `Minimum: ${draft.ui.survey.q_and_a[draft.currentStep].answer.choices.min_choices} Maximum: ${limit}`
    },

    setMinChoicesLimit: (draft, limit = 0) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.min_choices =
        limit
      draft.ui.survey.q_and_a[draft.currentStep].question.choice_prompt.text =
        `Minimum: ${limit} Maximum: ${draft.ui.survey.q_and_a[draft.currentStep].answer.choices.max_choices}`
      draft.ui.survey.q_and_a[draft.currentStep].is_required = limit > 0
      if (
        draft.ui.survey.q_and_a[draft.currentStep].footer.buttons[0].action
          .type === "dismiss" &&
        limit > 0
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].footer.buttons[0].action.type = "next"
      }
    },

    // Question Title Actions
    enableQuestionTitle: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.enable_title = enable
    },
    setQuestionTitleText: (draft, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.text = text
    },
    setQuestionTitleColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.color = color
    },
    setQuestionTitleBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.title.background_color = color
    },
    setQuestionTitleMaxCharacters: (draft, max) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.max_characters =
        max
    },
    setQuestionTitleAlignment: (draft, alignment) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.alignment =
        alignment
    },
    setQuestionTitleDynamicScript: (draft, action) => {
      const conf = draft.ui.survey.q_and_a[draft.currentStep].question.title
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setQuestionTitleFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[draft.currentStep].question.title.font.type =
          config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[draft.currentStep].question.title.font.source =
          config.source
        draft.ui.survey.q_and_a[draft.currentStep].question.title.font.url =
          config.url
      }

      draft.ui.survey.q_and_a[draft.currentStep].question.title.font.family =
        config.family
    },
    setQuestionTitleFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.font.size = size
    },
    setQuestionTitleFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.font.weight =
        weight
    },
    setQuestionTitleFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.font.style =
        style
    },
    setQuestionTitlePadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.padding[dir] =
        value
    },
    setQuestionTitleMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.title.margin[dir] =
        value
    },

    // Question Description Actions
    enableQuestionDescription: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.enable_description =
        enable
    },
    setQuestionDescriptionText: (draft, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.description.text =
        text
    },
    setQuestionDescriptionColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.description.color =
        color
    },
    setQuestionDescriptionBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.background_color = color
    },
    setQuestionDescriptionMaxCharacters: (draft, max) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.max_characters = max
    },
    setQuestionDescriptionAlignment: (draft, alignment) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.alignment = alignment
    },
    setQuestionDescriptionDynamicScript: (draft, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].question.description
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setQuestionDescriptionFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].question.description.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].question.description.font.source = config.source
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].question.description.font.url = config.url
      }

      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.font.family = config.family
    },
    setQuestionDescriptionFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.font.size = size
    },
    setQuestionDescriptionFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.font.weight = weight
    },
    setQuestionDescriptionFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.description.font.style = style
    },
    setQuestionDescriptionPadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.description.padding[
        dir
      ] = value
    },
    setQuestionDescriptionMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.description.margin[
        dir
      ] = value
    },

    // Question Choice Prompt Actions
    enableQuestionChoicePrompt: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.enable_choice_prompt =
        enable
    },
    setQuestionChoicePromptText: (draft, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.choice_prompt.text =
        text
    },
    setQuestionChoicePromptColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.choice_prompt.color =
        color
    },
    setQuestionChoicePromptBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.background_color = color
    },
    setQuestionChoicePromptMaxCharacters: (draft, max) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.max_characters = max
    },
    setQuestionChoicePromptAlignment: (draft, alignment) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.alignment = alignment
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setQuestionChoicePromptFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].question.choice_prompt.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].question.choice_prompt.font.source = config.source
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].question.choice_prompt.font.url = config.url
      }

      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.font.family = config.family
    },
    setQuestionChoicePromptFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.font.size = size
    },
    setQuestionChoicePromptFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.font.weight = weight
    },
    setQuestionChoicePromptFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].question.choice_prompt.font.style = style
    },
    setQuestionChoicePromptPadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.choice_prompt.padding[
        dir
      ] = value
    },
    setQuestionChoicePromptMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.choice_prompt.margin[
        dir
      ] = value
    },

    // Answer Actions
    setAnswerMode: (draft, mode) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.answer_mode = mode
    },
    setAnswerBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.background_color = color
    },
    setAnswerPadding: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.padding[dir] = value
    },
    setAnswerMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.margin[dir] = value
    },

    // Answer Font Actions

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setAnswerFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.font.type =
          config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.font.source =
          config.source
        draft.ui.survey.q_and_a[draft.currentStep].answer.font.url = config.url
      }

      draft.ui.survey.q_and_a[draft.currentStep].answer.font.family =
        config.family
    },
    setAnswerFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.font.size = size
    },
    setAnswerFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.font.weight = weight
    },
    setAnswerFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.font.style = style
    },

    // Answer Border Actions
    enableAnswerBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.enable_border = enable
    },
    setAnswerBorderColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.border.color = color
    },
    setAnswerBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.border.width = width
    },
    setAnswerBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.border.radius = radius
    },
    setAnswerBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.border.style = style
    },

    setTotalSurveyHolderheight: (draft, height) => {
      draft.ui.holderheight = height
    },

    // Answer Scroll Actions
    enableAnswerSectionScroll: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.enable_scroll = enable
    },
    setAnswerSectionScrollHeight: (draft, height) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.scroll.height = height
    },

    // Answer Scroll Border Actions
    enableAnswerSectionScrollBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.scroll.enable_border =
        enable
    },
    setAnswerSectionScrollBorderColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.scroll.border.color =
        color
    },
    setAnswerSectionScrollBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.scroll.border.width =
        width
    },
    setAnswerSectionScrollBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.scroll.border.radius =
        radius
    },
    setAnswerSectionScrollBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.scroll.border.style =
        style
    },

    // Answer Input Text Actions
    setAnswerInputTextBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.background_color = color
    },
    setAnswerInputTextMaxCharacters: (draft, max) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.max_characters = max
    },
    setAnswerInputTextText: (draft, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.input_text.text = text
    },
    setAnswerInputTextColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.input_text.text_color =
        color
    },

    // Answer Input Text Border Actions
    enableAnswerInputTextBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.enable_border = enable
    },
    setAnswerInputTextBorderColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.border.color = color
    },
    setAnswerInputTextBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.border.width = width
    },
    setAnswerInputTextBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.border.radius = radius
    },
    setAnswerInputTextBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.border.style = style
    },

    // Answer Input Text Placeholder Actions
    enableAnswerInputTextPlaceholder: (draft, enable) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.enable_placeholder = enable
    },
    setAnswerInputTextPlaceholderText: (draft, placeholder) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.placeholder.text = placeholder
    },
    setAnswerInputTextPlaceholderColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.input_text.placeholder.color = color
    },

    // Choices Actions
    setRandomChoices: (draft, randomize) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.choices.randomize_choices = randomize
    },
    setChoicesType: (draft, type) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.bg_type = type
    },
    setActiveChoiceColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.color_active =
        color
    },
    setInactiveChoiceColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.color_inactive =
        color
    },
    setDisableChoiceColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.color_disable =
        color
    },
    setActiveChoiceBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.choices.bg_color_active = color
    },
    setInactiveChoiceBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.choices.bg_color_inactive = color
    },
    setDisableChoiceBackgroundColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.choices.bg_color_disable = color
    },
    setActiveChoiceRadioColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.choices.radio_color_active = color
    },
    setInactiveChoiceRadioColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.choices.radio_color_inactive = color
    },

    // Choices Border Actions
    enableChoicesBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.enable_border =
        enable
    },
    setChoicesBorderColor: (draft, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.border.color =
        color
    },
    setChoicesBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.border.width =
        width
    },
    setChoicesBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.border.radius =
        radius
    },
    setChoicesBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.border.style =
        style
    },

    // Single Choice Actions
    addChoice: (draft, isOther = false) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list.push({
        id: getQAId(),
        is_other: isOther,
        value: isOther
          ? "Other"
          : `Choice ${
              draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list
                .length + 1
            }`,
        next_question_id: -1,
      })

      if (isOther) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.input_text = {
          ...draft.ui.survey.q_and_a[draft.currentStep].answer.input_text,
          border: {
            ...draft.ui.survey.q_and_a[draft.currentStep].answer.input_text
              .border,
            radius: 10,
            width: 1,
          },
          text: "",
          placeholder: {
            ...draft.ui.survey.q_and_a[draft.currentStep].answer.input_text
              .placeholder,
            text: "Give your reason...",
            color: "#999999",
          },
        }
      } else {
        const hasOtherChoice = draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.choices.list.some((choice) => choice.is_other)
        if (hasOtherChoice) {
          const otherChoiceIdx = draft.ui.survey.q_and_a[
            draft.currentStep
          ].answer.choices.list.findIndex((choice) => choice.is_other)

          // make the other choice the last choice
          draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list =
            arrayMove(
              draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list,
              otherChoiceIdx,
              draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list
                .length - 1,
            )
        }
      }
    },
    updateChoice: (draft, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list[
        draft.choiceIdx
      ].value = value
    },
    setChoiceDynamicScript: (draft, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].answer.choices.list[
          draft.choiceIdx
        ]
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.value = isDynamic ? action.text : conf.value
      conf.is_dyn = isDynamic
    },
    removeChoice: (draft, idx) => {
      let choices = draft.ui.survey.q_and_a[draft.currentStep].answer.choices
      choices.list.splice(idx, 1)
      if (
        choices.max_choices &&
        choices.list.length > 0 &&
        choices.max_choices > choices.list.length
      ) {
        choices.max_choices = choices.list.length
      }
    },

    // NPS Buttons Root Actions
    setNpsButtonsStart: (draft, start) => {
      const end =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.end
      const type =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.type

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.buttons =
        generateNpsArray(start, end)

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.start =
        start
      if (type === "emoji" && start === 10) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.split_line = true

        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.first_rating.position = "top"
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.last_rating.position = "bottom"
      }
    },
    setNpsButtonsEnd: (draft, end) => {
      const start =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.start
      const type =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.type

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.buttons =
        generateNpsArray(start, end)

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.end = end
      if (type === "emoji" && end === 10) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.split_line = true

        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.first_rating.position = "top"
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.last_rating.position = "bottom"
      }
    },
    switchNpsButtonsLimits: (draft) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.buttons.reverse()

      const swap =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.start
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.start =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.end
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.end =
        swap
    },

    /**
     * @param {"numbered" | "radio" | "slider" | "star" | "emoji"} style
     */
    setNpsNumberStyle: (draft, style) => {
      if (style === "slider") {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.split_line = false
      } else if (style !== "star") {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.enable_box_model = false
      } else if (style !== "numbered") {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.enable_colors = false
      }

      const start =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.start

      const end =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.end

      const buttons =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.buttons

      if (style === "star") {
        // When Star, set border width to zero
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.border.width = 0
        if (start > end) {
          draft.ui.survey.q_and_a[
            draft.currentStep
          ].answer.rating.buttons.buttons = generateNpsArray(
            end,
            start,
            buttons,
          )
          draft.ui.survey.q_and_a[
            draft.currentStep
          ].answer.rating.buttons.start = end
          draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.end =
            start
        }
      } else {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.buttons = generateNpsArray(start, end, buttons)
      }

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.type =
        style
    },
    setNpsSplitLine: (draft, split) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.split_line = split
      if (split) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.first_rating.position = "top"
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.last_rating.position = "bottom"
      }
    },
    setNpsButtonFadeEnable: (draft, state) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_fade = state
      if (state) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.bg_color_active = "transparent"
      }
    },
    setNpsButtonShadowEnable: (draft, state) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_shadow = state
      if (state) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.bg_color_active = "transparent"
      }
    },
    setNpsButtonBorderEnable: (draft, state) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_border = state
      if (
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
          ?.bg_color_active &&
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
          .bg_color_active === "transparent"
      )
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.bg_color_active = "#017DFD"
    },
    setNpsButtonFadeShadowColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.shadow.color = color
    },
    setNpsButtonFadeShadowBlur: (draft, blur) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.shadow.blur_radius = blur
    },
    setNpsButtonFadeShadowSpread: (draft, spread) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.shadow.spread_radius = spread
    },
    setNpsButtonFadeShadowOffsetX: (draft, x) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.shadow.offset_x = x
    },
    setNpsButtonFadeShadowOffsetY: (draft, y) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.shadow.offset_y = y
    },

    // NPS Colors' Actions
    setNpsSliderColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.slider_color = color
    },
    setNpsActiveBgColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.bg_color_active = color
    },
    setNpsInactiveBgColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.bg_color_inactive = color
    },
    setNpsActiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.color_active = color
    },
    setNpsInactiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.color_inactive = color
    },
    setNpsEmoji: (draft, idx, emoji) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.buttons[
        idx
      ].emoji = emoji
    },

    // NPS Star Box Model Actions
    enableNpsBoxModel: (draft, enable) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_box_model = enable
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_margin = !enable
      if (enable) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.split_line = false
      }
    },
    setNpsBoxModelActiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.box_model.active_color = color
    },
    setNpsBoxModelInactiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.box_model.inactive_color = color
    },
    setNpsHeight: (draft, height) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.height =
        height
    },
    setNpsWidth: (draft, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.width =
        width
      const type =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.type ||
        ""
      if (type === "emoji") {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.font.size = width
      }
    },
    enableNpsColors: (draft, enable) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_colors = enable
      //Set Stroke width of Number Ratings to zero when Enabling Colors toggled
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.border.width = 0
    },
    setRatingButtonColor: (draft, buttonIndex, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.buttons[
        buttonIndex
      ].color = color
    },

    // NPS Buttons Border Actions
    enableNpsButtonsBorder: (draft, enable) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.enable_border = enable
    },
    setNpsButtonsBorderActiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.border.color = color
    },
    setNpsButtonsBorderInactiveColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.border.inactive_color = color
    },
    setNpsButtonsBorderWidth: (draft, width) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.border.width = width
    },
    setNpsButtonsBorderRadius: (draft, radius) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.border.radius = radius
    },
    setNpsButtonsBorderStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.border.style = style
    },

    // NPS Buttons Font Actions

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setNpsButtonsFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.font.source = config.source
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.font.url = config.url
      }

      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.font.family = config.family
    },
    setNpsButtonsFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.font.size = size
      const type =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.type ||
        ""
      if (type === "emoji") {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.width =
          size
      }
    },
    setNpsButtonsFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.font.weight = weight
    },
    setNpsButtonsFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.buttons.font.style = style
    },

    // NPS Buttons Padding & Margin Actions
    setNpsButtonsPadding: (draft, dir, value) => {
      if (
        !draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
          .enable_padding
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.buttons.enable_padding = true
      }
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.padding[
        dir
      ] = value
    },
    setNpsButtonsMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons.margin[
        dir
      ] = value
    },

    // NPS Labels Position Action
    setNpsStartLabelPosition: (draft, position) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.position = position
    },
    setNpsEndLabelPosition: (draft, position) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.position = position
    },

    // NPS Labels Actions
    enableNpsLabels: (draft, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.enable_labels =
        enable
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.labels =
        LABELS.labels
    },

    // NPS First Label Text Actions
    setNpsStartLabelText: (draft, label) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.text = label
    },
    setNpsStartLabelColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.color = color
    },
    setNpsStartLabelAlignment: (draft, alignment) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.alignment = alignment
    },
    setNpsStartLabelTextDynamicScript: (draft, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.labels
          .first_rating.text
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setNpsStartLabelFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.first_rating.text.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.first_rating.text.font.source = config.source
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.first_rating.text.font.url = config.url
      }

      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.font.family = config.family
    },
    setNpsStartLabelFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.font.size = size
    },
    setNpsStartLabelFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.font.weight = weight
    },
    setNpsStartLabelFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.font.style = style
    },

    // NPS Last Label Text Actions
    setNpsEndLabelText: (draft, label) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.text = label
    },
    setNpsEndLabelColor: (draft, color) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.color = color
    },
    setNpsEndLabelAlignment: (draft, alignment) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.alignment = alignment
    },
    setNpsEndLabelTextDynamicScript: (draft, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.labels
          .last_rating.text
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setNpsEndLabelFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.last_rating.text.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.last_rating.text.font.source = config.source
        draft.ui.survey.q_and_a[
          draft.currentStep
        ].answer.rating.labels.last_rating.text.font.url = config.url
      }

      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.font.family = config.family
    },
    setNpsEndLabelFontSize: (draft, size) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.font.size = size
    },
    setNpsEndLabelFontWeight: (draft, weight) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.font.weight = weight
    },
    setNpsEndLabelFontStyle: (draft, style) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.font.style = style
    },

    // NPS Labels Padding & Margin Actions
    setNpsStartLabelMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.first_rating.text.margin[dir] = value
    },
    setNpsEndLabelMargin: (draft, dir, value) => {
      draft.ui.survey.q_and_a[
        draft.currentStep
      ].answer.rating.labels.last_rating.text.margin[dir] = value
    },

    // NPS Reactions Actions
    addNpsReaction: (draft) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions.push({
        ...REACTION,
        start:
          draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
            .start,
        end: draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
          .end,
      })
    },
    deleteNpsReaction: (draft, idx) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions.splice(
        idx,
        1,
      )
    },
    clearNpsReactions: (draft) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions = []
    },
    setNpsReactionsStart: (draft, idx, start) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].start = start
    },
    setNpsReactionsEnd: (draft, idx, end) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].end = end
    },

    // NPS Reactions Text Actions
    enableNpsReactionText: (draft, idx, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].enable_text = enable
    },
    setNpsReactionText: (draft, idx, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.text = text
    },
    setNpsReactionTextColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.color = color
    },
    setNpsReactionTextAlignment: (draft, idx, alignment) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.alignment = alignment
    },
    setNpsReactionTextDynamicScript: (draft, idx, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[idx]
          .text
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {number} idx
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setNpsReactionTextFontFamily: (draft, idx, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
          idx
        ].text.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
          idx
        ].text.font.source = config.source
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
          idx
        ].text.font.url = config.url
      }

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.font.family = config.family
    },
    setNpsReactionTextFontSize: (draft, idx, size) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.font.size = size
    },
    setNpsReactionTextFontWeight: (draft, idx, weight) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.font.weight = weight
    },
    setNpsReactionTextFontStyle: (draft, idx, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.font.style = style
    },
    setNpsReactionTextPosition: (draft, idx, position) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text_position = position
    },

    // NPS Reactions Text Padding & Margin Actions
    setNpsReactionTextPadding: (draft, dir, idx, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.padding[dir] = value
    },
    setNpsReactionTextMargin: (draft, dir, idx, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].text.margin[dir] = value
    },

    // NPS Reactions Image Actions
    enableNpsReactionImage: (draft, idx, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].enable_image = enable
    },
    setNpsReactionImagePath: (draft, idx, path) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.path = path
    },
    setNpsReactionImageType: (draft, idx, type) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.type = type
    },
    setNpsReactionImageHeight: (draft, idx, height) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.height = height
    },
    setNpsReactionImageWidth: (draft, idx, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.width = width

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.height =
        width === 0
          ? 0
          : (width *
              draft.ui.survey.q_and_a[draft.currentStep].answer.rating
                .reactions[idx].image.aspect_ratio.height) /
            draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
              idx
            ].image.aspect_ratio.width
    },
    setNpsReactionImagePosition: (draft, idx, position) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.position = position
    },
    setNpsReactionImageAspectRatio: (draft, idx, width, height) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.aspect_ratio = {
        width,
        height,
      }
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.height =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[idx]
          .image.width === 0 || width === 0
          ? 0
          : (draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
              idx
            ].image.width *
              height) /
            width
    },

    // NPS Reactions Image Border Actions
    enableNpsReactionImageBorder: (draft, idx, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.enable_border = enable
    },
    setNpsReactionImageBorderColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.border.color = color
    },
    setNpsReactionImageBorderWidth: (draft, idx, width) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.border.width = width
    },
    setNpsReactionImageBorderRadius: (draft, idx, radius) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.border.radius = radius
    },
    setNpsReactionImageBorderStyle: (draft, idx, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.reactions[
        idx
      ].image.border.style = style
    },

    // NPS Suggestions Actions
    enableNpsInputText: (draft, idx, enabled) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].enable_input_text = enabled
    },
    enableNpsQuestion: (draft, idx, enabled) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].enable_question = enabled
    },
    addNpsSuggestion: (draft) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s.push({
        ...QUESTION_AND_SUGGESTION,
        start:
          draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
            .start,
        end: draft.ui.survey.q_and_a[draft.currentStep].answer.rating.buttons
          .end,
        question: {
          ...QUESTION_AND_SUGGESTION.question,
          enable_description: false,
          title: {
            ...QUESTION_AND_SUGGESTION.question.title,
            margin: {
              top: 12,
              bottom: 12,
              right: 16,
              left: 16,
            },
          },
        },
      })
    },
    deleteNpsSuggestion: (draft, idx) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s.splice(
        idx,
        1,
      )
    },
    clearNpsSuggestions: (draft) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s = []
    },
    setNpsSuggestionStart: (draft, idx, start) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].start = start
    },
    setNpsSuggestionEnd: (draft, idx, end) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].end = end
    },

    // NPS Suggestions Text Actions
    setNpsSuggestionQuestionTitleRequired: (draft, idx, required) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].is_required = required
    },
    setNpsSuggestionQuestionTitleText: (draft, idx, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.text = text
    },
    setNpsSuggestionQuestionTitleColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.color = color
    },
    setNpsSuggestionQuestionTitleAlignment: (draft, idx, alignment) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.alignment = alignment
    },
    setNpsSuggestionQuestionTitleDynamicScript: (draft, idx, action) => {
      const conf =
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[idx]
          .question.title
      const dynamicType = action.d_type
      const isDynamic =
        dynamicType === "u" || dynamicType === "v" || dynamicType === "s"
      if (!isDynamic) {
        delete conf.d_type
        delete conf.url
        delete conf.mvm
        delete conf.script
      } else {
        conf.d_type = dynamicType
        if (dynamicType === "v") {
          conf.mvm = action.data
          conf.url = ""
        } else if (dynamicType === "u") {
          conf.url = action.data
        } else if (dynamicType === "s") {
          conf.script = {
            cmds: action.data.commands,
            vmap: action.data.variableMap,
            src: action.data.originalScript,
            t: action.data.tab,
          }
        }
      }
      conf.text = isDynamic ? action.text : conf.text
      conf.is_dyn = isDynamic
    },

    /**
     * @param {number} idx
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setNpsSuggestionQuestionTitleFontFamily: (draft, idx, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
          idx
        ].question.title.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
          idx
        ].question.title.font.source = config.source
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
          idx
        ].question.title.font.url = config.url
      }

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.font.family = config.family
    },
    setNpsSuggestionQuestionTitleFontSize: (draft, idx, size) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.font.size = size
    },
    setNpsSuggestionQuestionTitleFontWeight: (draft, idx, weight) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.font.weight = weight
    },
    setNpsSuggestionQuestionTitleFontStyle: (draft, idx, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.font.style = style
    },

    // NPS Suggestions Text Padding & Margin Actions
    setNpsSuggestionQuestionTitlePadding: (draft, idx, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.padding[dir] = value
    },
    setNpsSuggestionQuestionTitleMargin: (draft, idx, dir, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].question.title.margin[dir] = value
    },

    // NPS Suggestions Texts' Actions
    enableNpsSuggestions: (draft, idx, enable) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].enable_suggestion = enable
    },
    addNpsSuggestionKey: (draft, idx, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.text.push(text)
    },
    deleteNpsSuggestionKey: (draft, idx, keyIdx) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.text.splice(keyIdx, 1)
    },
    setNpsSuggestionKeyText: (draft, idx, keyIdx, text) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.text[keyIdx] = text
    },
    setNpsSuggestionsActiveColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.color_active = color
    },
    setNpsSuggestionsInactiveColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.color_inactive = color
    },
    setNpsSuggestionsActiveBgColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.bg_color_active = color
    },
    setNpsSuggestionsInactiveBgColor: (draft, idx, color) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.bg_color_inactive = color
    },
    setNpsSuggestionsMargin: (draft, dir, idx, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.margin[dir] = value
    },
    setNpsSuggestionsPadding: (draft, dir, idx, value) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.padding[dir] = value
    },
    /**
     * @param {number} idx
     * @param {Object} config
     * @param {string} config.platform
     * @param {string} [config.kind]
     * @param {string} config.family
     * @param {string} [config.source]
     * @param {string} [config.url]
     */
    setNpsSuggestionsFontFamily: (draft, idx, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
          idx
        ].suggestion.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
          idx
        ].suggestion.font.source = config.source
        draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
          idx
        ].suggestion.font.url = config.url
      }

      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.font.family = config.family
    },
    setNpsSuggestionsFontSize: (draft, idx, size) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.font.size = size
    },
    setNpsSuggestionsFontWeight: (draft, idx, weight) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.font.weight = weight
    },
    setNpsSuggestionsFontStyle: (draft, idx, style) => {
      draft.ui.survey.q_and_a[draft.currentStep].answer.rating.q_and_s[
        idx
      ].suggestion.font.style = style
    },

    // Success Message Actions
    enableSuccessMessage: (draft, enable) => {
      draft.ui.enable_success_message_latest = enable
    },
    setSuccessMessageDuration: (draft, value) => {
      draft.ui.success_message.duration = value
    },
    setSuccessMessageWidth: (draft, width) => {
      draft.ui.success_message.width = width
    },
    setSuccessMessageIsFullscreen: (draft, enable) => {
      draft.ui.success_message.is_fullscreen = enable
    },
    setSuccessMessagePosition: (draft, position) => {
      draft.ui.success_message.position.position = position
    },
    setDefaultSuccessMessage: (draft) => {
      draft.ui.success_message = SUCCESS_MESSAGE.success_message
    },
    setSuccessMessageBackGround: (draft, color) => {
      draft.ui.success_message.background_color = color
    },
    setSuccessMessageMargin: (draft, dir, value) => {
      draft.ui.success_message.margin[dir] = value
    },
    enableSuccessMessageBorder: (draft, enable) => {
      draft.ui.success_message.enable_border = enable
    },
    setSuccessMessageBorderColor: (draft, color) => {
      draft.ui.success_message.border.color = color
    },
    setSuccessMessageBorderWidth: (draft, width) => {
      draft.ui.success_message.border.width = width
    },
    setSuccessMessageBorderRadius: (draft, radius) => {
      draft.ui.success_message.border.radius = radius
    },
    setSuccessMessageBorderStyle: (draft, style) => {
      draft.ui.success_message.border.style = style
    },

    // Survey Request Title Actions
    enableSuccessMessageTitle: (draft, enable) => {
      draft.ui.success_message.enable_title = enable
    },
    setSuccessMessageTitleText: (draft, text) => {
      draft.ui.success_message.title.text = text
    },
    setSuccessMessageTitleColor: (draft, color) => {
      draft.ui.success_message.title.color = color
    },
    setSuccessMessageTitleBackgroundColor: (draft, color) => {
      draft.ui.success_message.title.background_color = color
    },
    setSuccessMessageTitleMaxCharacters: (draft, max) => {
      draft.ui.success_message.title.max_characters = max
    },
    setSuccessMessageTitleAlignment: (draft, alignment) => {
      draft.ui.success_message.title.alignment = alignment
    },
    setSuccessMessageTitleFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.success_message.title.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.success_message.title.font.source = config.source
        draft.ui.success_message.title.font.url = config.url
      }

      draft.ui.success_message.title.font.family = config.family
    },
    setSuccessMessageTitleFontSize: (draft, size) => {
      draft.ui.success_message.title.font.size = size
    },
    setSuccessMessageTitleFontWeight: (draft, weight) => {
      draft.ui.success_message.title.font.weight = weight
    },
    setSuccessMessageTitleFontStyle: (draft, style) => {
      draft.ui.success_message.title.font.style = style
    },
    setSuccessMessageTitlePadding: (draft, dir, value) => {
      draft.ui.success_message.title.padding[dir] = value
    },
    setSuccessMessageTitleMargin: (draft, dir, value) => {
      draft.ui.success_message.title.margin[dir] = value
    },

    // Success Message Description Actions
    enableSuccessMessageDescription: (draft, enable) => {
      draft.ui.success_message.enable_description = enable
    },
    setSuccessMessageDescriptionText: (draft, text) => {
      draft.ui.success_message.description.text = text
    },
    setSuccessMessageDescriptionColor: (draft, color) => {
      draft.ui.success_message.description.color = color
    },
    setSuccessMessageDescriptionBackgroundColor: (draft, color) => {
      draft.ui.success_message.description.background_color = color
    },
    setSuccessMessageDescriptionMaxCharacters: (draft, max) => {
      draft.ui.success_message.description.max_characters = max
    },
    setSuccessMessageDescriptionAlignment: (draft, alignment) => {
      draft.ui.success_message.description.alignment = alignment
    },
    setSuccessMessageDescriptionFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.success_message.description.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.success_message.description.font.source = config.source
        draft.ui.success_message.description.font.url = config.url
      }

      draft.ui.success_message.description.font.family = config.family
    },
    setSuccessMessageDescriptionFontSize: (draft, size) => {
      draft.ui.success_message.description.font.size = size
    },
    setSuccessMessageDescriptionFontWeight: (draft, weight) => {
      draft.ui.success_message.description.font.weight = weight
    },
    setSuccessMessageDescriptionFontStyle: (draft, style) => {
      draft.ui.success_message.description.font.style = style
    },
    setSuccessMessageDescriptionPadding: (draft, dir, value) => {
      draft.ui.success_message.description.padding[dir] = value
    },
    setSuccessMessageDescriptionMargin: (draft, dir, value) => {
      draft.ui.success_message.description.margin[dir] = value
    },

    // Success Message Icon Actions
    enableSuccessMessageIcon: (draft, enable) => {
      draft.ui.success_message.enable_icon = enable
    },
    setSuccessMessageIconPath: (draft, path) => {
      draft.ui.success_message.icon.path = path
    },
    setSuccessMessageIconDefaultPath: (draft) => {
      draft.ui.success_message.icon.path = DEFAULT_SUCCESS_MESSAGE_IMAGE.path
    },
    setSuccessMessageIconHeight: (draft, height) => {
      draft.ui.success_message.icon.height = height
      draft.ui.success_message.icon.aspect_ratio = {
        width: "custom",
        height: "",
      }
    },
    setSuccessMessageIconWidth: (draft, width) => {
      draft.ui.success_message.icon.width = width
      draft.ui.success_message.icon.height =
        width === 0
          ? 0
          : (width * draft.ui.success_message.icon.aspect_ratio.height) /
            draft.ui.success_message.icon.aspect_ratio.width
    },
    setSuccessMessageIconAspectRatio: (draft, width, height) => {
      draft.ui.success_message.icon.aspect_ratio = {
        width,
        height,
      }
      draft.ui.success_message.icon.height =
        draft.ui.success_message.icon.width === 0 || width === 0
          ? 0
          : (draft.ui.success_message.icon.width * height) / width
    },
    setSuccessMessageIconPosition: (draft, position) => {
      draft.ui.success_message.icon.position = position
    },
    setSuccessMessageIconType: (draft, type) => {
      draft.ui.success_message.icon.type = type
    },

    // Success Message Icon Border Actions
    enableSuccessMessageIconBorder: (draft, enable) => {
      draft.ui.success_message.icon.enable_border = enable
    },
    setSuccessMessageIconBorderColor: (draft, color) => {
      draft.ui.success_message.icon.border.color = color
    },
    setSuccessMessageIconBorderWidth: (draft, width) => {
      draft.ui.success_message.icon.border.width = width
    },
    setSuccessMessageIconBorderRadius: (draft, radius) => {
      draft.ui.success_message.icon.border.radius = radius
    },
    setSuccessMessageIconBorderStyle: (draft, style) => {
      draft.ui.success_message.icon.border.style = style
    },
    setSuccessMessageIconMargin: (draft, dir, value) => {
      draft.ui.success_message.icon.margin[dir] = value
    },

    // Survey Request Actions
    enableSurveyRequest: (draft, enable) => {
      draft.ui.enable_survey_request_latest = enable
    },
    setSurveyRequestWidth: (draft, width) => {
      draft.ui.survey_request.width = width
    },
    setSurveyRequestIsFullscreen: (draft, enable) => {
      draft.ui.survey_request.is_fullscreen = enable
    },
    setSurveyRequestPosition: (draft, position) => {
      draft.ui.survey_request.position.position = position
    },
    setSurveyScreenRestrictionType: (draft, type) => {
      draft.ui.restriction_type = type
      draft.ui.white_list_screens = []
      draft.ui.black_list_screens = []
    },
    setSurveyWhiteListScreen: (draft, screens) => {
      draft.ui.white_list_screens = screens
    },
    setSurveyBlackListScreen: (draft, screens) => {
      draft.ui.black_list_screens = screens
    },
    setDefaultSurveyRequest: (draft) => {
      draft.ui.survey_request = SURVEY_REQUEST.survey_request
    },
    setSurveyRequestBackGround: (draft, color) => {
      draft.ui.survey_request.background_color = color
    },
    setSurveyRequestMargin: (draft, dir, value) => {
      draft.ui.survey_request.margin[dir] = value
    },
    enableSurveyRequestBorder: (draft, enable) => {
      draft.ui.survey_request.enable_border = enable
    },
    setSurveyRequestBorderColor: (draft, color) => {
      draft.ui.survey_request.border.color = color
    },
    setSurveyRequestBorderWidth: (draft, width) => {
      draft.ui.survey_request.border.width = width
    },
    setSurveyRequestBorderRadius: (draft, radius) => {
      draft.ui.survey_request.border.radius = radius
    },
    setSurveyRequestBorderStyle: (draft, style) => {
      draft.ui.survey_request.border.style = style
    },
    // SurveyRequest Close Button Actions
    enableSurveyRequestCloseButton: (draft, enable) => {
      draft.ui.survey_request.enable_close_button = enable
    },
    setSurveyRequestCloseButtonPath: (draft, path) => {
      draft.ui.survey_request.close_button.path = path
    },
    setSurveyRequestCloseButtonColor: (draft, color) => {
      draft.ui.survey_request.close_button.color = color
    },
    setSurveyRequestCloseButtonFillColor: (draft, color) => {
      draft.ui.survey_request.close_button.fill_color = color
    },
    setSurveyRequestCloseButtonType: (draft, type) => {
      draft.ui.survey_request.close_button.type = type
    },
    setSurveyRequestCloseButtonShape: (draft, shape) => {
      draft.ui.survey_request.close_button.shape = shape
    },
    setSurveyRequestCloseButtonHeight: (draft, height) => {
      draft.ui.survey_request.close_button.height = height
    },
    setSurveyRequestCloseButtonWidth: (draft, width) => {
      draft.ui.survey_request.close_button.width = width
    },
    setSurveyRequestCloseButtonPosition: (draft, position) => {
      draft.ui.survey_request.close_button.position.position = position
    },
    setSurveyRequestCloseButtonPositionOffsetX: (draft, x) => {
      draft.ui.survey_request.close_button.position.offset.x = x
    },
    setSurveyRequestCloseButtonPositionOffsetY: (draft, y) => {
      draft.ui.survey_request.close_button.position.offset.y = y
    },
    setSurveyRequestCloseButtonMargin: (draft, dir, value) => {
      draft.ui.survey_request.close_button.margin[dir] = value
    },

    // Survey Request Title Actions
    enableSurveyRequestTitle: (draft, enable) => {
      draft.ui.survey_request.enable_title = enable
    },
    setSurveyRequestTitleText: (draft, text) => {
      draft.ui.survey_request.title.text = text
    },
    setSurveyRequestTitleColor: (draft, color) => {
      draft.ui.survey_request.title.color = color
    },
    setSurveyRequestTitleBackgroundColor: (draft, color) => {
      draft.ui.survey_request.title.background_color = color
    },
    setSurveyRequestTitleMaxCharacters: (draft, max) => {
      draft.ui.survey_request.title.max_characters = max
    },
    setSurveyRequestTitleAlignment: (draft, alignment) => {
      draft.ui.survey_request.title.alignment = alignment
    },
    setSurveyRequestTitleFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey_request.title.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey_request.title.font.source = config.source
        draft.ui.survey_request.title.font.url = config.url
      }

      draft.ui.survey_request.title.font.family = config.family
    },
    setSurveyRequestTitleFontSize: (draft, size) => {
      draft.ui.survey_request.title.font.size = size
    },
    setSurveyRequestTitleFontWeight: (draft, weight) => {
      draft.ui.survey_request.title.font.weight = weight
    },
    setSurveyRequestTitleFontStyle: (draft, style) => {
      draft.ui.survey_request.title.font.style = style
    },
    setSurveyRequestTitlePadding: (draft, dir, value) => {
      draft.ui.survey_request.title.padding[dir] = value
    },
    setSurveyRequestTitleMargin: (draft, dir, value) => {
      draft.ui.survey_request.title.margin[dir] = value
    },

    // Survey Request Description Actions
    enableSurveyRequestDescription: (draft, enable) => {
      draft.ui.survey_request.enable_description = enable
    },
    setSurveyRequestDescriptionText: (draft, text) => {
      draft.ui.survey_request.description.text = text
    },
    setSurveyRequestDescriptionColor: (draft, color) => {
      draft.ui.survey_request.description.color = color
    },
    setSurveyRequestDescriptionBackgroundColor: (draft, color) => {
      draft.ui.survey_request.description.background_color = color
    },
    setSurveyRequestDescriptionMaxCharacters: (draft, max) => {
      draft.ui.survey_request.description.max_characters = max
    },
    setSurveyRequestDescriptionAlignment: (draft, alignment) => {
      draft.ui.survey_request.description.alignment = alignment
    },
    setSurveyRequestDescriptionFontFamily: (draft, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey_request.description.font.type = config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey_request.description.font.source = config.source
        draft.ui.survey_request.description.font.url = config.url
      }

      draft.ui.survey_request.description.font.family = config.family
    },
    setSurveyRequestDescriptionFontSize: (draft, size) => {
      draft.ui.survey_request.description.font.size = size
    },
    setSurveyRequestDescriptionFontWeight: (draft, weight) => {
      draft.ui.survey_request.description.font.weight = weight
    },
    setSurveyRequestDescriptionFontStyle: (draft, style) => {
      draft.ui.survey_request.description.font.style = style
    },
    setSurveyRequestDescriptionPadding: (draft, dir, value) => {
      draft.ui.survey_request.description.padding[dir] = value
    },
    setSurveyRequestDescriptionMargin: (draft, dir, value) => {
      draft.ui.survey_request.description.margin[dir] = value
    },

    // Survey Request Icon Actions
    enableSurveyRequestIcon: (draft, enable) => {
      draft.ui.survey_request.enable_icon = enable
    },
    setSurveyRequestIconPath: (draft, path) => {
      draft.ui.survey_request.icon.path = path
    },
    setSurveyRequestIconDefaultPath: (draft) => {
      draft.ui.survey_request.icon.path = DEFAULT_SURVEY_REQUEST_IMAGE.path
    },
    setSurveyRequestIconHeight: (draft, height) => {
      draft.ui.survey_request.icon.height = height
      draft.ui.survey_request.icon.width =
        height === 0 && draft.ui.survey_request.icon.aspect_ratio.width !== -1
          ? 0
          : draft.ui.survey_request.icon.aspect_ratio.width !== -1
            ? (height * draft.ui.survey_request.icon.aspect_ratio.width) /
              draft.ui.survey_request.icon.aspect_ratio.height
            : draft.ui.survey_request.icon.width
    },
    setSurveyRequestIconWidth: (draft, width) => {
      draft.ui.survey_request.icon.width = width
      draft.ui.survey_request.icon.height =
        width === 0 && draft.ui.survey_request.icon.aspect_ratio.width !== -1
          ? 0
          : draft.ui.survey_request.icon.aspect_ratio.width !== -1
            ? (width * draft.ui.survey_request.icon.aspect_ratio.height) /
              draft.ui.survey_request.icon.aspect_ratio.width
            : draft.ui.survey_request.icon.height
    },
    setSurveyRequestIconAspectRatio: (draft, width, height) => {
      draft.ui.survey_request.icon.aspect_ratio = {
        width,
        height,
      }
      draft.ui.survey_request.icon.height =
        draft.ui.survey_request.icon.width === 0 || width === 0
          ? 0
          : (draft.ui.survey_request.icon.width * height) / width
    },
    setSurveyRequestIconPosition: (draft, position) => {
      draft.ui.survey_request.icon.position = position
    },
    setSurveyRequestIconType: (draft, type) => {
      draft.ui.survey_request.icon.type = type
    },
    setSurveyRequestIconMargin: (draft, dir, value) => {
      draft.ui.survey_request.icon.margin[dir] = value
    },
    // Survey Request Icon Border Actions
    enableSurveyRequestIconBorder: (draft, enable) => {
      draft.ui.survey_request.icon.enable_border = enable
    },
    setSurveyRequestIconBorderColor: (draft, color) => {
      draft.ui.survey_request.icon.border.color = color
    },
    setSurveyRequestIconBorderWidth: (draft, width) => {
      draft.ui.survey_request.icon.border.width = width
    },
    setSurveyRequestIconBorderRadius: (draft, radius) => {
      draft.ui.survey_request.icon.border.radius = radius
    },
    setSurveyRequestIconBorderStyle: (draft, style) => {
      draft.ui.survey_request.icon.border.style = style
    },

    // Survey Request Buttons direction
    setSurveyRequestButtonDirection: (draft, direction) => {
      if (
        draft.ui.survey_request.buttons_container.buttons_direction !==
        direction
      ) {
        draft.ui.survey_request.buttons_container.buttons.reverse()
      }
      draft.ui.survey_request.buttons_container.buttons_direction = direction
    },
    // Survey Request Buttons position
    setSurveyRequestButtonContainerPosition: (draft, position) => {
      draft.ui.survey_request.buttons_container.buttons_position = position
    },
    setSurveyRequestButtonContainerMargin: (draft, dir, value) => {
      draft.ui.survey_request.buttons_container.margin[dir] = value
    },

    // Survey Request Buttons' Actions
    addButtonToSurveyRequest: (draft) => {
      draft.ui.survey_request.buttons_container.buttons.push(BUTTON)
    },
    removeButtonFromSurveyRequest: (draft, idx) => {
      draft.ui.survey_request.buttons_container.buttons.splice(idx, 1)
    },
    setSurveyRequestButtonColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[idx].color = color
    },
    setSurveyRequestButtonFillColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[idx].fill_color = color
    },
    setSurveyRequestButtonActiveColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[idx].active_color =
        color
    },
    setSurveyRequestButtonInactiveColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[idx].inactive_color =
        color
    },
    setSurveyRequestButtonType: (draft, idx, type) => {
      draft.ui.survey_request.buttons_container.buttons[idx].type = type
    },
    setSurveyRequestButtonPosition: (draft, idx, position) => {
      draft.ui.survey_request.buttons_container.buttons[idx].position.position =
        position
    },
    setSurveyRequestButtonMargin: (draft, idx, dir, value) => {
      draft.ui.survey_request.buttons_container.buttons[idx].margin[dir] = value
    },
    setSurveyRequestButtonPadding: (draft, idx, dir, value) => {
      draft.ui.survey_request.buttons_container.buttons[idx].padding[dir] =
        value
    },
    enableSurveyRequestButtonBorder: (draft, idx, enable) => {
      draft.ui.survey_request.buttons_container.buttons[idx].enable_border =
        enable
    },
    setSurveyRequestButtonBorderColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[idx].border.color =
        color
    },
    setSurveyRequestButtonBorderWidth: (draft, idx, width) => {
      draft.ui.survey_request.buttons_container.buttons[idx].border.width =
        width
    },
    setSurveyRequestButtonBorderRadius: (draft, idx, radius) => {
      draft.ui.survey_request.buttons_container.buttons[idx].border.radius =
        radius
    },
    setSurveyRequestButtonBorderStyle: (draft, idx, style) => {
      draft.ui.survey_request.buttons_container.buttons[idx].border.style =
        style
    },

    // Survey Request Buttons' Text Actions
    setSurveyRequestButtonText: (draft, idx, text) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.text = text
    },
    setSurveyRequestButtonTextColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.color = color
    },
    setSurveyRequestButtonTextBackgroundColor: (draft, idx, color) => {
      draft.ui.survey_request.buttons_container.buttons[
        idx
      ].text.background_color = color
    },
    setSurveyRequestButtonTextMaxCharacters: (draft, idx, max) => {
      draft.ui.survey_request.buttons_container.buttons[
        idx
      ].text.max_characters = max
    },
    setSurveyRequestButtonTextAlignment: (draft, idx, alignment) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.alignment =
        alignment
    },
    setSurveyRequestButtonTextFontFamily: (draft, idx, config) => {
      // Web and Omni
      if ([APP_PLATFORMS.web, APP_PLATFORMS.omni].includes(config.platform)) {
        draft.ui.survey_request.buttons_container.buttons[idx].text.font.type =
          config.kind
      }

      // Android and Omni
      if (
        [APP_PLATFORMS.android, APP_PLATFORMS.omni].includes(config.platform)
      ) {
        draft.ui.survey_request.buttons_container.buttons[
          idx
        ].text.font.source = config.source
        draft.ui.survey_request.buttons_container.buttons[idx].text.font.url =
          config.url
      }

      draft.ui.survey_request.buttons_container.buttons[idx].text.font.family =
        config.family
    },
    setSurveyRequestButtonTextFontSize: (draft, idx, size) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.font.size =
        size
    },
    setSurveyRequestButtonTextFontWeight: (draft, idx, weight) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.font.weight =
        weight
    },
    setSurveyRequestButtonTextFontStyle: (draft, idx, style) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.font.style =
        style
    },
    setSurveyRequestButtonTextPadding: (draft, idx, dir, value) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.padding[dir] =
        value
    },
    setSurveyRequestButtonTextMargin: (draft, idx, dir, value) => {
      draft.ui.survey_request.buttons_container.buttons[idx].text.margin[dir] =
        value
    },

    // Survey Request Buttons' Action Actions
    setSurveyRequestButtonActionType: (draft, idx, type) => {
      draft.ui.survey_request.buttons_container.buttons[idx].action.type = type
    },
    setSurveyRequestButtonActionUrl: (draft, idx, url) => {
      draft.ui.survey_request.buttons_container.buttons[idx].action.url = url
    },
    setSurveyRequestButtonActionAsExternal: (draft, idx, isExternal) => {
      draft.ui.survey_request.buttons_container.buttons[
        idx
      ].action.is_external = isExternal
    },
    setSurveyRequestButtonActionAsWithin: (draft, idx, isWithin) => {
      draft.ui.survey_request.buttons_container.buttons[idx].action.is_within =
        isWithin
    },
    setSurveyRequestButtonActionAsIr: (draft, idx, isIr) => {
      draft.ui.survey_request.buttons_container.buttons[idx].action.is_ir = isIr
    },

    // Survey response based Actions
    addPath: (draft, paths) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.paths = paths
    },
    addPathV2: (draft, paths) => {
      draft.ui.survey.q_and_a[draft.currentStep].question.paths_v2 = paths
    },
  })
  .build()

export const { useActions, useComputed } = SurveysModule

const useStateValues = createTrackedSelector(SurveysModule.useState)

export const useSelector = () => {
  const state = useStateValues()
  return { ...state }
}
