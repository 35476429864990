import { makeStyles } from "@material-ui/core"
import { APP_PLATFORMS } from "../../../../../../../../constants/index"
import {
  ANDROID_DEFAULT_FONTS,
  IOS_DEFAULT_FONTS,
  OLD_IOS_DEFAULT_FONTS,
  WEB_DEFAULT_FONTS,
} from "../../../../../../common/components/campaigncreator/constants"
import { CUSTOM_FONT_SOURCES } from "../../../../../themes/common/constants"
import { BTN_ACTION_ENUM, INAPP_LAYOUT_TYPES } from "../../constants"
import { IMAGE_ONLY_REDIRECTION_CONFIG } from "../../templates/common"
import { DEFAULT_IMAGE_DATA } from "../simulator/utils"

let scriptCounter = 0
let scriptMap = {}

const generateFontFaces = (
  fontFamily,
  fontSource,
  platform = APP_PLATFORMS.android,
) => {
  if (
    ![
      ...IOS_DEFAULT_FONTS,
      ...OLD_IOS_DEFAULT_FONTS,
      ...ANDROID_DEFAULT_FONTS,
      ...WEB_DEFAULT_FONTS,
    ]
      .map((font) => font.value)
      .includes(fontFamily)
  ) {
    if (platform === APP_PLATFORMS.android) {
      switch (fontSource) {
        case CUSTOM_FONT_SOURCES.ASSETS:
          return `@font-face {
        font-family: '${fontFamily}';
        src: url("file:///android_asset/${fontFamily}");
      }`
        case CUSTOM_FONT_SOURCES.RESOURCES:
          return `@font-face {
        font-family: '${fontFamily}';
        src: url("file:///android_res/${fontFamily}");
      }`
        case CUSTOM_FONT_SOURCES.PATH: {
          return `@font-face {
        font-family: '${fontFamily}';
        src: url("${fontFamily}");
      }`
        }
        default:
          return ""
      }
    } else if (platform === APP_PLATFORMS.ios) {
      return `@font-face {
      font-family: '${fontFamily}';
      src: url("${fontFamily}");
    }`
    }
  } else return ""
}

const HEADER_STYLES = (ui, platform) => {
  const {
    window_attributes: { dimmed_background = false, dim_amount = 0.8 },
    title_config: {
      font_family: title_font_family,
      font_source: title_font_source = "",
    },
    text_config: {
      font_family: text_font_family,
      font_source: text_font_source = "",
    },
    buttons_config,
  } = ui

  const buttonTextConfigs = buttons_config.map((button) => button.text_config)

  const fontFaceOfTitle = generateFontFaces(
    title_font_family,
    title_font_source,
    platform,
  )
  const fontFaceOfText = generateFontFaces(
    text_font_family,
    text_font_source,
    platform,
  )
  const fontFacesOfButtons = buttonTextConfigs.map((textConfig) =>
    generateFontFaces(textConfig.font_family, textConfig.font_source, platform),
  )

  const finalFontFaces = Array.from(
    new Set([fontFaceOfTitle, fontFaceOfText, ...fontFacesOfButtons]),
  )

  return (
    /* css */ `
    body {
      background: ${
        dimmed_background ? "rgba(51,51,51," + dim_amount + ")" : "transparent"
      };
    }
    .h {
      position: relative;
      bottom: 100%;
      width: 100%;
    }
    .h-anim {
      -webkit-animation: h-slide 0.5s forwards;
      animation: h-slide 0.5s forwards;
    }
    @-webkit-keyframes h-slide {
      100% {bottom: 0;}
    }
    @keyframes h-slide {
      100% {bottom: 0;}
    }` +
    "\n" +
    finalFontFaces.join("\n")
  )
}

const FOOTER_STYLES = (ui, platform) => {
  const {
    window_attributes: { dimmed_background = false, dim_amount = 0.8 },
    title_config: {
      font_family: title_font_family,
      font_source: title_font_source = "",
    },
    text_config: {
      font_family: text_font_family,
      font_source: text_font_source = "",
    },
    buttons_config,
  } = ui

  const fontFaceOfTitle = generateFontFaces(
    title_font_family,
    title_font_source,
    platform,
  )
  const fontFaceOfText = generateFontFaces(
    text_font_family,
    text_font_source,
    platform,
  )
  const fontFacesOfButtons = buttons_config.map((button) =>
    generateFontFaces(
      button.text_config.font_family,
      button.text_config.font_source,
      platform,
    ),
  )

  const finalFontFaces = Array.from(
    new Set([fontFaceOfTitle, fontFaceOfText, ...fontFacesOfButtons]),
  )

  return (
    /* css */ `body {
        background: ${
          dimmed_background
            ? "rgba(51,51,51," + dim_amount + ")"
            : "transparent"
        };
      }
      .f {
        position: relative;
        top: 100%;
        width: 100%;
      }
      .f-anim {
        -webkit-animation: f-slide 0.5s forwards;
        animation: f-slide 0.5s forwards;
      }
      @-webkit-keyframes f-slide {
        100% {top: 0;}
      }
      @keyframes f-slide {
        100% {top: 0;}
      }` +
    "\n" +
    finalFontFaces.join("\n")
  )
}

const GENERAL_INAPP_STYLES = (ui, platform) => {
  const {
    title_config: {
      font_family: title_font_family,
      font_source: title_font_source = "",
    },
    text_config: {
      font_family: text_font_family,
      font_source: text_font_source = "",
    },
    buttons_config,
  } = ui

  const fontFaceOfTitle = generateFontFaces(
    title_font_family,
    title_font_source,
    platform,
  )
  const fontFaceOfText = generateFontFaces(
    text_font_family,
    text_font_source,
    platform,
  )
  const fontFacesOfButtons = buttons_config.map((button) =>
    generateFontFaces(
      button.text_config.font_family,
      button.text_config.font_source,
      platform,
    ),
  )

  const finalFontFaces = Array.from(
    new Set([fontFaceOfTitle, fontFaceOfText, ...fontFacesOfButtons]),
  )

  return finalFontFaces.join("\n")
}

const HEADER_SCRIPT = `
<script>
  function animate() {
    var e = document.getElementById("x");
    e.style.visibility = "hidden";
    setTimeout(() => {
      e.style.visibility = "visible";
      e.parentElement.style.height = e.getBoundingClientRect().height + "px";
      e.classList.add("h-anim");
    }, 20);
  }
  window.onload = animate;
</script>
`

const FOOTER_SCRIPT = `
<script>
  function animate() {
    var e = document.getElementById("x");
    e.style.visibility = "hidden";
    setTimeout(() => {
      e.style.visibility = "visible";
      e.parentElement.style.height = e.getBoundingClientRect().height + "px";
      e.classList.add("f-anim");
    }, 320);
  }
  window.onload = animate;
</script>
`

/**
 * Constructs the HTML based on layout type and returns the constructed HTML back
 *
 * @param new_ui
 * @param platform
 * @returns {*}
 */
export const constructHTML = (
  new_ui,
  platform = APP_PLATFORMS.android,
  forDashboard = false,
) => {
  scriptCounter = 0
  scriptMap = {}

  const { layout_type } = new_ui
  let html_data = new_ui?.html_config?.data ?? ""

  // eslint-disable-next-line default-case
  switch (layout_type) {
    case INAPP_LAYOUT_TYPES.HEADER:
      html_data = WITH_HTML_HOLDER(
        HEADER_OR_FOOTER_CONTENT(new_ui, false, platform, forDashboard),
        HEADER_STYLES(new_ui, platform),
        HEADER_SCRIPT,
        forDashboard,
      )
      break
    case INAPP_LAYOUT_TYPES.FOOTER:
      html_data = WITH_HTML_HOLDER(
        HEADER_OR_FOOTER_CONTENT(new_ui, true, platform, forDashboard),
        FOOTER_STYLES(new_ui, platform),
        FOOTER_SCRIPT,
        forDashboard,
      )
      break
    case INAPP_LAYOUT_TYPES.POPOUT_MODAL:
      html_data = WITH_HTML_HOLDER(
        POPOUT_MODAL_CONTENT(new_ui, platform, forDashboard),
        GENERAL_INAPP_STYLES(new_ui, platform),
        "",
        forDashboard,
      )
      break
    case INAPP_LAYOUT_TYPES.FULL_SCREEN_MODAL:
      html_data = WITH_HTML_HOLDER(
        FULL_SCREEN_MODAL_CONTENT(new_ui, platform, forDashboard),
        GENERAL_INAPP_STYLES(new_ui, platform),
        "",
        forDashboard,
      )
      break
    case INAPP_LAYOUT_TYPES.CAROUSEL:
      html_data = WITH_HTML_HOLDER(
        CAROUSEL_CONTENT(new_ui, platform, forDashboard),
        GENERAL_INAPP_STYLES(new_ui, platform),
        "",
        forDashboard,
      )
      break
  }

  if (scriptCounter > 0) {
    new_ui.html_config.has_scripts = true
    let data = {}
    Object.keys(scriptMap).forEach((scriptName) => {
      const script = scriptMap[scriptName]
      data[scriptName] = script.cmds
      data.vmap = {
        ...(data.vmap || {}),
        ...script.vmap,
      }
    })
    new_ui.html_config.script = {
      ...new_ui.html_config.script,
      keys: Object.keys(scriptMap),
      ...data,
    }
  }

  return html_data
}

const WITH_HTML_HOLDER = (content, styles = "", script = "", forDashboard) => {
  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <style>
          *{
            box-sizing: border-box;
          }
          ${styles}
        </style>
        ${script}
      </head>
      <body style="margin: 0; padding: 0; position:relative; min-height:100vh">
        ${content}
      </body>
    </html>
    `.replace(/[\s]{2,100}/g, "")
}

/**
 * Constructs the HTML layout for buttons
 *
 * @param config
 * @param only_image
 * @param style
 * @returns {string}
 *          HTML String if `only_image` flag is FALSE
 *          Otherwise, returns EMPTY string
 * @constructor
 */
const WITH_BUTTONS = (config, only_image = false, style = "") => {
  if (config === undefined || config === null || only_image) {
    return ``
  }

  let buttonsHtml = `<div style="display: flex; flex-direction: row; justify-content: flex-end; padding: 4px;">`

  config.forEach((button, index) => {
    if (isJson(button)) {
      buttonsHtml += `<a role="button" href="${getHrefFromActionConfig(
        button,
      )}" style="display: flex; align-items: center; justify-content: center;text-decoration: none; background: ${
        button.gradient_config
          ? `linear-gradient(${button.gradient_config.colors
              .map((each) => hexToRgb(each))
              .join(",")})`
          : hexToRgb(button.color)
      }; ${translateToStyles(
        button.text_config,
      )}; padding: 14px; width: 100%; margin-right: ${
        index + 1 === config.length ? 0 : "8px"
      }; text-align: center; border-radius: ${
        button.borders.radius || 4
      }px; ${style} line-height: normal !important;">${
        button.text_config.text
      }</a>`
    }
  })

  buttonsHtml += `</div>`

  return buttonsHtml
}

const HEADER_OR_FOOTER_CONTENT = (
  new_ui,
  isBottom = false,
  platform,
  forDashboard,
) => {
  const {
    action_config,
    only_image,
    bg_image,
    image_size,
    border_radius,
    size = 33,
  } = new_ui

  let redirectUrl = "apxor://close"
  if (action_config && action_config.action) {
    if (action_config.action === "redirect") {
      if (action_config.is_deep_link && action_config.deep_link) {
        redirectUrl = `apxor://dl_url?uri=${action_config.deep_link.uri}&action=android.intent.action.VIEW&buttonName=Image`
      } else {
        redirectUrl = `apxor://dl?activity=${action_config.activity}&buttonName=Image`
      }
    } else if (action_config.action === BTN_ACTION_ENUM.NEVER_SHOW) {
      redirectUrl = "apxor://never?buttonName=Image"
    }
  }

  function getPosition(isFooter, dashboard) {
    if (dashboard) return isFooter ? "absolute" : "none"
    else return "fixed"
  }

  if (only_image) {
    return `
    <div style="overflow: hidden; display: flex; justify-content: flex-start; flex-direction: column; position: ${
      platform === APP_PLATFORMS.android ? "relative" : "absolute"
    }; width: 100%;">
      <div style="width: 100%; height: 100%; background-position: center; background-repeat: no-repeat; background-image: url(${bg_image}); background-size: ${
        image_size === "Stretch" ? "100% 100%" : image_size.toLowerCase()
      }; border-radius: ${!isBottom ? "0px" : border_radius + "px"} ${
        !isBottom ? "0px" : border_radius + "px"
      } ${isBottom ? "0px" : border_radius + "px"} ${
        isBottom ? "0px" : border_radius + "px"
      }" onclick="window.open('${redirectUrl}')">
        </div>
    </div>
    `
  }

  const { title_config, text_config, buttons_config, bg_color, image } = new_ui

  const data = `
  <div id="x" class="${
    isBottom ? "f" : "h"
  }" style="overflow: hidden; display: flex; flex-direction: column;">
    <div style="width: 100%; height: 100%; background-color: ${hexToRgb(
      bg_color,
    )}; border-radius: ${!isBottom ? "0px" : border_radius + "px"} ${
      !isBottom ? "0px" : border_radius + "px"
    } ${isBottom ? "0px" : border_radius + "px"} ${
      isBottom ? "0px" : border_radius + "px"
    }">
      <div style="justify-content: start; margin: -4px; display: inline-flex; flex-wrap: wrap; align-items: center; gap: 10px;">
        ${getImage(image, size)}
        <div style="flex: 1; padding: 4px; max-width: 100%; word-wrap: break-word;overflow-wrap: break-word; word-break: break-all;">
          ${getTextContent(title_config, false)}
          ${getTextContent(text_config, false)}
        </div>
      </div>
      <div style="margin-top: 8px; margin-bottom: 8px;">
        ${WITH_BUTTONS(buttons_config, false)}
      </div>
    </div>
  </div>
  `

  return `
  <div style="position: ${getPosition(isBottom, forDashboard)}; ${
    isBottom ? "bottom: 0;" : "top: 0;"
  } overflow: hidden; width: 100%;">
    ${data}
  </div>
  `
}

const POPOUT_MODAL_CONTENT = (new_ui, platform) => {
  const { border_radius = 0, orientation } = new_ui

  if (new_ui?.only_image) {
    const {
      image_only_redirection_config = {
        ...IMAGE_ONLY_REDIRECTION_CONFIG,
        __id: "blnpy3XYx-Nr1JPARXG",
      },
      image_size,
      bg_image,
    } = new_ui

    const {
      is_deep_link = false,
      deep_link,
      activity,
      is_ir = false,
      internal_redirection = [],
    } = image_only_redirection_config

    let redirectUrl = "apxor://xclose"
    if (image_only_redirection_config?.action === "redirect") {
      if (is_deep_link && deep_link) {
        redirectUrl = `apxor://dl_url?uri=${deep_link?.uri}&action=android.intent.action.VIEW`
      } else if (is_ir && internal_redirection?.length > 0) {
        redirectUrl = `apxor://ir?`
        internal_redirection.forEach((key) => {
          redirectUrl += `key_value=${key.name}__${key.value}`
        })
      } else {
        redirectUrl = `apxor://dl?activity=${activity}`
      }
    } else if (
      image_only_redirection_config?.action === BTN_ACTION_ENUM.NEVER_SHOW
    ) {
      redirectUrl = "apxor://never?buttonName=Image"
    }

    return /* html */ `
    <div style="overflow: hidden; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%; position: ${
      platform === APP_PLATFORMS.android ? "relative" : "absolute"
    };">
      <div style="width: 85%; max-height: ${
        orientation === "Portrait" ? "99%" : "95%"
      }; min-height: 50%; position: relative;display: flex; flex-direction: column; border-radius: ${border_radius}px; background-position: center; background-repeat: no-repeat; background-image: url(${
        bg_image === ""
          ? "data:image/png;base64," + DEFAULT_IMAGE_DATA
          : bg_image
      }); background-size: ${
        image_size === "Stretch" ? "100% 100%" : image_size.toLowerCase()
      };" onclick=${
        platform === APP_PLATFORMS.ios
          ? `window.location.href='${redirectUrl}'`
          : `window.open('${redirectUrl}')`
      }>
          <div onclick="${
            platform === APP_PLATFORMS.ios
              ? `window.location.href='apxor://xclose'; event.stopPropagation();`
              : `window.open('apxor://xclose'); event.stopPropagation();`
          }" style="top: -8px; right: -8px; position: absolute; width: 16px; height: 16px; z-index: 999;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="12" cy="12" r="11" fill="white" stroke="black" stroke-width="2"></circle>
  <line x1="18" y1="6" x2="6" y2="18"></line>
  <line x1="6" y1="6" x2="18" y2="18"></line>
</svg>
</div>
      </div>
    </div>
    `
  }

  const { image, title_config, text_config, buttons_config, bg_color } = new_ui

  return /* html */ `
    <div style="overflow: hidden; display: flex; flex-direction: column; justify-content: center; align-items: center; width: 100%; height: 100%; position: ${
      platform === APP_PLATFORMS.android ? "relative" : "absolute"
    };">
      <div style="width: 85%; max-height: ${
        orientation === "Portrait" ? "99%" : "95%"
      }; display: flex; flex-direction: column; position: relative; border-radius: ${border_radius}px; background-color: ${hexToRgb(
        bg_color,
      )};">
  <div onclick="${
    platform === APP_PLATFORMS.ios
      ? `window.location.href='apxor://xclose'; event.stopPropagation();`
      : `window.open('apxor://xclose'); event.stopPropagation();`
  }" style="top: -8px; right: -8px; position: absolute; width: 16px; height: 16px; z-index: 999;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="12" cy="12" r="11" fill="white" stroke="black" stroke-width="2"></circle>
  <line x1="18" y1="6" x2="6" y2="18"></line>
  <line x1="6" y1="6" x2="18" y2="18"></line>
</svg>
</div>

        <div style="width: 100%; margin-top: 15px; justify-content: center; display: flex; flex-wrap: wrap; min-height: 57%;">
          <div style="padding: 4px; margin: 0; width: 90%;">
            <div style="min-width: 64px; min-height: 64px; padding: 0; height: 100%;">
                <img alt="icon" src="${
                  image === ""
                    ? "data:image/png;base64," + DEFAULT_IMAGE_DATA
                    : image
                }" style="width: 100%; height: 100%;"/>
            </div>
          </div>
        </div>
        <div style="width: 100%; justify-content: center; display: flex; flex-wrap: wrap; max-width: 100%;word-wrap: break-word; overflow-wrap: break-word; word-break: break-all;">
          <div style="flex-grow: 1; max-width: 100%; flex-basis: 0; align-self: center;">
            ${getTextContent(title_config, false, "text-align: center; ")}
            ${getTextContent(
              text_config,
              false,
              "text-align: center; padding-top: 0px;",
            )}
          </div>
        </div>
        <div style="width: 100%; display: flex; flex-wrap: wrap;">
          <div style="display: flex; flex-direction: row; justify-content: flex-end; padding: 4px; width: 100%;">
            <a role="button" href="${getHrefFromActionConfig(
              buttons_config[0],
            )}" style="text-decoration: none; background: ${
              buttons_config[0].gradient_config
                ? `linear-gradient(${buttons_config[0].gradient_config.colors
                    .map((each) => hexToRgb(each))
                    .join(",")})`
                : hexToRgb(buttons_config[0].color)
            };${translateToStyles(
              buttons_config[0].text_config,
            )}; padding: 14px; border: 1px solid transparent; line-height: 0; width: 100%; text-align: center; border-radius: ${
              buttons_config[0].borders.radius || 4
            }px; margin: 8px;">${buttons_config[0].text_config?.text?.replaceAll(
              "#",
              "%23",
            )}</a>
  ${
    buttons_config.length > 1
      ? `
  <a role="button" href="${getHrefFromActionConfig(
    buttons_config[1],
  )}" style="text-decoration: none; background: ${
    buttons_config[1].gradient_config
      ? `linear-gradient(${buttons_config[1].gradient_config.colors.join(",")})`
      : hexToRgb(buttons_config[1].color)
  }; ${translateToStyles(
    buttons_config[1].text_config,
  )}; padding: 14px; border: 1px solid transparent; line-height: 0; width: 100%; text-align: center; border-radius: ${
    buttons_config[1].borders.radius || 4
  }px; margin: 8px;">${buttons_config[1].text_config?.text?.replaceAll(
    "#",
    "%23",
  )}</a>`
      : ``
  }
        </div>
      </div>
    </div>
  </div>
  `
}

const FULL_SCREEN_MODAL_CONTENT = (new_ui, platform) => {
  if (new_ui?.only_image) {
    const { image_only_redirection_config, bg_image, image_size } = new_ui

    const {
      is_deep_link,
      deep_link,
      activity,
      is_ir = false,
      internal_redirection = [],
    } = image_only_redirection_config

    let redirectUrl = "apxor://xclose"
    if (image_only_redirection_config?.action) {
      if (image_only_redirection_config?.action === "redirect") {
        if (is_deep_link && deep_link) {
          redirectUrl = `apxor://dl_url?uri=${deep_link?.uri}&action=android.intent.action.VIEW`
        } else if (is_ir && internal_redirection.length > 0) {
          redirectUrl = `apxor://ir?`
          internal_redirection.forEach((key) => {
            redirectUrl += `key_value=${key.name}__${key.value}`
          })
        } else {
          redirectUrl = `apxor://dl?activity=${activity}`
        }
      } else if (
        image_only_redirection_config?.action === BTN_ACTION_ENUM.NEVER_SHOW
      ) {
        redirectUrl = "apxor://never?buttonName=Image"
      }
    }

    return /* html */ `
    <div style="overflow: hidden; width: 100%; height: 100%; position: ${
      platform === APP_PLATFORMS.android ? "relative" : "absolute"
    }; background-position: center; background-repeat: no-repeat; background-image: url(${
      bg_image === "" ? "data:image/png;base64," + DEFAULT_IMAGE_DATA : bg_image
    }); background-size: ${
      image_size === "Stretch" ? "100% 100%" : image_size.toLowerCase()
    };" onclick=${
      platform === APP_PLATFORMS.ios
        ? `window.location.href='${redirectUrl}'`
        : `window.open('${redirectUrl}')`
    }>
      <div onclick="${
        platform === APP_PLATFORMS.ios
          ? `window.location.href='apxor://xclose'; event.stopPropagation();`
          : `window.open('apxor://xclose'); event.stopPropagation();`
      }" style="top: 0px; right: 0px; position: absolute; width: 16px; height: 16px; z-index: 999;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="12" cy="12" r="11" fill="white" stroke="black" stroke-width="2"></circle>
  <line x1="18" y1="6" x2="6" y2="18"></line>
  <line x1="6" y1="6" x2="18" y2="18"></line>
</svg>
    </div>
    </div>
    `
  }

  return /* html */ `
  <div style="overflow: hidden; width: 100%; height: 100%; position: ${
    platform === APP_PLATFORMS.android ? "relative" : "absolute"
  }; background-color: transparent;">
    <div onclick="${
      platform === APP_PLATFORMS.ios
        ? `window.location.href='apxor://xclose'; event.stopPropagation();`
        : `window.open('apxor://xclose'); event.stopPropagation();`
    }" style="top: 0px; right: 0px; position: absolute; width: 16px; height: 16px; z-index: 999;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="12" cy="12" r="11" fill="white" stroke="black" stroke-width="2"></circle>
  <line x1="18" y1="6" x2="6" y2="18"></line>
  <line x1="6" y1="6" x2="18" y2="18"></line>
</svg>
  </div>
    ${getFullScreenModalBody(new_ui)}
  </div>
  </div>
    `
}

const CAROUSEL_CONTENT = (new_ui, platform) => {
  const {
    carousel: { images = [] } = {},
    border_radius = 0,
    image_size,
    orientation,
  } = new_ui

  let redirectUrl = "apxor://close"

  const getCarouselIndicators = (imageIndex) => {
    const indicatorElements = images.map((eachImage, index) => {
      return `
              <li style="display: inline-block">
              <a href="${eachImage}" style="
                  display: inline-block;
                  width: 1rem;
                  height: 1rem;
                  background-color: ${
                    imageIndex === index ? "#017dfd" : "#a6a6a6"
                  };
                  background-clip: content-box;
                  border: 0.25rem solid transparent;
                  border-radius: 50%;
                  font-size: 0;
                  transition: transform 0.1s;
                "
                ></a>
            </li>`
    })
    return indicatorElements.join("")
  }

  const getImageComponents = () => {
    const imageElements = images.map((eachImage, index) => {
      return /* html */ `
          <div style="width: 100%; max-height: ${
            orientation === "Portrait" ? "99%" : "95%"
          }; min-height: 50%; display: flex; flex-direction: column; border-radius: ${border_radius}px; background-position: center; background-repeat: no-repeat; background-image: url(${
            eachImage || "data:image/png;base64," + DEFAULT_IMAGE_DATA
          }); background-size: ${
            image_size === "Stretch" ? "100% 100%" : image_size.toLowerCase()
          }; scroll-snap-align: start; flex-shrink: 0; transform-origin: center center;   transform: scale(1);
        transition: transform 0.5s;
        position: relative;" onclick=${
          platform === APP_PLATFORMS.ios
            ? `window.location.href='${redirectUrl}'`
            : `window.open('${redirectUrl}')`
        }>
            <div onclick="${
              platform === APP_PLATFORMS.ios
                ? `window.location.href='apxor://xclose'; event.stopPropagation();`
                : `window.open('apxor://xclose'); event.stopPropagation();`
            }" style="top: 0px; right: 0px; position: absolute; width: 16px; height: 16px; z-index: 999;">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="none" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
  <circle cx="12" cy="12" r="11" fill="white" stroke="black" stroke-width="2"></circle>
  <line x1="18" y1="6" x2="6" y2="18"></line>
  <line x1="6" y1="6" x2="18" y2="18"></line>
</svg>
</div>

        <aside style="
            position: absolute;
            z-index: 999;
            text-align: center;
            bottom: 0px;
            left: 0px;
            right: 0px;
          "
        >
          <ol style="display: inline-block; padding: 0px">
            ${getCarouselIndicators(index)}
          </ol>
        </aside>
      </div>
    `
    })
    return imageElements.join("")
  }

  return `
    <div style=" margin:20px; overflow: hidden; display: flex; align-items: center; width: 90%; height: 100%; position: ${
      platform === APP_PLATFORMS.android ? "relative" : "absolute"
    }; overflow-x: auto; scroll-snap-type: x mandatory; scroll-behavior: smooth; -webkit-overflow-scrolling: touch;" >
      ${getImageComponents()}
    </div>
    `
}

const getFullScreenModalBody = (new_ui) => {
  const {
    image,
    title_config,
    buttons_config,
    border_radius = 0,
    image_size,
  } = new_ui

  return `
  <div style="width: 100%; height: 100%; display: flex; flex-wrap: wrap; flex-direction: column; justify-content: center; text-align: center; padding: 12px;">
    <div style="margin: 0px 0px 6px 0px;">
      ${getTextContent(
        title_config,
        false,
        "text-align: center; display: -webkit-box; overflow: hidden; text-overflow: ellipsis; -webkit-line-clamp: 2; -webkit-box-orient: vertical; align-self: center;",
      )}
    </div>
    <div style="margin: 0; min-height: 70%; max-height: 70%; overflow: hidden; border-top-left-radius: ${border_radius}px; border-top-right-radius: ${border_radius}px;">
    <div style="width: 100%; height: 100%; padding: 0; background-image: url(${
      image === "" ? "data:image/png;base64," + DEFAULT_IMAGE_DATA : image
    }); background-size: ${
      image_size === "Stretch" ? "100% 100%" : image_size.toLowerCase()
    }; background-repeat: no-repeat; border: 0px;"></div>
    </div>
    <div style="overflow: hidden; border-bottom-left-radius: ${border_radius}px; border-bottom-right-radius: ${border_radius}px; margin: 0; margin-top: -2px; ">
      <div style="display: flex; flex-direction: row; justify-content: flex-end; padding: 0px; border: 0px;">
        <a role="button" href="${getHrefFromActionConfig(
          buttons_config[0],
        )}" style="text-decoration: none; background: ${
          buttons_config[0].gradient_config
            ? `linear-gradient(${buttons_config[0].gradient_config.colors.join(",")})`
            : hexToRgb(buttons_config[0].color)
        }; ${translateToStyles(
          buttons_config[0].text_config,
        )}; padding: 14px; border: 1px solid transparent; line-height: 0; width: 100%; text-align: center; border-radius: ${
          buttons_config[0].borders.radius || 0
        }px;">${buttons_config[0].text_config.text}</a>
  ${
    buttons_config.length > 1
      ? `<a role="button" href="${getHrefFromActionConfig(
          buttons_config[1],
        )}" style="text-decoration: none; background: ${
          buttons_config[1].gradient_config
            ? `linear-gradient(${buttons_config[1].gradient_config.colors.join(
                ",",
              )})`
            : hexToRgb(buttons_config[1].color)
        }; ${translateToStyles(
          buttons_config[1].text_config,
        )}; padding: 14px; border: 1px solid transparent; line-height: 0; width: 100%; text-align: center; border-radius: ${
          buttons_config[1].borders.radius || 0
        }px; margin-left: 1px;">${buttons_config[1].text_config.text}</a>`
      : ``
  }
    </div>
  </div>
  `
}

const translateToStyles = (config) => {
  const { color, font_family, size, style } = config
  return `color: ${hexToRgb(
    color,
  )}; font-family: '${font_family}'; font-size: ${size}px; font-style: ${
    style !== "bold" ? style : "normal"
  }; font-weight: ${
    style === "bold" ? style : "normal"
  }; margin: 0; padding-top: 5px; line-height: ${Number(size) + 2}px`
}

export function hexToRgb(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  let resultMap
  if (result) {
    resultMap = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    }
    return `rgb(${Object.values(resultMap)})`
  }
  return hex
}

/**
 * Constructs the href string based on the given configuration
 *
 * @param button_config
 * @returns {string}
 */
const getHrefFromActionConfig = (button_config) => {
  const {
    text_config,
    action_config: {
      action,
      activity,
      is_deep_link,
      parameters = [],
      share_options = {},
      deep_link = {},
      is_ir = false,
      internal_redirection = [],
    },
  } = button_config

  let redirectUrl = "apxor://close"

  if (is_ir && internal_redirection.length > 0) {
    redirectUrl = `apxor://ir?buttonName=${text_config.text}`
    internal_redirection.forEach((key) => {
      redirectUrl += `&key_value=${key.name}__${key.value}`
    })

    return redirectUrl
  }

  switch (action) {
    case BTN_ACTION_ENUM.REDIRECT:
      if (is_deep_link && deep_link) {
        redirectUrl = `apxor://dl_url?uri=${deep_link.uri}&action=android.intent.action.VIEW&buttonName=${text_config.text}`
      } else {
        redirectUrl = `apxor://dl?activity=${activity}&buttonName=${text_config.text}`
        if (parameters.length > 0) {
          // Iterate over all configured parameters, and append them to the URL
          // &<PARAM_DATATYPE>___<PARAM_KEY>___<PARAM_VALUE>
          parameters.forEach((param) => {
            redirectUrl +=
              "&" + param.datatype + "__" + param.key + "__" + param.value
          })
        }
      }
      break
    case BTN_ACTION_ENUM.CANCEL:
      redirectUrl = "apxor://cancel?buttonName=" + text_config.text
      break
    case "share":
      if (Object.keys(share_options) > 0) {
        redirectUrl =
          "apxor://share?type=text/plain&action=android.intent.action.SEND&extraText=" +
          share_options["extra_text"] +
          "&title=" +
          share_options["title"] +
          "&package=" +
          share_options["package"]
      } else {
        redirectUrl = "apxor://close?buttonName=" + text_config.text
      }
      break
    case BTN_ACTION_ENUM.NEVER_SHOW:
      redirectUrl = "apxor://never?buttonName=" + text_config.text
      break
    default:
      redirectUrl = "apxor://close?buttonName=" + text_config.text
      break
  }

  return redirectUrl
}

const getImage = (image, size = 33) => {
  if (image === "NA") {
    return ``
  }
  return `
    <img alt="icon" src="${
      image === "" ? "data:image/png;base64," + DEFAULT_IMAGE_DATA : image
    }" style="width: ${size}%; height: auto; display: inline-flex; margin: 10px;"/>
  `
}

const getTextContent = (config, only_image = false, style = "") => {
  if (!isJson(config) || only_image) {
    return ``
  }

  if (config.is_dyn && config.d_type === "s" && config.script) {
    scriptMap["Script" + scriptCounter] = config.script
  }

  return `
  <div style="padding: 4px; ${style};">
    <p style="${translateToStyles(config)};">${
      config?.is_dyn && config?.d_type === "s"
        ? "apx_evaluate(Script" + scriptCounter++ + ")"
        : config.text
    }</p>
  </div>`
}

const isJson = (prop) =>
  typeof prop !== "undefined" && Object.keys(prop).length > 0

export const moduleConfigStyles = (theme) => ({
  moduleConfigRoot: {
    color: theme.palette.text.primary,
    fontSize: 14,
    fontWeight: "700 !important",
  },
  sectionRoot: {
    color: theme.palette.text.primary,
    fontSize: 16,
    fontWeight: "700 !important",
  },
  root: {
    borderRadius: 3,
    boxShadow:
      "0 0 0 1px rgba(63,63,68,0.05), 0 3px 10px 0 rgba(63,63,68,0.15)",
    marginBottom: 4,
    marginTop: 4,
    padding: 12,
  },
})

export const useCommonConfigStyles = makeStyles((theme) => ({
  gridItem: (props) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: 4,
    marginBottom: 4,
  }),
  gridContainer: (props) => ({
    overflowY: "scroll",
    overflowX: "hidden",
    maxHeight: props.isOnDrawer ? "100vh" : "75vh",
  }),
  gridItemVertical: (props) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  typographySubtitle2: (props) => ({
    fontWeight: 800,
    fontSize: 12,
  }),
  typographyCaption: (props) => ({
    fontWeight: 600,
    fontSize: 13,
    lineHeight: "17px",
    color: "#002845",
  }),
  formControlLabel: (props) => ({
    marginRight: 0,
  }),
}))
