import { Button } from "@material-ui/core"
import IconButton from "@material-ui/core/ButtonBase"
import Typography from "@material-ui/core/Typography"
import AssignmentIcon from "@material-ui/icons/Assignment"
import BuildIcon from "@material-ui/icons/Build"
import DeleteIcon from "@material-ui/icons/DeleteOutline"
import RefreshIcon from "@material-ui/icons/Refresh"
import SaveIcon from "@material-ui/icons/Save"
import { IconPlus } from "@tabler/icons-react"
import { Suspense, lazy, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import Select from "react-select"
import { useAuth } from "../../../../../contexts/AuthContext"
import {
  useDeepCompareEffect,
  useDeepCompareMemo,
} from "../../../../../utils/use-deep-compare"
import { apxTheme } from "../../../../ApplicationFrame/styling/CustomCSS"
import { useTrackedState } from "../../../../IntegrationExperience/store"
import HStack from "../../../../ReusableComponents/HStack"
import Loading from "../../../../ReusableComponents/Loading"
import ThemedTooltip from "../../../../ReusableComponents/ThemedTooltip"
import VStack from "../../../../ReusableComponents/VStack"
import DialogLoader from "../../../../ReusableComponents/skeletons/DialogLoader"
import {
  IndicatorSeparator,
  Option,
} from "../../../common/components/campaigncreator/components/context/components/targetaudience/components/selects/DidNotDoEventsComboBox"
import { getBiDimensionsAPI } from "../../bi-dashboards/action"
import {
  getCustomReportsAPI,
  getExplorerAPI,
  getFlatTableAPI,
} from "../actions"
import {
  DEFAULT_METRICS_BOX_TITLE,
  REPORT_ACTIONS_DIALOG_STATES,
} from "../constants"
import { useActions, useSelector } from "../store"
import DimensionsDrawer from "./DimensionsDrawer"
import FlatTable from "./FlatTable"

const SaveCustomReportDialog = lazy(() => import("./SaveCustomReportDialog"))
const DeleteCustomReportDialog = lazy(
  () => import("./DeleteCustomReportDialog"),
)

const customStyles = {
  control: (base, state) => ({
    ...base,
    primary: "orange",
    "&:hover": { borderColor: "none" },
    width: 350,
    minHeight: 45,
    paddingInline: 6,
    paddingBlock: 0,
    fontWeight: 500,
    fontSize: 13,
    border: "none",
    boxShadow: state.isFocused ? "0 0 0 1.5px rgba(1, 125, 253, 0.88)" : "none",
    borderRadius: 50,
    backgroundColor: state.isFocused
      ? state.isSelected
        ? "rgba(176, 176, 176, 0.22)"
        : apxTheme.palette.action.hover
      : apxTheme.palette.action.hover,
    color: state.isFocused
      ? apxTheme.palette.primary.main
      : apxTheme.palette.text.primary,
  }),
  "& .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover": {
    backgroundColor: "transparent",
  },
  placeholder: (base) => ({
    ...base,
    color: "#BDBDBD",
  }),
  menu: (base) => ({
    ...base,
    maxWidth: "100%",
    borderRadius: 12,
    marginTop: 6,
    boxShadow: "0px 4.985915184020996px 49.859153747558594px 0px #00284529",
    paddingInline: 4,
  }),
  multiValue: (base) => ({
    ...base,
    backgroundColor: "rgba(0, 40, 69, 0.1)",
    borderRadius: 8,
    padding: "0 6px",
    color: "rgba(0, 40, 69, 0.6)",
  }),
  multiValueRemove: (base) => ({
    ...base,
    borderRadius: "50%",
    color: apxTheme.palette.action.disabled,
    backgroundColor: "transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "rgba(229, 59, 59, 1)",
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(0, 40, 69, 1)",
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    color: "rgba(0, 40, 69, 0.2)",
    "&:hover": {
      color: "rgba(229, 59, 59, 1)",
    },
  }),
}

function getCustomReportResponsesLimit(features) {
  let key = "APX_CR_LIMIT_"
  let limit = 1000
  features.forEach((feature) => {
    if (feature.indexOf(key) !== -1) {
      limit = feature.slice(key.length)
    }
  })
  return Number(limit)
}

function SavedCustomReportSelect({ queryParams }) {
  const auth = useAuth()
  const { appId } = useParams()

  const {
    fetchingInProgress,
    reports,
    selectedReport,
    isExplorer,
    dimensions,
    filters,
  } = useSelector()

  const {
    setDimensions,
    setInstantDimensions,
    setInstantFilters,
    setFilters,
    setSelectedDimensions,
    setSelectedReport,
    setExplorerData,
    setFlatTableData,
    setFetchingInProgress,
    setBoxTitle,
    resetState,
    setOpenDrawer,
  } = useActions()

  const { app_data: { features = [] } = {} } = useTrackedState()

  const responseLimit = { limit: getCustomReportResponsesLimit(features) }

  const finalOptions =
    reports?.map((o) => ({
      label: o?.name,
      value: o?._id,
    })) ?? []

  return (
    <>
      <HStack spacing={24}>
        {" "}
        {/* Loading State */}
        {fetchingInProgress && <Loading size={18} />}
        {/* Saved Custom Reports Select */}
        <Select
          inputId="saved-custom-reports"
          placeholder="Select a Saved Report"
          noOptionsMessage={() => (
            <Typography
              style={{
                fontWeight: 700,
                fontSize: 13,
              }}
            >
              No Saved Reports Found
            </Typography>
          )}
          isSearchable={true}
          isClearable={true}
          isMulti={false}
          closeMenuOnSelect={true}
          options={finalOptions}
          value={finalOptions?.find((o) => o?.value === selectedReport)}
          onChange={(pair) => {
            const reportId = pair?.value ?? ""
            setSelectedReport(reportId)

            if (reportId === "") {
              resetState()
              return
            } else {
              const report = reports?.find((report) => report?._id === reportId)

              if (report) {
                setBoxTitle(report?.name)
                setFilters(report?.filters || [])

                const dims =
                  report?.dimensions?.map((d) => ({
                    ...d,
                    id: d?.id,
                  })) ?? []

                setDimensions(dims)
                setSelectedDimensions(dims)

                const api = isExplorer ? getExplorerAPI : getFlatTableAPI

                if (!fetchingInProgress) {
                  const dim = report?.dimensions?.map((d) => ({
                    ...d,
                    id: d?.id,
                  }))
                  const postBody = {
                    filters: report?.filters || [],
                    metrics: report?.metrics || [],
                    dimensions: dim,
                  }

                  setFetchingInProgress(true)
                  api(
                    auth,
                    appId,
                    { ...queryParams, ...responseLimit },
                    postBody,
                  )
                    .then((response) => {
                      isExplorer
                        ? setExplorerData(response)
                        : setFlatTableData(response)
                    })
                    .catch((err) => {
                      setExplorerData({
                        aggregations: [],
                        data: [],
                      })
                    })
                    .finally(() => {
                      setFetchingInProgress(false)
                    })
                }
              }
            }
          }}
          components={{
            Option,
            IndicatorSeparator,
          }}
          styles={{
            ...customStyles,
          }}
        />
        <Button
          size="large"
          variant="contained"
          color="primary"
          startIcon={<IconPlus size={15} />}
          style={{ borderRadius: "50px" }}
          onClick={() => {
            setOpenDrawer(true)
            setInstantDimensions(dimensions)
            setInstantFilters(filters)
          }}
        >
          Add Dimensions
        </Button>
      </HStack>
    </>
  )
}

export function CustomReportHolder({ queryParams }) {
  const auth = useAuth()

  const {
    activeTab,
    dimensions,
    fetchReports,
    fetchingInProgress,
    filters,
    metrics,
    reports,
    selectedDimensions,
    selectedReport,
    boxTitle,
    refreshData,
    openDrawer,
  } = useSelector()

  const {
    setReports,
    setAttributes,
    setInstantDimensions,
    setInstantFilters,
    setSelectedDimensions,
    setFlatTableData,
    setFetchingInProgress,
    setFetchReports,
    setRefreshData,
    setDimensionWithType,
    setOpenDrawer,
  } = useActions()

  const { appId } = useParams()
  const { app_data: { features = [] } = {} } = useTrackedState()

  const responseLimit = { limit: getCustomReportResponsesLimit(features) }

  useDeepCompareEffect(() => {
    getBiDimensionsAPI(auth, appId, queryParams).then((response) => {
      const attributes = response.map((item) => item["attribute"])
      setDimensionWithType(response)
      setAttributes(
        [...new Set([...attributes])].map((o) => ({
          label: o,
          value: o,
        })),
      )
    })
  }, [appId, auth])

  useDeepCompareEffect(() => {
    if (fetchReports) {
      getCustomReportsAPI(auth, appId, queryParams).then((reports) => {
        setReports(reports)
      })
      setFetchReports(false)
    }
  }, [appId, auth, fetchReports, queryParams])

  useDeepCompareEffect(() => {
    if (dimensions.length > 0 || selectedDimensions.length > 0) {
      if (!fetchingInProgress) {
        const dim = dimensions
        const api = getFlatTableAPI
        const postBody = {
          filters,
          metrics,
          dimensions: dim.length > 0 ? dim : dimensions.slice(0, 1),
        }
        setFetchingInProgress(true)
        api(auth, appId, { ...queryParams, ...responseLimit }, postBody)
          .then((response) => {
            setFlatTableData(response)
          })
          .catch((err) => {
            setSelectedDimensions(dimensions.slice(0, 1))
          })
          .finally(() => {
            setFetchingInProgress(false)
            setRefreshData(false)
          })
      }
    }
    setRefreshData(false)
  }, [auth, appId, refreshData, queryParams, selectedReport, activeTab])

  const readyToGo = useMemo(() => dimensions.length > 0, [dimensions.length])

  const isSavedReport = useDeepCompareMemo(
    () =>
      selectedReport !== "" &&
      reports.findIndex((report) => report._id === selectedReport) !== -1,
    [reports, selectedReport],
  )

  const [showDialog, setShowDialog] = useState(
    REPORT_ACTIONS_DIALOG_STATES.CLOSED,
  )

  return (
    <>
      <VStack
        style={{
          width: "100%",
        }}
        justify={"flex-start"}
      >
        <HStack
          style={{
            width: "100%",
            height: "15%",
            padding: "36px 24px",
          }}
          justify={"space-between"}
          align={"center"}
        >
          <HStack spacing={12}>
            {boxTitle === DEFAULT_METRICS_BOX_TITLE ? (
              <BuildIcon />
            ) : (
              <AssignmentIcon />
            )}
            <span>{boxTitle}</span>
          </HStack>
          <SavedCustomReportSelect queryParams={queryParams} />
        </HStack>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {dimensions.length === 0 && (
            <>
              <VStack
                style={{
                  width: "25%",
                }}
              >
                <img
                  src="https://storage.googleapis.com/apx_frontend-assets/stories/image%201815.png"
                  alt="Add Dimension"
                ></img>
                <VStack spacing={18}>
                  <span
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Click on add dimension to create new records or,<br></br>{" "}
                    select the saved reports from dropdown.{" "}
                  </span>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button
                      size="large"
                      variant="contained"
                      color="primary"
                      startIcon={<IconPlus size={15} />}
                      style={{ borderRadius: "50px" }}
                      onClick={() => {
                        setOpenDrawer(true)
                        setInstantDimensions(dimensions)
                        setInstantFilters(filters)
                      }}
                    >
                      Add Dimensions
                    </Button>
                  </div>
                </VStack>
              </VStack>
            </>
          )}
          {readyToGo && (
            <>
              <VStack style={{ width: "100%" }}>
                <HStack
                  justify={"flex-end"}
                  spacing="30px"
                  style={{
                    marginRight: 36,
                  }}
                >
                  {/* Save Button */}
                  {readyToGo && (
                    <ThemedTooltip
                      dark
                      title="Save this as new report"
                    >
                      <IconButton
                        onClick={() => {
                          setShowDialog(REPORT_ACTIONS_DIALOG_STATES.SAVE)
                        }}
                      >
                        <SaveIcon color="primary" />
                      </IconButton>
                    </ThemedTooltip>
                  )}

                  {/* Update Button */}
                  <ThemedTooltip
                    dark
                    title="Refresh data"
                  >
                    <IconButton
                      disabled={!(dimensions.length > 0)}
                      onClick={(e) => {
                        setRefreshData(true)
                      }}
                    >
                      <RefreshIcon color="action" />
                    </IconButton>
                  </ThemedTooltip>

                  {/* Delete Button */}
                  {isSavedReport && (
                    <ThemedTooltip
                      dark
                      title="Delete this report"
                    >
                      <IconButton
                        onClick={() => {
                          setShowDialog(REPORT_ACTIONS_DIALOG_STATES.DELETE)
                        }}
                      >
                        <DeleteIcon color="error" />
                      </IconButton>
                    </ThemedTooltip>
                  )}
                </HStack>
                <VStack style={{ padding: "0px 24px" }}>
                  <Typography variant={"subtitle1"}>
                    Showing top <b>{getCustomReportResponsesLimit(features)}</b>{" "}
                    responses.
                  </Typography>
                  {fetchingInProgress ? (
                    <Loading />
                  ) : (
                    <FlatTable queryParams={queryParams} />
                  )}
                </VStack>
              </VStack>
            </>
          )}
        </div>
      </VStack>

      <DimensionsDrawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false)
          setInstantDimensions([])
          setInstantFilters([])
        }}
      />

      {showDialog === REPORT_ACTIONS_DIALOG_STATES.SAVE && (
        <Suspense fallback={<DialogLoader />}>
          <SaveCustomReportDialog
            open={showDialog === REPORT_ACTIONS_DIALOG_STATES.SAVE}
            onClose={() => {
              setShowDialog(REPORT_ACTIONS_DIALOG_STATES.CLOSED)
            }}
            queryParams={queryParams}
          />
        </Suspense>
      )}

      {showDialog === REPORT_ACTIONS_DIALOG_STATES.DELETE && (
        <Suspense fallback={<DialogLoader />}>
          <DeleteCustomReportDialog
            open={showDialog === REPORT_ACTIONS_DIALOG_STATES.DELETE}
            onClose={() => {
              setShowDialog(REPORT_ACTIONS_DIALOG_STATES.CLOSED)
            }}
            queryParams={queryParams}
          />
        </Suspense>
      )}
    </>
  )
}
