export const UPDATE_IN_NEW_STEP = "UPDATE_IN_NEW_STEP"
export const COPY_STEP = "COPY_STEP"
export const DELETE_STEP_FROM_CAMPAIGN = "DELETE_STEP_FROM_CAMPAIGN"
export const ADD_NEXT_STEP_TO_CAMPAIGN = "ADD_NEXT_STEP_TO_CAMPAIGN"
export const SET_NEXT_STEP_DATA = "SET_NEXT_STEP_DATA"
export const MOVE_STEP = "MOVE_STEP"
export const SET_WEB_VIEW = "SET_WEB_VIEW"
export const SET_WEB_VIEW_TAG = "SET_WEB_VIEW_TAG"
export const SET_DELAY = "SET_DELAY"
export const SET_BACKGROUND_COLOR = "SET_BACKGROUND_COLOR"

// New Border Constants
export const SET_NEW_BORDER_ENABLED = "SET_NEW_BORDER_ENABLED"
export const SET_NEW_BORDER_COLOR = "SET_NEW_BORDER_COLOR"
export const SET_NEW_BORDER_WIDTH = "SET_NEW_BORDER_WIDTH"
export const SET_NEW_BORDER_STYLE = "SET_NEW_BORDER_STYLE"
export const SET_NEW_BORDER_RADIUS = "SET_NEW_BORDER_RADIUS"

export const SET_BORDER_ENABLED = "SET_BORDER_ENABLED"
export const SET_BORDER_RADIUS = "SET_BORDER_RADIUS"
export const SET_BORDER_WIDTH = "SET_BORDER_WIDTH"

export const SET_DIM_BACKGROUND = "SET_DIM_BACKGROUND"
export const SET_DIM_BACKGROUND_COLOR = "SET_DIM_BACKGROUND_COLOR"
export const SET_DIM_BACKGROUND_OPACITY = "SET_DIM_BACKGROUND_OPACITY"
export const SET_ELEMENT_HIGHLIGHTER_SHAPE = "SET_ELEMENT_HIGHLIGHTER_SHAPE"
export const SET_ELEMENT_HIGHLIGHTER_OFFSET_RADIUS =
  "SET_ELEMENT_HIGHLIGHTER_OFFSET_RADIUS"
export const SET_ELEMENT_HIGHLIGHTER_BORDER_COLOR =
  "SET_ELEMENT_HIGHLIGHTER_BORDER_COLOR"
export const SET_ELEMENT_HIGHLIGHTER_BORDER_WIDTH =
  "SET_ELEMENT_HIGHLIGHTER_BORDER_WIDTH"
export const SET_ELEMENT_HIGHLIGHTER_BORDER_OPACITY =
  "SET_ELEMENT_HIGHLIGHTER_BORDER_OPACITY"
export const SET_ELEMENT_HIGHLIGHTER_OFFSET_BOTTOM =
  "SET_ELEMENT_HIGHLIGHTER_OFFSET_BOTTOM"
export const SET_ELEMENT_HIGHLIGHTER_OFFSET_LEFT =
  "SET_ELEMENT_HIGHLIGHTER_OFFSET_LEFT"
export const SET_ELEMENT_HIGHLIGHTER_OFFSET_RIGHT =
  "SET_ELEMENT_HIGHLIGHTER_OFFSET_RIGHT"
export const SET_ELEMENT_HIGHLIGHTER_OFFSET_TOP =
  "SET_ELEMENT_HIGHLIGHTER_OFFSET_TOP"
export const SET_ELEMENT_HIGHLIGHTER_CORNER_RADIUS =
  "SET_ELEMENT_HIGHLIGHTER_CORNER_RADIUS"
export const CHECK_AND_REMOVE_ELEMENT_HIGHLIGHTER =
  "CHECK_AND_REMOVE_ELEMENT_HIGHLIGHTER"
export const CHECK_AND_REMOVE_WIDTH = "CHECK_AND_REMOVE_WIDTH"
export const SET_MESSAGE_POSITION = "SET_MESSAGE_POSITION"
export const SET_INAPP_POSITION = "SET_INAPP_POSITION"
export const SET_TOOLTIP_TEXT_TYPE = "SET_TOOLTIP_TEXT_TYPE"
export const SET_WEB_URL = "SET_WEB_URL"
export const SET_DISMISS_OUTSIDE_TOUCH = "SET_DISMISS_OUTSIDE_TOUCH"
export const SET_BUTTONS = "SET_BUTTONS"
export const SET_ANIMATION_ENABLED = "SET_ANIMATION_ENABLED"
export const SET_ANIMATION_DURATION = "SET_ANIMATION_DURATION"
export const SET_WEB_HTML_DATA = "SET_WEB_HTML_DATA"
export const SET_PASSIVE_NUDGE_ANIMATION_TYPE =
  "SET_PASSIVE_NUDGE_ANIMATION_TYPE"
export const SET_WIDTH_TYPE = "SET_WIDTH_TYPE"
export const SET_WIDTH = "SET_WIDTH"
export const REMOVE_WIDTH = "REMOVE_WIDTH"
export const SET_CURRENT_STEP = "SET_CURRENT_STEP"
export const SET_BUTTON_ACTION = "SET_BUTTON_ACTION"
export const SET_IMAGE_ONLY_REDIRECTION_CONFIG =
  "SET_IMAGE_ONLY_REDIRECTION_CONFIG"
//position offet inside the position config
export const SET_BACKGROUND_POSITION_OFFSET_SKID =
  "SET_BACKGROUND_POSITION_OFFSET_SKID"
export const SET_BACKGROUND_POSITION_OFFSET_DISTANCE =
  "SET_BACKGROUND_POSITION_OFFSET_DISTANCE"
//Position Offset Constant for Android,IOS
export const SET_BACKGROUND_OFFSET_SKID = "SET_BACKGROUND_OFFSET_SKID"
export const SET_BACKGROUND_OFFSET_DISTANCE = "SET_BACKGROUND_OFFSET_DISTANCE"

// Old Button Constants
export const SET_BUTTON_TEXT = "SET_BUTTON_TEXT"
export const SET_BUTTON_BORDER_RADIUS = "SET_BUTTON_BORDER_RADIUS"
export const SET_BUTTON_COLOR = "SET_BUTTON_COLOR"
export const SET_BUTTON_PADDING = "SET_BUTTON_PADDING"
export const SET_BUTTON_MARGIN = "SET_BUTTON_MARGIN"
export const SET_BUTTON_TEXT_PADDING = "SET_BUTTON_TEXT_PADDING"
export const SET_BUTTON_TEXT_MARGIN = "SET_BUTTON_TEXT_MARGIN"
export const SET_BUTTON_BG_COLOR = "SET_BUTTON_BG_COLOR"
export const SET_BUTTON_FONT_FAMILY = "SET_BUTTON_FONT_FAMILY"
export const SET_BUTTON_FONT_SIZE = "SET_BUTTON_FONT_SIZE"
export const SET_BUTTON_ADAPTIVE_TEXT = "SET_BUTTON_ADAPTIVE_TEXT"
export const SET_BUTTON_REDIRECT_ACTIVITY = "SET_BUTTON_REDIRECT_ACTIVITY"
export const SET_BUTTON_REDIRECT_DEEPLINK = "SET_BUTTON_REDIRECT_DEEPLINK"
export const SET_BUTTON_REDIRECT_TYPE = "SET_BUTTON_REDIRECT_TYPE"
export const SET_BUTTON_ACTION_TYPE = "SET_BUTTON_ACTION_TYPE"
export const SET_BUTTON_FONT_STYLE = "SET_BUTTON_FONT_STYLE"
export const SET_BUTTON_DYNAMIC_TEXT_CONFIG = "SET_BUTTON_DYNAMIC_TEXT_CONFIG"

// New Button Constants
export const SET_NEW_BUTTON_PADDING = "SET_NEW_BUTTON_PADDING"
export const SET_NEW_BUTTON_MARGIN = "SET_NEW_BUTTON_MARGIN"
export const SET_NEW_BUTTON_BG_COLOR = "SET_NEW_BUTTON_BG_COLOR"
export const SET_NEW_BUTTON_BORDER_ENABLED = "SET_NEW_BUTTON_BORDER_ENABLED"
export const SET_NEW_BUTTON_ACTION_ENABLED = "SET_NEW_BUTTON_ACTION_ENABLED"
export const SET_NEW_BUTTON_ACTION_TYPE = "SET_NEW_BUTTON_ACTION_TYPE"
export const SET_NEW_BUTTON_ACTION_DATA = "SET_NEW_BUTTON_ACTION_DATA"
export const SET_NEW_BUTTON_REDIRECT_URL = "SET_NEW_BUTTON_REDIRECT_URL"
export const SET_NEW_BUTTON_REDIRECT_TYPE = "SET_NEW_BUTTON_REDIRECT_TYPE"
export const SET_NEW_BUTTON_BORDER_COLOR = "SET_NEW_BUTTON_BORDER_COLOR"
export const SET_NEW_BUTTON_BORDER_WIDTH = "SET_NEW_BUTTON_BORDER_WIDTH"
export const SET_NEW_BUTTON_BORDER_STYLE = "SET_NEW_BUTTON_BORDER_STYLE"
export const SET_NEW_BUTTON_BORDER_RADIUS = "SET_NEW_BUTTON_BORDER_RADIUS"

// New Button Text Constants
export const SET_NEW_BUTTON_TEXT_PADDING = "SET_NEW_BUTTON_TEXT_PADDING"
export const SET_NEW_BUTTON_TEXT_MARGIN = "SET_NEW_BUTTON_TEXT_MARGIN"
export const SET_NEW_BUTTON_TEXT = "SET_NEW_BUTTON_TEXT"
export const SET_NEW_BUTTON_TEXT_COLOR = "SET_NEW_BUTTON_TEXT_COLOR"
export const SET_NEW_BUTTON_FONT_FAMILY = "SET_NEW_BUTTON_FONT_FAMILY"
export const SET_NEW_BUTTON_FONT_SIZE = "SET_NEW_BUTTON_FONT_SIZE"
export const SET_NEW_BUTTON_ADAPTIVE_TEXT = "SET_NEW_BUTTON_ADAPTIVE_TEXT"
export const SET_NEW_BUTTON_FONT_STYLE = "SET_NEW_BUTTON_FONT_STYLE"
export const SET_NEW_BUTTON_FONT_WEIGHT = "SET_NEW_BUTTON_FONT_WEIGHT"
export const SET_NEW_BUTTON_DYNAMIC_TEXT = "SET_NEW_BUTTON_DYNAMIC_TEXT"

// Font and Text Constants
export const SET_TEXT = "SET_TEXT"
export const SET_COLOR = "SET_COLOR"
export const SET_GRADIENT = "SET_GRADIENT"
export const SET_FONT_FAMILY = "SET_FONT_FAMILY"
export const SET_FONT_SIZE = "SET_FONT_SIZE"
export const SET_ADAPTIVE_TEXT = "SET_ADAPTIVE_TEXT"
export const SET_FONT_STYLE = "SET_FONT_STYLE"
export const SET_TEXT_ALIGNMENT = "SET_TEXT_ALIGNMENT"

// New Font and Text Constants
export const SET_NEW_FONT_FAMILY = "SET_NEW_FONT_FAMILY"
export const SET_NEW_FONT_SIZE = "SET_NEW_FONT_SIZE"
export const SET_NEW_ADAPTIVE_TEXT = "SET_NEW_ADAPTIVE_TEXT"
export const SET_NEW_FONT_STYLE = "SET_NEW_FONT_STYLE"
export const SET_NEW_FONT_WEIGHT = "SET_NEW_FONT_WEIGHT"
export const SET_NEW_TEXT_ALIGNMENT = "SET_NEW_TEXT_ALIGNMENT"

export const SET_BUTTON_IMAGE = "SET_BUTTON_IMAGE"
export const SET_COACHMARK_TYPE = "SET_COACHMARK_TYPE"
export const SET_COACHMARK_HAS_ARROW = "SET_COACHMARK_HAS_ARROW"
export const SET_COACHMARK_HAS_RIPPLE = "SET_COACHMARK_HAS_RIPPLE"
export const SET_OPACITY = "SET_OPACITY"
export const SET_MODULE_TYPE = "SET_MODULE_TYPE"
export const SET_ROW_NAME = "SET_ROW_NAME"
export const SET_SLOT_NUMBER = "SET_SLOT_NUMBER"
export const SET_SHOW_CONFIGURATION_DRAWER = "SET_SHOW_CONFIGURATION_DRAWER"
export const SET_SHOW_WEB_VIEW_TEMPLATES = "SET_SHOW_WEB_VIEW_TEMPLATES"
export const SET_SHOW_CUSTOMIZE_TEMPLATE_CONFIRMATION =
  "SET_SHOW_CUSTOMIZE_TEMPLATE_CONFIRMATION"
export const SET_SHOW_EDIT_SIMULATOR_BUTTON = "SET_SHOW_EDIT_SIMULATOR_BUTTON"
export const SET_BADGE_VARIANT = "SET_BADGE_VARIANT"
export const SET_SHOW_QR_IN_VIEW_ID_PATH_SELECTION =
  "SET_SHOW_QR_IN_VIEW_ID_PATH_SELECTION"
export const SET_SHOW_QR_FOR_TEST_DEVICE_ADDITION =
  "SET_SHOW_QR_FOR_TEST_DEVICE_ADDITION"
export const SET_PREVIEW_MODE = "SET_PREVIEW_MODE"
export const SET_BADGE_CAN_HORIZONTAL_AUTO_SCROLL_OPTION_SHOW =
  "SET_BADGE_CAN_HORIZONTAL_AUTO_SCROLL_OPTION_SHOW"
export const SET_IMAGE = "SET_IMAGE"
export const SET_IMAGE_SIZE = "SET_IMAGE_SIZE"
export const SET_UIS_CONFIG = "SET_UIS_CONFIG"
export const SET_ONLY_IMAGE = "SET_ONLY_IMAGE"
export const SET_TOUCH_URL = "SET_TOUCH_URL"
export const SET_BG_IMAGE_SIZE = "SET_BG_IMAGE_SIZE"
export const SET_DIMMED_BACKGROUND = "SET_DIMMED_BACKGROUND"
export const SET_INAPP_DISMISS_OUTSIDE_TOUCH = "SET_INAPP_DISMISS_OUTSIDE_TOUCH"
export const SET_INAPP_HTML = "SET_INAPP_HTML"
export const SET_INAPP_HTML_URL = "SET_INAPP_HTML_URL"
export const SET_MAXIMUM_HEIGHT = "SET_MAXIMUM_HEIGHT"
export const SET_BG_IMAGE = "SET_BG_IMAGE"
export const SET_PREVIEW_DATA = "SET_PREVIEW_DATA"

// New Title and Description Constants
export const SET_TITLE_ENABLED = "SET_TITLE_ENABLED"
export const SET_DESCRIPTION_ENABLED = "SET_DESCRIPTION_ENABLED"
export const SET_MODAL_DIRECTION = "SET_MODAL_DIRECTION"
export const SET_MODAL_NONMEDIA_DIRECTION = "SET_MODAL_NONMEDIA_DIRECTION"
export const SET_TITLE_MARGIN = "SET_TITLE_MARGIN"
export const SET_TITLE_PADDING = "SET_TITLE_PADDING"
export const SET_DESCRIPTION_MARGIN = "SET_DESCRIPTION_MARGIN"
export const SET_DESCRIPTION_PADDING = "SET_DESCRIPTION_PADDING"

// New Width Constants
export const SET_MODAL_WIDTH = "SET_MODAL_WIDTH"

// Modal Scrollable Config
export const SET_SCROLLABLE = "SET_SCROLLABLE"
export const SET_SCROLLABLE_HEIGHT = "SET_SCROLLABLE_HEIGHT"
export const SET_SCROLLABLE_BORDER = "SET_SCROLLABLE_BORDER"
export const SET_SCROLLABLE_BORDER_WIDTH = "SET_SCROLLABLE_BORDER_WIDTH"
export const SET_SCROLLABLE_BORDER_COLOR = "SET_SCROLLABLE_BORDER_COLOR"
export const SET_SCROLLABLE_BORDER_STYLE = "SET_SCROLLABLE_BORDER_STYLE"
export const SET_SCROLLABLE_BORDER_RADIUS = "SET_SCROLLABLE_BORDER_RADIUS"

// Shadow Constants
export const SET_SHADOW_ENABLED = "SET_SHADOW_ENABLED"
export const SET_SHADOW_COLOR = "SET_SHADOW_COLOR"
export const SET_SHADOW_OFFSET_X = "SET_SHADOW_OFFSET_X"
export const SET_SHADOW_OFFSET_Y = "SET_SHADOW_OFFSET_Y"
export const SET_SHADOW_BLUR_RADIUS = "SET_SHADOW_BLUR_RADIUS"
export const SET_SHADOW_SPREAD_RADIUS = "SET_SHADOW_SPREAD_RADIUS"

// Modal Margin Constants
export const SET_MODAL_MARGIN_TOP = "SET_MODAL_MARGIN_TOP"
export const SET_MODAL_MARGIN_BOTTOM = "SET_MODAL_MARGIN_BOTTOM"
export const SET_MODAL_MARGIN_LEFT = "SET_MODAL_MARGIN_LEFT"
export const SET_MODAL_MARGIN_RIGHT = "SET_MODAL_MARGIN_RIGHT"

// Modal Padding Constants
export const SET_MODAL_PADDING_TOP = "SET_MODAL_PADDING_TOP"
export const SET_MODAL_PADDING_BOTTOM = "SET_MODAL_PADDING_BOTTOM"
export const SET_MODAL_PADDING_LEFT = "SET_MODAL_PADDING_LEFT"
export const SET_MODAL_PADDING_RIGHT = "SET_MODAL_PADDING_RIGHT"

// Title Icon Constants
export const SET_TITLE_ICON_ENABLED = "SET_TITLE_ICON_ENABLED"
export const SET_TITLE_ICON_PATH = "SET_TITLE_ICON_PATH"
export const SET_TITLE_ICON_WIDTH = "SET_TITLE_ICON_WIDTH"
export const SET_TITLE_ICON_HEIGHT = "SET_TITLE_ICON_HEIGHT"
export const SET_TITLE_ICON_BORDER_ENABLED = "SET_TITLE_ICON_BORDER_ENABLED"
export const SET_TITLE_ICON_BORDER_WIDTH = "SET_TITLE_ICON_BORDER_WIDTH"
export const SET_TITLE_ICON_BORDER_COLOR = "SET_TITLE_ICON_BORDER_COLOR"
export const SET_TITLE_ICON_BORDER_RADIUS = "SET_TITLE_ICON_BORDER_RADIUS"
export const SET_TITLE_ICON_BORDER_STYLE = "SET_TITLE_ICON_BORDER_STYLE"

// Modal Media Constants
export const SET_MODAL_MEDIA_TYPE = "SET_MODAL_MEDIA_TYPE"

// Media Icon Constants
export const SET_MEDIA_ICON_PATH = "SET_MEDIA_ICON_PATH"
export const SET_MEDIA_ICON_HEIGHT = "SET_MEDIA_ICON_HEIGHT"
export const SET_MEDIA_ICON_WIDTH = "SET_MEDIA_ICON_WIDTH"
export const SET_MEDIA_ICON_ALIGNMENT = "SET_MEDIA_ICON_ALIGNMENT"
export const SET_MEDIA_ICON_BORDER_ENABLED = "SET_MEDIA_ICON_BORDER_ENABLED"
export const SET_MEDIA_ICON_BORDER_WIDTH = "SET_MEDIA_ICON_BORDER_WIDTH"
export const SET_MEDIA_ICON_BORDER_COLOR = "SET_MEDIA_ICON_BORDER_COLOR"
export const SET_MEDIA_ICON_BORDER_RADIUS = "SET_MEDIA_ICON_BORDER_RADIUS"
export const SET_MEDIA_ICON_BORDER_STYLE = "SET_MEDIA_ICON_BORDER_STYLE"

// Media Image Constants
export const SET_MEDIA_IMAGE_PATH = "SET_MEDIA_IMAGE_PATH"
export const SET_MEDIA_IMAGE_WIDTH = "SET_MEDIA_IMAGE_WIDTH"
export const SET_MEDIA_IMAGE_HEIGHT = "SET_MEDIA_IMAGE_HEIGHT"
export const SET_MEDIA_IMAGE_BORDER_COLOR = "SET_MEDIA_IMAGE_BORDER_COLOR"
export const SET_MEDIA_IMAGE_BORDER_ENABLED = "SET_MEDIA_IMAGE_BORDER_ENABLED"
export const SET_MEDIA_IMAGE_BORDER_RADIUS = "SET_MEDIA_IMAGE_BORDER_RADIUS"
export const SET_MEDIA_IMAGE_BORDER_STYLE = "SET_MEDIA_IMAGE_BORDER_STYLE"
export const SET_MEDIA_IMAGE_BORDER_WIDTH = "SET_MEDIA_IMAGE_BORDER_WIDTH"
export const SET_MEDIA_IMAGE_ACTION_ENABLED = "SET_MEDIA_IMAGE_ACTION_ENABLED"
export const SET_MEDIA_IMAGE_ACTION = "SET_MEDIA_IMAGE_ACTION"

// Media Video Constants
export const SET_MEDIA_VIDEO_PATH = "SET_MEDIA_VIDEO_PATH"
export const SET_MEDIA_VIDEO_WIDTH = "SET_MEDIA_VIDEO_WIDTH"
export const SET_MEDIA_VIDEO_HEIGHT = "SET_MEDIA_VIDEO_HEIGHT"
export const SET_MEDIA_VIDEO_BORDER_COLOR = "SET_MEDIA_VIDEO_BORDER_COLOR"
export const SET_MEDIA_VIDEO_BORDER_ENABLED = "SET_MEDIA_VIDEO_BORDER_ENABLED"
export const SET_MEDIA_VIDEO_BORDER_RADIUS = "SET_MEDIA_VIDEO_BORDER_RADIUS"
export const SET_MEDIA_VIDEO_BORDER_STYLE = "SET_MEDIA_VIDEO_BORDER_STYLE"
export const SET_MEDIA_VIDEO_BORDER_WIDTH = "SET_MEDIA_VIDEO_BORDER_WIDTH"
export const SET_MEDIA_VIDEO_EMBED_ENABLED = "SET_MEDIA_VIDEO_EMBED_ENABLED"
export const SET_MEDIA_VIDEO_EMBED_HTML = "SET_MEDIA_VIDEO_EMBED_HTML"
export const SET_MEDIA_VIDEO_AUDIO_ENABLED = "SET_MEDIA_VIDEO_AUDIO_ENABLED"

// Modal New Buttons Constants
export const SET_BUTTON_PLACEMENT_MODE = "SET_BUTTON_PLACEMENT_MODE"
export const SET_BUTTONS_SLOTS_UPDATE_MODE = "SET_BUTTONS_SLOTS_UPDATE_MODE"
export const SET_BUTTONS_SLOTS_COUNT = "SET_BUTTONS_SLOTS_COUNT"
export const SET_END_OF_VIDEO = "SET_END_OF_VIDEO"
export const SET_ENABLE_PIP_MODE = "SET_ENABLE_PIP_MODE"
export const CHECK_AND_SET_ENABLE_PIP_MODE = "CHECK_AND_SET_ENABLE_PIP_MODE"

// Video InApp Constants
export const SET_VIDEO_LINK = "SET_VIDEO_LINK"
export const SET_VALID_VIDEO_LINK = "SET_VALID_VIDEO_LINK"
export const SET_HEIGHT = "SET_HEIGHT"
export const SET_VIDEO_LOADING_TYPE = "SET_VIDEO_LOADING_TYPE"
export const SET_VIDEO_HAS_AUDIO = "SET_VIDEO_HAS_AUDIO"
export const SET_VIDEO_BINDING = "SET_VIDEO_BINDING"
export const SET_VIDEO_PROGRESS_BAR = "SET_VIDEO_PROGRESS_BAR"
export const SET_VIDEO_ACTIVITY = "SET_VIDEO_ACTIVITY"
export const SET_VIDEO_CLOSE = "SET_VIDEO_CLOSE"
export const SET_VIDEO_AUTO_REPLAY = "SET_VIDEO_AUTO_REPLAY"
export const SET_VIDEO_CAN_MINIMIZE = "SET_VIDEO_CAN_MINIMIZE"
export const SET_VIDEO_ISFULLSCREEN = "SET_VIDEO_ISFULLSCREEN"
export const SET_VIDEO_MUTE_AUDIO = "SET_VIDEO_MUTE_AUDIO"
export const SET_VIDEO_BUTTON_COLOR = "SET_VIDEO_BUTTON_COLOR"

export const SET_ONBOARDING_ANIM_TYPE = "SET_ONBOARDING_ANIM_TYPE"
export const SET_ONBOARDING_ANIM_DURATION = "SET_ONBOARDING_ANIM_DURATION"
export const SET_ONBOARDING_FADEOUT_OPACITY = "SET_ONBOARDING_FADEOUT_OPACITY"
export const SET_ONBOARDING_DISMISS_TYPE = "SET_ONBOARDING_DISMISS_TYPE"
export const SET_ONBOARDING_BACKGROUND_COLOR = "SET_ONBOARDING_BACKGROUND_COLOR"
export const SET_ONBOARDING_HAS_COUNTER = "SET_ONBOARDING_HAS_COUNTER"
export const SET_ONBOARDING_HAS_DOTS = "SET_ONBOARDING_HAS_DOTS"

export const SET_ONBOARDING_PAGINATION_TYPE = "SET_ONBOARDING_PAGINATION_TYPE"
export const SET_ONBOARDING_PAGINATION_COUNTER_TYPE =
  "SET_ONBOARDING_PAGINATION_COUNTER_TYPE"
export const SET_ONBOARDING_BORDER_COLOR = "SET_ONBOARDING_BORDER_COLOR"
export const SET_ONBOARDING_BORDER_WIDTH = "SET_ONBOARDING_BORDER_WIDTH"
export const SET_ONBOARDING_CORNER_WIDTH = "SET_ONBOARDING_CORNER_WIDTH"
export const SET_ONBOARDING_DIVIDER_ENABLED = "SET_ONBOARDING_DIVIDER_ENABLED"
export const SET_ONBOARDING_DIVIDER_HEIGHT = "SET_ONBOARDING_DIVIDER_HEIGHT"
export const SET_ONBOARDING_DIVIDER_COLOR = "SET_ONBOARDING_DIVIDER_COLOR"
export const SET_ONBOARDING_DIVIDER_MARGIN = "SET_ONBOARDING_DIVIDER_MARGIN"

export const SET_ONBOARDING_LEFT_PAD = "SET_ONBOARDING_LEFT_PAD"
export const SET_ONBOARDING_RIGHT_PAD = "SET_ONBOARDING_RIGHT_PAD"
export const SET_ONBOARDING_TOP_PAD = "SET_ONBOARDING_TOP_PAD"
export const SET_ONBOARDING_BOTTOM_PAD = "SET_ONBOARDING_BOTTOM_PAD"
export const SET_ONBOARDING_PREV_NEXT_DIST = "SET_ONBOARDING_PREV_NEXT_DIST"
export const SET_ONBOARDING_ACTION_BUTTON_MARGIN =
  "SET_ONBOARDING_ACTION_BUTTON_MARGIN"
export const SET_ONBOARDING_COUNTER_TEXT = "SET_ONBOARDING_COUNTER_TEXT"
export const SET_ONBOARDING_COUNTER_COLOR = "SET_ONBOARDING_COUNTER_COLOR"
export const SET_ONBOARDING_COUNTER_FONT_FAMILY =
  "SET_ONBOARDING_COUNTER_FONT_FAMILY"
export const SET_ONBOARDING_TEXT_MARGIN = "SET_ONBOARDING_TEXT_MARGIN"
export const SET_ONBOARDING_TEXT_PADDING = "SET_ONBOARDING_TEXT_PADDING"
export const SET_ONBOARDING_COUNTER_TEXT_SIZE =
  "SET_ONBOARDING_COUNTER_TEXT_SIZE"
export const SET_ONBOARDING_COUNTER_FONT_STYLE =
  "SET_ONBOARDING_COUNTER_FONT_STYLE"
export const SET_ONBOARDING_COUNTER_LEFT_DISTANCE =
  "SET_ONBOARDING_COUNTER_LEFT_DISTANCE"
export const SET_ONBOARDING_COUNTER_BOTTOM_DISTANCE =
  "SET_ONBOARDING_COUNTER_BOTTOM_DISTANCE"
export const SET_ONBOARDING_VERTICAL_MARGIN = "SET_ONBOARDING_VERTICAL_MARGIN"
export const SET_ONBOARDING_HORIZONTAL_MARGIN =
  "SET_ONBOARDING_HORIZONTAL_MARGIN"
export const SET_ONBOARDING_DOTS_ACTIVE_COLOR =
  "SET_ONBOARDING_DOTS_ACTIVE_COLOR"
export const SET_ONBOARDING_DOTS_BOTTOM_DIST = "SET_ONBOARDING_DOTS_BOTTOM_DIST"
export const SET_ONBOARDING_DOTS_INACTIVE_COLOR =
  "SET_ONBOARDING_DOTS_INACTIVE_COLOR"
export const SET_ONBOARDING_DOTS_OPACITY = "SET_ONBOARDING_DOTS_OPACITY"
export const SET_ONBOARDING_DOTS_RADIUS = "SET_ONBOARDING_DOTS_RADIUS"
export const SET_ONBOARDING_DOTS_DISTANCE = "SET_ONBOARDING_DOTS_DISTANCE"

export const SET_ONBOARDING_TOOLTIP_MARGIN = "SET_ONBOARDING_TOOLTIP_MARGIN"
export const SET_ONBOARDING_MESSAGE_POSITION = "SET_ONBOARDING_MESSAGE_POSITION"
export const SET_ONBOARDING_IMAGE_POSITION = "SET_ONBOARDING_IMAGE_POSITION"
export const SET_ONBOARDING_LAST_STEP_TEXT = "SET_ONBOARDING_LAST_STEP_TEXT"
export const SET_ONBOARDING_BTN_TEXT = "SET_ONBOARDING_BTN_TEXT"
export const SET_ONBOARDING_BTN_FONT_SIZE = "SET_ONBOARDING_BTN_FONT_SIZE"
export const SET_ONBOARDING_BTN_ADAPTIVE_TEXT =
  "SET_ONBOARDING_BTN_ADAPTIVE_TEXT"
export const SET_ONBOARDING_BTN_FONT_FAMILY = "SET_ONBOARDING_BTN_FONT_FAMILY"
export const SET_ONBOARDING_BTN_FONT_STYLE = "SET_ONBOARDING_BTN_FONT_STYLE"
export const SET_ONBOARDING_BTN_COLOR = "SET_ONBOARDING_BTN_COLOR"
export const SET_ONBOARDING_BTN_BACKGROUND_COLOR =
  "SET_ONBOARDING_BTN_BACKGROUND_COLOR"

export const SET_ONBOARDING_SKIP_ENABLED = "SET_ONBOARDING_SKIP_ENABLED"
export const SET_ONBOARDING_SKIP_TOP = "SET_ONBOARDING_SKIP_TOP"
export const SET_ONBOARDING_SKIP_RIGHT = "SET_ONBOARDING_SKIP_RIGHT"
export const SET_ONBOARDING_NEXT_PREV_ENABLED =
  "SET_ONBOARDING_NEXT_PREV_ENABLED"
export const SET_CORNER_RADUIS_X = "SET_CORNER_RADUIS_X"
export const SET_CORNER_RADUIS_Y = "SET_CORNER_RADUIS_Y"
export const SET_FADEOUT_OPACITY = "SET_FADEOUT_OPACITY"
export const SET_ONBOARDING_STATE = "SET_ONBOARDING_STATE"
export const SET_ONBOARDING_MODE = "SET_ONBOARDING_MODE"
export const UPDATE_ONBOARDING_STATE_IN_UIS = "UPDATE_ONBOARDING_STATE_IN_UIS"
export const SET_DYNAMIC_TEXT_CONFIG = "SET_DYNAMIC_TEXT_CONFIG"
export const TEMPLATE_BEEN_SET = "TEMPLATE_BEEN_SET"
export const HIDE_CONFIGURATION_TOOLBARS = "HIDE_CONFIGURATION_TOOLBARS"
export const ON_COPY_STEP = "ON_COPY_STEP"
export const ON_EDIT_STEP = "ON_EDIT_STEP"
export const ON_DELETE_STEP = "ON_DELETE_STEP"
export const SET_BORDER_BOTTOM_RADIUS = "SET_BORDER_BOTTOM_RADIUS"
export const SET_PLATFORM = "SET_PLATFORM"
export const CONVERT_INAPP_TO_HTML = "CONVERT_INAPP_TO_HTML"
export const SET_IMG = "SET_IMG"
export const SET_CURRENT_IMAGE = "SET_CURRENT_IMAGE"
export const SET_LAYOUT = "SET_LAYOUT"
export const SET_SELECTED_HTML_VIEW = "SET_SELECTED_HTML_VIEW"
export const SET_EVENT_SOURCE = "SET_EVENT_SOURCE"
export const SET_TREEVIEW = "SET_TREEVIEW"
export const SET_ORIENTATION = "SET_ORIENTATION"
export const SET_ACTIVITY = "SET_ACTIVITY"
export const SET_SCREEN = "SET_SCREEN"
export const SET_SSE_DATA = "SET_SSE_DATA"
export const SET_VIEWID_SELECTION_DIALOG_DATA =
  "SET_VIEWID_SELECTION_DIALOG_DATA"

export const SET_HTML_SCRIPT_CONFIG = "SET_HTML_SCRIPT_CONFIG"
export const REMOVE_SNIPPET_FROM_HTML_CONFIG = "REMOVE_SNIPPET_FROM_HTML_CONFIG"

export const SET_HTML_ACTION_CONFIG = "SET_HTML_ACTION_CONFIG"

export const SET_CAROUSEL_IMAGES = "SET_CAROUSEL_IMAGES"
export const SET_CAROUSEL_COUNT = "SET_CAROUSEL_COUNT"
export const UNSET_CAROUSAL_IMAGE = "UNSET_CAROUSAL_IMAGE"

export const ON_UIS_CHANGE = "ON_UIS_CHANGE"

export const SET_EXPERIMENT_STATE = "SET_EXPERIMENT_STATE"

export const SET_IS_CONTENT_VIEW_SELECTION_MODE =
  "SET_IS_CONTENT_VIEW_SELECTION_MODE"
export const SET_VIEW_MAP = "SET_VIEW_MAP"
export const CLEAR_VIEW_MAP = "CLEAR_VIEW_MAP"

export const SET_REFRESH_STATE = "SET_REFRESH_STATE"

export const SET_AUTO_DISMISS_DURATION = "SET_AUTO_DISMISS_DURATION"
export const SET_AUTO_DISMISS_ENABLED = "SET_AUTO_DISMISS_ENABLED"
export const SET_OPEN_LAUNCH_URL_DIALOG = "SET_OPEN_LAUNCH_URL_DIALOG"

export const SET_ENABLE_TIME_BASED = "SET_ENABLE_TIME_BASED"
export const SET_ENABLE_TIME_BASED_TYPE = "SET_ENABLE_TIME_BASED_TYPE"
export const SET_TIME_BASED_TYPE = "SET_TIME_BASED_TYPE"
export const SET_TIME_BASED_DURATION = "SET_TIME_BASED_DURATION"
export const SET_TIME_BASED_DATE = "SET_TIME_BASED_DATE"
export const SET_TIME_BASED_DAYS = "SET_TIME_BASED_DAYS"

export const SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS =
  "SET_TERMINATE_INFO_ENABLE_GOAL_EVENTS"
export const SET_TERMINATE_INFO_GOAL_EVENTS_SEQUENCE_ENABLED =
  "SET_TERMINATE_INFO_GOAL_EVENTS_SEQUENCE_ENABLED"
export const SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS =
  "SET_TERMINATE_INFO_GOAL_EVENTS_EVENTS"
export const SET_GOAL_EVENTS_SEQUENCE_ENABLED =
  "SET_GOAL_EVENTS_SEQUENCE_ENABLED"
export const SET_ENABLE_GOAL_EVENTS_EVENTS = "SET_ENABLE_GOAL_EVENTS_EVENTS"
export const SET_ENABLE_TOUCH_VALUE = "SET_ENABLE_TOUCH_VALUE"
export const SET_ENABLE_TOUCH = "SET_ENABLE_TOUCH"
export const SET_TERMINATE_INFO_ENABLE_ATTRIBUTES_VALUE =
  "SET_TERMINATE_INFO_ENABLE_ATTRIBUTES_VALUE"
export const SET_TERMINATE_INFO_ENABLE_ATTRIBUTES =
  "SET_TERMINATE_INFO_ENABLE_ATTRIBUTES"
export const SET_TERMINATE_INFO_ATTRIBUTES_SESSION =
  "SET_TERMINATE_INFO_ATTRIBUTES_SESSION"
export const SET_TERMINATE_INFO_ATTRIBUTES_USER =
  "SET_TERMINATE_INFO_ATTRIBUTES_USER"

// Close Button Constants
export const SET_CLOSE_BUTTON_ENABLED = "SET_CLOSE_BUTTON_ENABLED"
export const SET_CLOSE_BUTTON_COLOR = "SET_CLOSE_BUTTON_COLOR"
export const SET_CLOSE_BUTTON_FILL_COLOR = "SET_CLOSE_BUTTON_FILL_COLOR"
export const SET_CLOSE_BUTTON_TYPE = "SET_CLOSE_BUTTON_TYPE"
export const SET_CLOSE_BUTTON_SHAPE = "SET_CLOSE_BUTTON_SHAPE"
export const SET_CLOSE_BUTTON_ACTION = "SET_CLOSE_BUTTON_ACTION"
export const SET_CLOSE_BUTTON_PATH = "SET_CLOSE_BUTTON_PATH"
export const SET_CLOSE_BUTTON_OFFSET_TOP = "SET_CLOSE_BUTTON_OFFSET_TOP"
export const SET_CLOSE_BUTTON_OFFSET_RIGHT = "SET_CLOSE_BUTTON_OFFSET_RIGHT"
export const SET_CLOSE_BUTTON_WIDTH = "SET_CLOSE_BUTTON_WIDTH"
export const SET_CLOSE_BUTTON_HEIGHT = "SET_CLOSE_BUTTON_HEIGHT"

// Pip Constants
export const SET_PIP_MODE = "SET_PIP_MODE"

// Pip Button Constants
export const SET_PIP_BUTTON_ENABLED = "SET_PIP_BUTTON_ENABLED"
export const SET_PIP_BUTTON_COLOR = "SET_PIP_BUTTON_COLOR"
export const SET_PIP_BUTTON_FILL_COLOR = "SET_PIP_BUTTON_FILL_COLOR"
export const SET_PIP_BUTTON_TYPE = "SET_PIP_BUTTON_TYPE"
export const SET_PIP_BUTTON_SHAPE = "SET_PIP_BUTTON_SHAPE"
export const SET_PIP_BUTTON_PATH = "SET_PIP_BUTTON_PATH"
export const SET_PIP_BUTTON_OFFSET_TOP = "SET_PIP_BUTTON_OFFSET_TOP"
export const SET_PIP_BUTTON_OFFSET_LEFT = "SET_PIP_BUTTON_OFFSET_LEFT"
export const SET_PIP_BUTTON_WIDTH = "SET_PIP_BUTTON_WIDTH"
export const SET_PIP_BUTTON_HEIGHT = "SET_PIP_BUTTON_HEIGHT"

//Inline Animation Constants
export const SET_INLINE_ANIMATION_ENABLED = "SET_INLINE_ANIMATION_ENABLED"
export const SET_ANIMATION_TYPE = "SET_ANIMATION_TYPE"

// Arrow Constants
export const SET_ARROW_ENABLED = "SET_ARROW_ENABLED"
export const SET_ARROW_TYPE = "SET_ARROW_TYPE"
export const SET_ARROW_COLOR = "SET_ARROW_COLOR"
export const SET_ARROW_BORDER_ENABLED = "SET_ARROW_BORDER_ENABLED"
export const SET_ARROW_BORDER_COLOR = "SET_ARROW_BORDER_COLOR"
export const SET_ARROW_BORDER_WIDTH = "SET_ARROW_BORDER_WIDTH"
export const SET_ARROW_BORDER_STYLE = "SET_ARROW_BORDER_STYLE"
export const SET_ARROW_BORDER_RADIUS = "SET_ARROW_BORDER_RADIUS"

//New Config Constants
export const NEW_CONFIG_SET_BUTTONS = "NEW_CONFIG_SET_BUTTONS"
export const NEW_CONFIG_SET_BUTTONS_LENGTH = "NEW_CONFIG_SET_BUTTONS_LENGTH"
export const NEW_CONFIG_SET_BUTTONS_POSITION = "NEW_CONFIG_SET_BUTTONS_POSITION"
export const NEW_CONFIG_SET_BACKGROUND_COLOR = "NEW_CONFIG_SET_BACKGROUND_COLOR"
export const NEW_CONFIG_SET_BACKGROUND_OPACITY =
  "NEW_CONFIG_SET_BACKGROUND_OPACITY"
export const NEW_CONFIG_SET_BACKGROUND_OFFSET =
  "NEW_CONFIG_SET_BACKGROUND_OFFSET"
export const NEW_CONFIG_SET_BUTTONS_ALIGNMENT =
  "NEW_CONFIG_SET_BUTTONS_ALIGNMENT"
export const NEW_CONFIG_COACHMARK_SHAPE = "NEW_CONFIG_COACHMARK_SHAPE"
export const NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_CIRCLE =
  "NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_CIRCLE"
export const NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_RECT =
  "NEW_CONFIG_BACKGROUND_COACHMARK_DELTA_RECT"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_SHAPE =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_SHAPE"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_ENABLE =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_ENABLE"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_RADIUS =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_RADIUS"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_COLOR =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_COLOR"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_WIDTH =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_WIDTH"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_OPACITY =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_OPACITY"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_STYLE =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_BORDER_STYLE"
export const SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_OFFSET =
  "SET_NEW_CONFIG_ELEMENT_HIGHLIGHTER_OFFSET"
export const NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_CIRCLE =
  "NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_CIRCLE"
export const NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_RECT =
  "NEW_CONFIG_ELEMENT_HIGHLIGHTER_DELTA_RECT"
export const SET_NEW_CONFIG_COACHMARK_ENABLE_RIPPLE =
  "SET_NEW_CONFIG_COACHMARK_ENABLE_RIPPLE"
export const SET_NEW_CONFIG_COACHMARK_RIPPLE_OPACITY =
  "SET_NEW_CONFIG_COACHMARK_RIPPLE_OPACITY"
export const SET_NEW_CONFIG_COACHMARK_RIPPLE_COLOR =
  "SET_NEW_CONFIG_COACHMARK_RIPPLE_COLOR"
export const SET_NEW_CONFIG_COACHMARK_RIPPLE_TYPE =
  "SET_NEW_CONFIG_COACHMARK_RIPPLE_TYPE"
export const SET_NEW_CONFIG_COACHMARK_RIPPLE_DURATION =
  "SET_NEW_CONFIG_COACHMARK_RIPPLE_DURATION"
export const SET_NEW_CONFIG_COACHMARK_RIPPLE_WIDTH =
  "SET_NEW_CONFIG_COACHMARK_RIPPLE_WIDTH"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_DIMENSION =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_DIMENSION"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE_BORDER =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_ENABLE_BORDER"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_BORDER_PROPERTY =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_BORDER_PROPERTY"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_TYPE =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_TYPE"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_PATH =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_PATH"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_POSITION =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_POSITION"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_OFFSET =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_OFFSET"
export const SET_NEW_CONFIG_COACHMARK_IMAGE_ASPECT_RATIO =
  "SET_NEW_CONFIG_COACHMARK_IMAGE_ASPECT_RATIO"
export const SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE =
  "SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE"
export const SET_NEW_CONFIG_COACHMARK_ARROW_DIMENSION =
  "SET_NEW_CONFIG_COACHMARK_ARROW_DIMENSION"
export const SET_NEW_CONFIG_COACHMARK_ARROW_ANGLE =
  "SET_NEW_CONFIG_COACHMARK_ARROW_ANGLE"
export const SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE_BORDER =
  "SET_NEW_CONFIG_COACHMARK_ARROW_ENABLE_BORDER"
export const SET_NEW_CONFIG_COACHMARK_ARROW_BORDER_PROPERTY =
  "SET_NEW_CONFIG_COACHMARK_ARROW_BORDER_PROPERTY"
export const SET_NEW_CONFIG_COACHMARK_ARROW_TYPE =
  "SET_NEW_CONFIG_COACHMARK_ARROW_TYPE"
export const SET_NEW_CONFIG_COACHMARK_ARROW_PATH =
  "SET_NEW_CONFIG_COACHMARK_ARROW_PATH"
export const SET_NEW_CONFIG_COACHMARK_ARROW_POSITION =
  "SET_NEW_CONFIG_COACHMARK_ARROW_POSITION"
export const SET_NEW_CONFIG_COACHMARK_ARROW_OFFSET =
  "SET_NEW_CONFIG_COACHMARK_ARROW_OFFSET"
export const SET_NEW_CONFIG_COACHMARK_ARROW_ASPECT_RATIO =
  "SET_NEW_CONFIG_COACHMARK_ARROW_ASPECT_RATIO"
export const SET_NEW_CONFIG_CONTENT_ENABLE_TITLE =
  "SET_NEW_CONFIG_CONTENT_ENABLE_TITLE"
export const SET_NEW_CONFIG_CONTENT_ENABLE_DESC =
  "SET_NEW_CONFIG_CONTENT_ENABLE_DESC"
export const SET_NEW_CONFIG_CONTENT_HEIGHT = "SET_NEW_CONFIG_CONTENT_HEIGHT"
export const SET_NEW_CONFIG_CONTENT_WIDTH = "SET_NEW_CONFIG_CONTENT_WIDTH"
export const SET_NEW_CONFIG_CONTENT_MARGIN = "SET_NEW_CONFIG_CONTENT_MARGIN"
export const SET_NEW_CONFIG_CONTENT_OFFSET = "SET_NEW_CONFIG_CONTENT_OFFSET"
export const SET_NEW_CONFIG_CONTENT_POSITION = "SET_NEW_CONFIG_CONTENT_POSITION"
export const SET_NEW_CONFIG_CONTENT_TEXT = "SET_NEW_CONFIG_CONTENT_TEXT_"
export const SET_NEW_CONFIG_CONTENT_TEXT_ALIGNMENT =
  "SET_NEW_CONFIG_CONTENT_TEXT_ALIGNMENT"
export const SET_NEW_CONFIG_CONTENT_TEXT_COLOR =
  "SET_NEW_CONFIG_CONTENT_TEXT_COLOR"
export const SET_NEW_CONFIG_CONTENT_TEXT_FONT_PROPERTY =
  "SET_NEW_CONFIG_CONTENT_TEXT_FONT_PROPERTY"
export const SET_NEW_CONFIG_CONTENT_TEXT_MARGIN =
  "SET_NEW_CONFIG_CONTENT_TEXT_MARGIN"
export const SET_NEW_CONFIG_CONTENT_TEXT_PADDING =
  "SET_NEW_CONFIG_CONTENT_TEXT_PADDING"
export const SET_NEW_CONFIG_CONTENT_BUTTON_COLOR =
  "SET_NEW_CONFIG_CONTENT_BUTTON_COLOR"
export const SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_PROPERTY =
  "SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_PROPERTY"
export const SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_ENABLED =
  "SET_NEW_CONFIG_CONTENT_BUTTON_BORDER_ENABLED"
export const SET_NEW_CONFIG_CONTENT_BUTTON_MARGIN =
  "SET_NEW_CONFIG_CONTENT_BUTTON_MARGIN"
export const SET_NEW_CONFIG_CONTENT_BUTTON_PADDING =
  "SET_NEW_CONFIG_CONTENT_BUTTON_PADDING"
export const SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION =
  "SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION"
export const SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION_TYPE =
  "SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_ACTION_TYPE"
export const SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_TYPE =
  "SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_TYPE"
export const SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_URL =
  "SET_NEW_CONFIG_CONTENT_BUTTON_ENABLE_REDIRECT_URL"
export const SET_NEW_DYNAMIC_TEXT_CONFIG = "SET_NEW_DYNAMIC_TEXT_CONFIG"

export const SET_THEME = "SET_THEME"

export const SET_SCREEN_RESTRICTION_TYPE = "SET_SCREEN_RESTRICTION_TYPE"
export const SET_WHITELIST_SCREENS = "SET_WHITELIST_SCREENS"
export const SET_BLACKLIST_SCREENS = "SET_BLACKLIST_SCREENS"

export const ACTION_CLASSES = {
  TOOLTIP: "tooltip",
  NUDGE: "nudge",
  COACHMARK: "coachmark",
  INAPP: "inapp",
  INLINE: "inline",
}

export const TOOLTIP_LAYOUT_TYPES = {
  ACTION_BUTTONS: "action_buttons",
  DEFAULT: "default",
  IMAGE_TEXT_BUTTONS: "image_text_buttons",
  HTML_OVERLAY: "overlay",
}

export const NEW_INLINE_LAYOUT_TYPES = {
  INLINE: "inline",
}

export const BADGE_LAYOUT_TYPES = {
  DEFAULT: "default",
}

export const COACHMARK_DISPLAY_TYPES = {
  LEGACY: "coach_mark",
  LATEST: "coach_mark_v2",
}

export const BADGE_DISPLAY_TYPES = {
  LEGACY: "passive_nudge",
  LATEST: "badge",
}

export const INAPP_LAYOUT_TYPES = {
  HEADER: "header",
  FOOTER: "footer",
  POPOUT_MODAL: "popout_modal",
  FULL_SCREEN_MODAL: "full_screen_modal",
  CAROUSEL: "carousel",
  CUSTOM_HTML: "custom",
  VIDEOS: "videos",
  MODAL: "modal",
}

export const ONBOARDING_COACHMARK_LAYOUT_TYPES = {
  TEXT_SPOTLIGHT: "text_spotlight",
}

export const ONBOARDING_TOOLTIP_LAYOUT_TYPES = {
  CAROUSAL_BUTTONS: "carousal_buttons",
}

export const NUDGE_LAYOUT_TYPES = {
  DEFAULT: "default",
}

export const COACHMARK_LAYOUT_TYPES = {
  DEFAULT: "default",
  COACHMARKS_V2: "coachmarks V2",
}

export const WEB_ACTION_CLASSES = {
  MODALS: "modal",
}

export const NEW_INITIAL_CONFIG_TYPE = {
  FEATURE_DISCOVERY: "feature_discovery",
  FEATURE_ANNOUNCEMENT: "feature_announcement",
  FEATURE_EDUCATION: "feature_education",
  SMALL: "small",
  MEDIUM: "medium",
  LARGE: "large",
  MOBILE: "mobile",
  TITLE_ICON: "title_icon",
  ICON: "icon",
  IMAGE: "image",
  VIDEO: "video",
  SCROLLABLE: "scrollable",
}

export const range = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)

export const ONBOARDING_PAGINATION_ENUM_FOR_SPOTLIGHT = [
  { label: "None", value: "none" },
  { label: "Only numbers", value: "numbers" },
  { label: "Only carousel", value: "carousal" },
]

export const ONBOARDING_PAGINATION_ENUM_FOR_CAROUSAL = [
  { label: "None", value: "none" },
  { label: "Only carousel", value: "carousal" },
]

export const ONBOARDING_PAGINATION_NUMBER_TYPE_ENUM = [
  { label: "1/3", value: "1/3" },
  { label: "1 of 3", value: "1 of 3" },
  { label: "Step 1/3", value: "Step 1/3" },
  { label: "Step 1 of 3", value: "Step 1 of 3" },
]

export const ONBOARDING_DISMISS_ENUM = [
  { label: "swipe", value: "swipe" },
  { label: "tap", value: "tap" },
]

export const ONBOARDING_ANIMATION_ENUM = [
  { label: "Transition", value: "tran" },
  { label: "Fade Out", value: "fade_out" },
]

export const BORDER_RADIUS_ENUM = [2, 4, 8, 16].map((x) => ({
  label: x,
  value: x + "",
}))

export const COACHMARK_SHAPE_TYPES = [
  { label: "circle", value: "circle" },
  { label: "rectangle", value: "rectangle" },
]

export const MAX_HEIGHT_ENUM = range(20, 100, 1).map((x) => ({
  label: x,
  value: x + "",
}))

export const BORDER_WIDTH_ENUM = BORDER_RADIUS_ENUM

export const BUTTONS_COUNT_ENUM = [0, 1, 2, 3].map((x) => ({
  label: x > 0 ? x : "None",
  value: x + "",
}))

export const MESSAGE_POSITION_ENUM = ["Top", "Right", "Bottom", "Left"].map(
  (x) => ({ label: x, value: x }),
)

export const ONBOARDING_MESSAGE_POSITION_ENUM_FOR_SPOTLIGHT = [
  "Top",
  "Right",
  "Bottom",
  "Left",
].map((x) => ({
  label: x,
  value: x,
}))

export const ONBOARDING_MESSAGE_POSITION_ENUM_FOR_CAROUSAL = [
  "Top",
  "Bottom",
].map((x) => ({
  label: x,
  value: x,
}))

export const NEW_COACHMARK_POSITION_ENUM = [
  { label: "Top", value: "T" },
  { label: "Left", value: "L" },
  { label: "Right", value: "R" },
  { label: "Bottom", value: "B" },
]

export const WIDTH_ENUM = [
  {
    label: "Auto",
    value: "auto",
  },
  {
    label: "Custom",
    value: "custom",
  },
]

export const WIDTH_TYPES = {
  AUTO: "auto",
  CUSTOM: "custom",
}

export const MODAL_POSITION_ENUM = [
  { label: "Center", value: "center" },
  { label: "Top left", value: "top-left" },
  { label: "Top right", value: "top-right" },
  { label: "Bottom left", value: "bottom-left" },
  { label: "Bottom right", value: "bottom-right" },
  { label: "Full Screen", value: "full-screen" },
]

export const MESSAGE_TOOLTIP_TEXT_TYPE_ENUM = [
  { label: "Description Text Only", value: 0 },
  { label: "Title & Description", value: 1 },
]

export const NUDGE_POSITION_ENUM = [
  { value: "center", label: "Center" },
  { value: "top_center", label: "Top Center" },
  { value: "left", label: "Left" },
  { value: "right", label: "Right" },
]

export const NUDGE_ANIMATION_ENUM = ["none", "cycle"].map((x) => ({
  label: x,
  value: x.toLowerCase(),
}))

export const DEFAULT_INTENT_ACTION = "android.intent.action.VIEW"
export const DEFAULT_IMAGE_SIZE_TYPES = ["Cover", "Contain", "Stretch"]

export const BTN_ACTION_ENUM = Object.freeze({
  DISMISS: "dismiss",
  REDIRECT: "redirect",
  CANCEL: "cancel",
  NEVER_SHOW: "never",
})

export const ASPECT_RATIO_ENUM = {
  "16:9 (portrait)": {
    width: 1080,
    height: 1920,
  },
  "16:9 (landscape)": {
    width: 1920,
    height: 1080,
  },
  Custom: {
    width: 1280,
    height: 720,
  },
}

export const REDIRECT_ACTIONS = {
  ACTIVITY: "activity",
  DEEP_LINK: "deep link",
  KEY_VALUE_PAIRS: "Key Value Pairs",
}

export const MODULE_TYPES = {
  TITLE: "TITLE",
  ARROW: "ARROW",
  DESCRIPTION: "DESCRIPTION",
  IMAGE: "IMAGE",
  IMAGE_URL: "IMAGE_URL",
  BACKGROUND: "BACKGROUND",
  BG_IMAGE_URL: "BG_IMAGE_URL",
  NEW_BUTTON: "NEW_BUTTON",
  BUTTON: "BUTTON_",
  BUTTON_TEXT: "BUTTON_TEXT",
  BUTTON1: "BUTTON_1",
  BUTTON2: "BUTTON_2",
  NEW_BUTTON_TEXT: "NEW_BUTTON_TEXT",
  BUTTON_TEXT1: "BUTTON_TEXT1",
  BUTTON_TEXT2: "BUTTON_TEXT2",
  ACTION_BUTTON: "ACTION_BUTTON_",
  ACTION_BUTTON1: "ACTION_BUTTON_1",
  ACTION_BUTTON2: "ACTION_BUTTON_2",
  ACTION_BUTTON3: "ACTION_BUTTON_3",
  ACTION_BUTTON_1_IMAGE: "ACTION_BUTTON_1_IMAGE",
  ACTION_BUTTON_1_TEXT: "ACTION_BUTTON_1_TEXT",
  ACTION_BUTTON_1_DESC: "ACTION_BUTTON_1_DESC",
  ACTION_BUTTON_2_IMAGE: "ACTION_BUTTON_2_IMAGE",
  ACTION_BUTTON_2_TEXT: "ACTION_BUTTON_2_TEXT",
  ACTION_BUTTON_2_DESC: "ACTION_BUTTON_2_DESC",
  ACTION_BUTTON_3_IMAGE: "ACTION_BUTTON_3_IMAGE",
  ACTION_BUTTON_3_TEXT: "ACTION_BUTTON_3_TEXT",
  ACTION_BUTTON_3_DESC: "ACTION_BUTTON_3_DESC",
  VIDEO_BUTTON: "VIDEO_BUTTON",
  NOTHING: "NOTHING",
  CLOSE_BUTTON: "CLOSE_BUTTON",
  ELEMENT_HIGHLIGHTER: "ELEMENT_HIGHLIGHTER",
  TITLE_ICON: "TITLE_ICON",
  MEDIA_ICON: "MEDIA_ICON",
  MEDIA_IMAGE: "MEDIA_IMAGE",
  MEDIA_VIDEO: "MEDIA_VIDEO",
  TITLE_TEXT: "TITLE_TEXT",
  DESCRIPTION_TEXT: "DESCRIPTION_TEXT",
  PIP_BUTTON: "PIP_BUTTON",
  BADGE: "BADGE",
  BADGE_LABEL: "BADGE_LABEL",
  BADGE_ICON: "BADGE_ICON",
  EXPLAINER_ACTION_BUTTON_SKIP: "EXPLAINER_ACTION_BUTTON_SKIP",
  EXPLAINER_ACTION_BUTTON_NEXT: "EXPLAINER_ACTION_BUTTON_NEXT",
  EXPLAINER_ACTION_BUTTON_PREV: "EXPLAINER_ACTION_BUTTON_PREV",
  EXPLAINER_TITLE_TEXT: "EXPLAINER_TITLE_TEXT",
  EXPLAINER_TEXT: "EXPLAINER_TEXT",
  EXPLAINER_BACKGROUND: "EXPLAINER_BACKGROUND",
  EXPLAINER_CAROUSEL_DOT: "EXPLAINER_CAROUSEL_DOT",
  EXPLAINER_CAROUSEL_COUNTER: "EXPLAINER_CAROUSEL_COUNTER",
  EXPLAINER_CAROUSEL_DIVIDER: "EXPLAINER_CAROUSEL_DIVIDER",
  EXPLAINER_ELEMENT: "EXPLAINER_ELEMENT",
  NEW_COACHMARK_BACKGROUND: "NEW_COACHMARK_BACKGROUND",
  NEW_COACHMARK_ELEMENT: "NEW_COACHMARK_ELEMENT",
  NEW_COACHMARK_CONTENT: "NEW_COACHMARK_CONTENT",
  NEW_COACHMARK_ARROW: "NEW_COACHMARK_ARROW",
  NEW_COACHMARK_IMAGE: "NEW_COACHMARK_IMAGE",
  NEW_COACHMARK_CONTENT_TITLE: "NEW_COACHMARK_CONTENT_TITLE",
  NEW_COACHMARK_CONTENT_DESC: "NEW_COACHMARK_CONTENT_DESC",
  NEW_COACHMARK_BUTTON: "NEW_COACHMARK_BUTTON",
  NEW_COACHMARK_BUTTON1: "NEW_COACHMARK_BUTTON1",
  NEW_COACHMARK_BUTTON2: "NEW_COACHMARK_BUTTON2",
  NEW_COACHMARK_BUTTON_TEXT: "NEW_COACHMARK_BUTTON_TEXT",
  NEW_COACHMARK_BUTTON_TEXT1: "NEW_COACHMARK_BUTTON_TEXT1",
  NEW_COACHMARK_BUTTON_TEXT2: "NEW_COACHMARK_BUTTON_TEXT2",
  // and the list goes on
}

export const SKIP_BUTTON_STATE = {
  skip: {
    color: "#FFFFFF",
    bg_color: "",
    font_family: "sans-serif",
    text: "SKIP",
    size: 14,
    style: "normal",
    left_dist: 16,
    align_center: false,
    pos_top: false,
    border_radius: 4,
  },
}

export const NEXT_BUTTON_STATE = Object.freeze({
  next: {
    color: "#000000",
    bg_color: "#FFFFFF",
    font_family: "sans-serif",
    text: "NEXT",
    size: 16,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
    align_center: false,
    top_dist: 30,
    pos_top: false,
    border_radius: 8,
    margins: [0, 0, 0, 0],
  },
})

export const NEXT_BUTTON_STATE_FOR_CAROUSAL = {
  next: {
    color: "#FFFFFF",
    bg_color: "",
    font_family: "sans-serif",
    text: "NEXT",
    size: 12,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
    align_center: false,
    top_dist: 30,
    pos_top: false,
    border_radius: 8,
    margins: [5, 5, 5, 5],
  },
}

export const PREV_BUTTON_STATE = {
  prev: {
    color: "#000000",
    font_family: "sans-serif",
    text: "PREV",
    bg_color: "#FFFFFF",
    size: 16,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
    align_center: false,
    top_dist: 30,
    pos_top: false,
    border_radius: 8,
    margins: [5, 5, 5, 5],
  },
}

export const PREV_BUTTON_STATE_FOR_CAROUSAL = {
  prev: {
    color: "#FFFFFF",
    font_family: "sans-serif",
    text: "PREV",
    bg_color: "",
    size: 12,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
    align_center: false,
    top_dist: 30,
    pos_top: false,
    border_radius: 8,
    margins: [5, 5, 5, 5],
  },
}

export const INITIAL_ONBOARDING_STATE = {
  anim_type: "tran",
  duration: 100,
  rx: 12,
  ry: 12,
  carousal: {
    d_type: "tap",
    m_temp: "left_i",
    d_pad: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
    m_margin: {
      hz: 0,
      vr: 0,
    },
    f_alpha: 0.85,
    dots: true,
    d_acolor: "#69A1F0",
    d_icolor: "#ffffff",
    d_opacity: 0.8,
    radius: 5,
    bottom_dist: 5,
    m_pos: "left",
    i_pos: "center",
    dots_dist: 15,
    counter: false,
    skip_top: -10,
    has_divider: true,
    divider_height: 1,
    divider_color: "#FFFFFF",
    divider_margins: [0, 0, 0, 8],
    fixed_width: true,
    msg_margins: [8, 8, 8, 8],
  },
  has_counter: false,
  counter: {
    color: "#FFFFFF",
    font_family: "sans-serif",
    text: "1/2",
    size: 12,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
    margins: [0, 0, 0, 0],
  },
  lastStepText: "GOT IT",
  nextPrevEnabled: true,
  skipEnabled: true,
}
export const DEFAULT_MSG_MARGIN = {
  msg_margins: [8, 8, 8, 8],
}

export const INITIAL_ONBOARDING_STATE_FOR_CAROUSAL = {
  anim_type: "fade_out",
  duration: 100,
  rx: 12,
  ry: 12,
  carousal: {
    d_type: "tap",
    m_temp: "left_i",
    d_pad: {
      bottom: 0,
      left: 0,
      right: 0,
      top: 0,
    },
    m_margin: {
      hz: 0,
      vr: 0,
    },
    f_alpha: 0.85,
    dots: true,
    d_acolor: "#ffffff",
    d_icolor: "#69A1F0",
    d_opacity: 0.8,
    radius: 5,
    bottom_dist: 5,
    m_pos: "left",
    i_pos: "center",
    dots_dist: 15,
    counter: false,
    skip_top: -10,
    has_divider: true,
    divider_height: 1,
    divider_color: "#FFFFFF",
    divider_margins: [0, 0, 0, 8],
    fixed_width: true,
    msg_margins: [8, 8, 8, 8],
  },
  has_counter: false,
  counter: {
    color: "#FFFFFF",
    font_family: "sans-serif",
    text: "1/2",
    size: 12,
    style: "bold",
    bottom_dist: 15,
    left_dist: 15,
    margins: [0, 0, 0, 0],
  },
  lastStepText: "GOT IT",
  nextPrevEnabled: true,
  skipEnabled: true,
}

export const DEFAULT_HTML_SCRIPT_JSON = {
  keys: [],
  vmap: {},
  srcs: {},
}

export const DEFAULT_HTML_IMAGE_URL =
  "https://images.unsplash.com/photo-1577578306649-09e937512e28?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=400&q=50"

export const SCREEN_OR_ACTIVITY = Object.freeze({
  SCREEN: "screen",
  ACTIVITY: "activity",
})

export const PATH_OR_VIEW_ID = Object.freeze({
  PATH: "p",
  VIEW_ID: "",
})

export const CLOSE_ICON_STYLE_ENUM = [
  { label: "Without Background", value: "normal" },
  { label: "With Background", value: "fill" },
  { label: "Custom", value: "custom" },
]

export const TITLE_ICON_SIZES_ENUM = [
  { label: "Small", value: 24 },
  { label: "Medium", value: 28 },
  { label: "Large", value: 32 },
]
export const BORDER_TYPE_ENUM = [
  { label: "Solid", value: "solid" },
  {
    label: "Dashed",
    value: "dashed",
  },
  { label: "Dotted", value: "dotted" },
]
export const RIPPLE_TYPE_ENUM = [
  { label: "Outward", value: "outward" },
  { label: "Inward", value: "inward" },
]
export const MEDIA_ICON_SIZES_ENUM = [
  { label: "Small", value: 24 },
  { label: "Medium", value: 48 },
  { label: "Large", value: 64 },
]

export const MEDIA_TYPES_ENUM = Object.freeze({
  NONE: "none",
  ICON: "icon",
  IMAGE: "image",
  VIDEO: "video",
})

export const TITLE_OR_DESC_PADDING_MARGIN = Object.freeze({
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
  TOP: "top",
})

export const VIDEO_LINK_TYPES_ENUM = [
  { label: "Normal", value: false },
  { label: "Embed", value: true },
]

export const MODAL_TOP_BUTTONS_SLOTS = [1, 2, 3]
export const MODAL_CENTER_BUTTONS_SLOTS = [1]
export const MODAL_BOTTOM_BUTTONS_SLOTS = [1, 2, 3, 4, 5]

export const MODAL_NEW_BUTTONS_ROW_NAMES = Object.freeze({
  top: "top",
  center: "center",
  bottom: "bottom",
})

export const NEW_INLINE_POSITION_ENUM = [
  { label: "Top", value: "top" },
  { label: "Right", value: "right" },
  { label: "Bottom", value: "bottom" },
  { label: "Left", value: "left" },
]

export const ARROW_TYPE_ENUM = [
  { label: "Normal", value: "normal" },
  { label: "Round", value: "round" },
  { label: "Narrow", value: "narrow" },
  { label: "Large", value: "large" },
  { label: "Small", value: "small" },
  { label: "Wide", value: "wide" },
]

export const SEARCH_CRITERIA_TYPES = Object.freeze({
  TAG: "tag",
  ID: "id",
  PATH: "path",
  INDEX: "index",
  CONTENT: "content",
})

export const WEB_LAUNCH_MODE = Object.freeze({
  VIEW_SELECTION: "view-selection",
  REGISTER_EVENTS: "register-events",
  REGISTER_PAGES: "register-pages",
})
