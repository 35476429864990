import { useReducer } from "react"
import { createContainer } from "react-tracked"
import { reducer, initial_ui_state } from "./reducer"

const useValue = () => useReducer(reducer, initial_ui_state)

export const {
  Provider,
  useTracked,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue)
