import TextField from "@material-ui/core/TextField"
import { SaveAlt as DownloadIcon } from "@material-ui/icons"
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied"
import { IconArrowNarrowDown, IconArrowNarrowUp } from "@tabler/icons-react"
import { Cell, Column, Table } from "fixed-data-table-2"
import "fixed-data-table-2/dist/fixed-data-table.css"
import React from "react"
import Dimensions from "react-dimensions"
import { API_BASE, API_ROOT } from "../../config"
import { withQueryStrings } from "../../utils"
import Box from "./Box"
import DownloadButton from "./DownloadButton"
import FixedHeaderSortableTable from "./FixedHeaderSortableTable"
import HStack from "./HStack"
import WhitePaper from "./WhitePaper"

const DefaultTextCell = ({ rowIndex, data, col, ...props }) => (
  <Cell {...props}>{data[rowIndex][col]}</Cell>
)

const NonDefaultTextCell = ({ data }) => <span>{data}</span>

const IndexCell = ({ rowIndex, data, col, ...props }) => (
  <Cell {...props}>{rowIndex + 1}</Cell>
)

export const SortTypes = {
  ASC: "ASC",
  DESC: "DESC",
}

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC
}

export class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isHovered: false,
    }
  }

  render() {
    const { onSortChange, sortDir, children, ...props } = this.props
    const { isHovered } = this.state
    return (
      <HStack
        {...props}
        style={{
          cursor: "pointer",
          backgroundColor: isHovered ? "#d3d3d3" : "transparent",
        }}
        onClick={this._onSortChange}
        onMouseEnter={this._handleMouseEnter}
        onMouseLeave={this._handleMouseLeave}
        justify={"center"}
        align={"center"}
      >
        {children}{" "}
        {sortDir ? (
          sortDir === SortTypes.DESC ? (
            <IconArrowNarrowUp size="16px" />
          ) : (
            <IconArrowNarrowDown size="16px" />
          )
        ) : (
          ""
        )}
      </HStack>
    )
  }

  _onSortChange = (e) => {
    e.preventDefault()

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir
          ? reverseSortDirection(this.props.sortDir)
          : SortTypes.DESC,
      )
    }
  }
  _handleMouseEnter = () => {
    this.setState({ isHovered: true })
  }

  _handleMouseLeave = () => {
    this.setState({ isHovered: false })
  }
}

// TODO Convert this to functional component
class SortableDataTable extends React.Component {
  constructor(props) {
    super(props)
    const { data = [] } = props
    this.state = {
      sortedDataList: data,
      sortKey: null,
      sortOrder: SortTypes.ASC,
      query: "",
      downloadInProgress: false,
      hasEncounteredError: false,
    }
  }

  initializeDataList = (
    data,
    sortKey = this.state.sortKey,
    sortOrder = this.state.sortOrder,
  ) => {
    this.setState({
      sortedDataList: this.filteredData(
        this.sortedData(data, sortKey, sortOrder),
      ),
      sortKey,
      sortOrder,
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { data, needUpdate } = nextProps
    const { sortKey, sortOrder } = this.state
    this.initializeDataList(data, sortKey, sortOrder)
    if (needUpdate) {
      //FIXME: Will be called several times
      this.initializeDataList(data)
    }
  }

  sortedData = (data, sortKey, sortOrder) => {
    if (!sortKey || !sortOrder) {
      return data.slice()
    }
    const defaultComparator = (valueA, valueB, isASC) => {
      let sortVal = 0
      if (valueA > valueB) {
        sortVal = 1
      }
      if (valueA < valueB) {
        sortVal = -1
      }
      if (sortVal !== 0 && isASC) {
        sortVal = sortVal * -1
      }
      return sortVal
    }
    const { comparators = {} } = this.props
    const comparator = comparators[sortKey] || defaultComparator
    return data.slice().sort((indexA, indexB) => {
      const valueA = indexA[sortKey]
      const valueB = indexB[sortKey]
      return comparator(valueA, valueB, sortOrder === SortTypes.ASC)
    })
  }

  filteredData = (data, query = this.state.query) => {
    return data.filter((o) => {
      return Object.values(o).some(
        (term) => String(term).toLowerCase().indexOf(query.toLowerCase()) > -1,
      )
    })
  }

  _onSortChange = (sortKey, sortOrder) => {
    this.setState({
      sortedDataList: this.sortedData(
        this.state.sortedDataList,
        sortKey,
        sortOrder,
      ),
      sortKey,
      sortOrder,
    })
  }

  render() {
    const { sortedDataList, sortKey, sortOrder, query } = this.state
    const {
      data = [],
      headerLabels = [],
      keys = [],
      containerWidth,
      defaultTable = true,
      TextCell = defaultTable ? DefaultTextCell : NonDefaultTextCell,
      withoutBox = false,
      boxProps = {},
      withoutSerialNumbers = false,
      tableType,
      tableHeight = null,
    } = this.props

    let rowHeight = 0,
      height = 0,
      length = 0
    if (sortedDataList) {
      length = sortedDataList.length
      rowHeight = 50
      height = rowHeight * (length > 10 ? 10 : length + 1)
    }
    if (withoutBox) {
      return (
        <Table
          {...this.props}
          containerHeight={
            this.props.containerHeight > 0 ? this.props.containerHeight : 500
          }
          rowHeight={rowHeight}
          rowsCount={length}
          headerHeight={rowHeight}
          width={containerWidth - 16}
          height={height}
        >
          {!withoutSerialNumbers && (
            <Column
              header={<Cell>Sno.</Cell>}
              cell={
                <IndexCell
                  data={sortedDataList}
                  col="sno"
                />
              }
              fixed={true}
              width={50}
            />
          )}
          {keys.map((key, index) => {
            return (
              <Column
                key={key}
                columnKey={key}
                header={
                  <SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={key === sortKey ? sortOrder : null}
                  >
                    {headerLabels[index]}
                  </SortHeaderCell>
                }
                cell={
                  <TextCell
                    data={sortedDataList}
                    col={key}
                  />
                }
                width={120}
                flexGrow={1}
              />
            )
          })}
        </Table>
      )
    } else {
      const {
        withoutSearch = false,
        withoutDownload = false,
        downloadReportTitle,
        downloadParams: {
          appId,
          filters,
          queryParams,
          dimensions,
          auth: {
            user: { email },
          },
          isBiReport = false,
        },
      } = this.props

      return (
        <Box
          {...boxProps}
          controls={
            <div style={{ display: "flex", overflow: "hidden" }}>
              {!withoutSearch && (
                <TextField
                  value={query}
                  onChange={(e) => {
                    const query = e.target.value
                    this.setState({
                      query,
                      sortedDataList: this.sortedData(
                        this.filteredData(data, query),
                      ),
                    })
                  }}
                  placeholder="Search..."
                  type="search"
                />
              )}
              {!withoutDownload && (
                <DownloadButton
                  url={
                    `${API_BASE}${isBiReport ? "bi/frontendapi/derived/" : API_ROOT}custom-reports/${tableType}?customerId=${email}&appId=${appId}` +
                    withQueryStrings({
                      ...queryParams,
                      download: true,
                    })
                  }
                  method={"POST"}
                  data={{
                    metrics: ["impressions", "sessions", "users"],
                    dimensions: dimensions,
                    filters: filters,
                  }}
                  downloadReportTitle={downloadReportTitle}
                >
                  <DownloadIcon
                    color="primary"
                    fontSize="small"
                  />
                </DownloadButton>
              )}
            </div>
          }
          component={WhitePaper}
          styles={{ border: "none" }}
        >
          {sortedDataList.length === 0 && (
            <HStack
              style={{
                width: "100%",
                height: "50vh",
                border: "1px solid #d3d3d3",
              }}
              spacing={4}
              align={"center"}
              justify={"center"}
            >
              <SentimentVeryDissatisfiedIcon color="error" />
              No Data.
            </HStack>
          )}
          {sortedDataList.length !== 0 && !defaultTable && (
            <FixedHeaderSortableTable
              keys={keys}
              rows={sortedDataList}
              headerLabels={headerLabels}
              TextCell={TextCell}
            />
          )}
          {sortedDataList.length !== 0 && defaultTable && (
            <Table
              {...this.props}
              rowHeight={rowHeight}
              rowsCount={length}
              headerHeight={rowHeight}
              width={containerWidth - 16}
              height={tableHeight ? tableHeight : height}
            >
              {!withoutSerialNumbers && (
                <Column
                  header={<Cell>Sno.</Cell>}
                  cell={
                    <IndexCell
                      data={sortedDataList}
                      col="sno"
                    />
                  }
                  fixed={true}
                  width={50}
                />
              )}
              {keys.map((key, index) => {
                return (
                  <Column
                    key={key}
                    columnKey={key}
                    header={
                      <SortHeaderCell
                        onSortChange={this._onSortChange}
                        sortDir={key === sortKey ? sortOrder : null}
                      >
                        {headerLabels[index]}
                      </SortHeaderCell>
                    }
                    cell={
                      <TextCell
                        data={sortedDataList}
                        col={key}
                      />
                    }
                    width={120}
                    flexGrow={1}
                  />
                )
              })}
            </Table>
          )}
        </Box>
      )
    }
  }
}

export default Dimensions()(SortableDataTable)
